/**
 * Created by Sergey Panpurin on 5/19/21.
 */

// @ts-check

(function btLinkDataServiceClosure() {
  'use strict';

  var gDebug = false;
  var gPrefix = 'btLinkDataService:';

  /**
   * This service implements ???.
   *
   * @ngdoc service
   * @name ecapp.btLinkDataService
   * @param {angular.IQService} $q - promise interface
   * @param {angular.IIntervalService} $interval
   * @param {angular.IFilterService} $filter
   * @param {ecapp.ICustomRootScope} $rootScope
   * @param {ecapp.IGeneralLoopbackService} lbLdsApi
   * @return {ecapp.ILinkDataService}
   */

  angular.module('ecapp').factory('btLinkDataService', service);

  service.$inject = ['$q', '$interval', '$filter', '$rootScope', 'LdsApiWrapper'];

  /**
   *
   * @param {angular.IQService} $q - ?
   * @param {angular.IIntervalService} $interval - ?
   * @param {angular.IFilterService} $filter - ?
   * @param {ecapp.ICustomRootScope} $rootScope - ?
   * @param {*} lbLdsApi - ?
   * @return {*} - ?
   */
  function service($q, $interval, $filter, $rootScope, lbLdsApi) {
    if (gDebug) console.log(gPrefix, 'running...');

    // var gSubscriptionId = 1;

    var gTransactionStorage = {};

    $interval(function () {
      if (gDebug) console.log('>>>>>>', new Date());
      Object.keys(gTransactionStorage).forEach(function (key) {
        var storage = /** @type {ecapp.ITransactionStorage}*/ gTransactionStorage[key];
        if (storage.subscriptions.length) {
          if (gDebug) console.log('>>>>>> Updating Transaction Storage for ' + key + '...');
          loadTransactions(key)
            .then(function (transactions) {
              var storage = getTransactionStorage(key);
              updateTransactionsStorage(storage, transactions);
              updateSubscriptions(storage);
            })
            .catch(printError);
        }
      });
    }, 60000);

    /**
     *
     * @param {*} date
     */
    function TransactionStorage(date) {
      this.date = date;
      this.items = [];
      this.promise = null;
      this.subscriptions = [];
      this.updated = null;
    }

    TransactionStorage.prototype.subscribe = function (cb) {
      var subscription = { id: TransactionStorage.count++, callback: cb };
      this.subscriptions.push(subscription);
      return subscription;
    };

    TransactionStorage.prototype.unsubscribe = function (subscription) {
      var n = this.subscriptions.length;
      this.subscriptions = this.subscriptions.filter(function (value) {
        return value !== subscription;
      });

      var wasRemoved = n !== this.subscriptions.length;

      if (wasRemoved) {
        if (gDebug) console.log('>>>>>> subscription was removed');
      } else {
        if (gDebug) console.log('>>>>>> subscription was not found');
      }

      return wasRemoved;
    };

    TransactionStorage.count = 0;
    Transaction.count = 0;

    /**
     *
     * @example
     *    {
     *      'agreementDate': '2021-05-10T00:00:00',
     *      'masterTransactionNumber': 'C-S19968-O',
     *      'sellerId': 46,
     *      'sellerName': 'Chevron Products Company',
     *      'buyerId': 201,
     *      'buyerName': 'PBF Holding Company LLC',
     *      'productName': 'DOMESTIC SWEET',
     *      'product2Name': '',
     *      'location': 'CUSHING, OK',
     *      'totalVolume': 150000,
     *      'deliveryStartDate': '2021-06-01T00:00:00',
     *      'deliveryEndDate': '2021-06-30T00:00:00',
     *      'priceDifferential': 0.08,
     *      'modifiedOnUtc': '2021-05-10T17:40:40'
     *    }
     * @param {*} response
     * @class
     */
    function Transaction(response) {
      this.id = Transaction.count++;
      this.agreementDate = new Date(response.agreementDate);
      this.masterTransactionNumber = response.masterTransactionNumber;
      this.sellerId = response.sellerId;
      this.sellerName = response.sellerName;
      this.buyerId = response.buyerId;
      this.buyerName = response.buyerName;
      this.productName = response.productName;
      this.product2Name = response.product2Name;
      this.location = response.location;
      this.totalVolume = response.totalVolume;
      this.deliveryStartDate = new Date(response.deliveryStartDate);
      this.deliveryEndDate = new Date(response.deliveryEndDate);
      this.priceDifferential = response.priceDifferential;
      this.modifiedOnUtc = new Date(response.modifiedOnUtc + 'Z');

      this.productSymbol = 'ABC';

      this.agreementDateText = $filter('date')(this.agreementDate, 'yyyy/MM/dd');
      this.deliveryStartDateText = $filter('date')(this.deliveryStartDate, 'yyyy/MM');
      this.modifiedOnUtcText = $filter('date')(this.modifiedOnUtc, 'yyyy/MM/dd HH:mm', $rootScope.tz);

      this.title =
        'Product Name: ' +
        this.productName +
        '. Pricing Location: ' +
        this.location +
        '.' +
        ' Agreement Date: ' +
        this.agreementDateText +
        '. Transaction: ' +
        this.masterTransactionNumber;
    }

    Transaction.prototype.search = function (text) {
      var query = text.toLowerCase();

      return (
        this.productSymbol.toLowerCase().includes(query) ||
        this.productName.toLowerCase().includes(query) ||
        this.deliveryStartDateText.toLowerCase().includes(query) ||
        this.modifiedOnUtcText.toLowerCase().includes(query) ||
        this.totalVolume.toString().includes(query) ||
        this.priceDifferential.toString().includes(query) ||
        this.location.toLowerCase().includes(query)
      );
    };

    return {
      getToday: getToday,
      getTransactions: getTransactions,
      getVolumeWeightedAveragePrices: getVolumeWeightedAveragePrices,
      getDailyTable: getDailyTable,
    };

    /**
     *
     * @return {string}
     */
    function getToday() {
      return moment().tz('America/Chicago').startOf('day').format('YYYY/MM/DD');
    }

    // /**
    //  *
    //  * @param {string} date - date
    //  * @param {Function} cb - callback
    //  * @return {number} - subscription identifier
    //  */
    // function subscribe(date, cb) {
    //   var id = gSubscriptionId++;
    //   console.log('>>>>>> new subscription', id);
    //   var storage = getTransactionStorage(date);
    //   storage.subscriptions.push({id: id, callback: cb});
    //   return id;
    // }
    //
    // /**
    //  *
    //  * @param {number} id - subscription identifier
    //  * @return {boolean}
    //  */
    // function unsubscribe(id) {
    //   var n = gTransactionStorage.subscriptions.length;
    //   gTransactionStorage.subscriptions = gTransactionStorage.subscriptions.filter(function (value) {
    //     return value.id !== id;
    //   });
    //
    //   var wasRemoved = n !== gTransactionStorage.subscriptions.length;
    //   console.log('>>>>>> remove subscription', id, wasRemoved);
    //   return wasRemoved;
    // }

    /**
     *
     * @param {ecapp.ITransactionStorage} storage -
     */
    function updateSubscriptions(storage) {
      storage.subscriptions.forEach(function (subscription) {
        try {
          subscription.callback();
        } catch (e) {
          console.error(e);
        }
      });
    }

    /**
     * Returns transactions promise for specific date.
     *
     * @param {string} date - date in format 2021/05/29
     * @return {angular.IPromise<ecapp.ITransactionStorage>}
     */
    function getTransactions(date) {
      var storage = getTransactionStorage(date);

      if (!storage.promise) {
        storage.promise = prepareTransactionsPromise(date);
      }

      return storage.promise;
    }

    /**
     *
     * @param {string} date - date in format 2021/05/29
     * @return {angular.IPromise<ecapp.ITransactionStorage>}
     */
    function prepareTransactionsPromise(date) {
      return loadTransactions(date).then(function (transactions) {
        var storage = getTransactionStorage(date);
        updateTransactionsStorage(storage, transactions);
        return storage;
      });
    }

    // /**
    //  * Returns last transactions promise.
    //  *
    //  * @return {angular.IPromise<ecapp.ITransaction[]>}
    //  */
    // function getLastTransactions() {
    //   if (!gTransactionStorage.promise) {
    //     gTransactionStorage.promise = loadTransactions(getToday()).then(updateTransactionsStorage).then(getStoredTransactions);
    //   }
    //
    //   return gTransactionStorage.promise;
    // }

    /**
     * This function loads transactions for specific date.
     *
     * @param {string} date - date in format 2021/05/29
     * @return {angular.IPromise<ecapp.ITransaction[]>}
     */
    function loadTransactions(date) {
      date = moment(date, 'YYYY/MM/DD').format('YYYY-MM-DDTHH:mm:ss');
      // Calculate central time date
      // var date = moment().tz('America/Chicago').startOf('day').subtract(1, 'days').format('YYYY-MM-DDTHH:mm:ss');

      // Load transactions
      return lbLdsApi.getTransactions({ start: date, end: date }).$promise.then(function (results) {
        return results.map(function (item) {
          return new Transaction(item);
        });
      });
    }

    // /**
    //  *
    //  * @return {ecapp.ITransaction[]}
    //  */
    // function getStoredTransactions() {
    //   console.log('>>>>>> Get Stored Transactions');
    //   return gTransactionStorage.items;
    // }

    /**
     *
     * @param {string} date - date in format 2021/05/29
     * @return {ecapp.ITransactionStorage}
     */
    function getTransactionStorage(date) {
      if (!gTransactionStorage[date]) {
        gTransactionStorage[date] = new TransactionStorage(date);
      }

      return gTransactionStorage[date];
    }

    /**
     *
     * @param {ecapp.ITransactionStorage} storage - transaction storage
     * @param {ecapp.ITransaction[]} transactions - new transactions
     */
    function updateTransactionsStorage(storage, transactions) {
      storage.items.splice(0, storage.items.length);
      transactions.forEach(function (item) {
        storage.items.push(item);
      });

      if (gDebug) console.log('>>>>>> Transaction Storage Updated');
    }

    /**
     *
     * @param {Error} err - error
     */
    function printError(err) {
      console.error(err);
    }

    /**
     * Returns volume weighted average prices.
     *
     * @param {string} date - date in format 2021/05/29
     * @return {angular.IPromise<ecapp.ITransactionStorage>}
     */
    function getVolumeWeightedAveragePrices(date) {
      date = moment(date, 'YYYY/MM/DD').format('YYYY-MM-DDTHH:mm:ss');

      // Load volume weighted average prices
      return lbLdsApi.getVolumeWeightedAveragePrices({ start: date, end: date }).$promise.then(function (results) {
        return results;
      });
    }

    /**
     *
     * @return {*}
     */
    function getDailyTable() {
      return lbLdsApi.getDailyTable({}).$promise.then(function (result) {
        return result;
      });
    }
  }
})();
