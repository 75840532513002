/**
 * Created by yatree on 30/09/16.
 */

(function btAccountSettingsCtrlClosure() {
  'use strict';

  angular.module('ecapp').controller('btAccountSettingsController', btAccountSettingsController);

  btAccountSettingsController.$inject = [
    '$filter',
    '$scope',
    '$ionicLoading',
    '$analytics',
    'Upload',
    'btShareScopeService',
    'cloudinary',
    'btMarketing',
    'btRestrictionService',
    'btAuthService',
    'btDeviceService',
    'btBranchService',
    'btToastrService',
    'btSettingsService',
    '$ionicPopup',
  ];

  /**
   * Controller for account page.
   *
   * @ngdoc controller
   * @name ecapp.btAccountSettingsController
   * @param {angular.IFilterService} $filter - Filters are used for formatting data displayed to the user.
   * @param {ecapp.ICustomScope} $scope - common angular scope service
   * @param {ionic.ILoadingService} $ionicLoading - ionic loading service
   * @param {ext.IAnalyticsService} $analytics -
   * @param {ext.IUploadService} $upload - ng-file-upload service to upload files
   * @param {ecapp.IShareScopeService} btShareScopeService - big service processing account data and etc
   * @param {ext.ICloudinaryService} cloudinary - cloudinary image storage service
   * @param {ecapp.IMarketing} btMarketing - ?
   * @param {ecapp.IRestrictionService} btRestrictionService - ?
   * @param {ecapp.IAuthService} btAuthService - ?
   * @param {ecapp.IDeviceService} btDeviceService - ?
   * @param {ecapp.IBranchService} btBranchService - ?
   * @param {ecapp.IToastrService} btToastrService - ?
   * @param {ecapp.ISettingsService} btSettingsService - ?
   * @param {ionic.IPopupService} $ionicPopup - ionic popup service
   */
  function btAccountSettingsController(
    $filter,
    $scope,
    $ionicLoading,
    $analytics,
    $upload,
    btShareScopeService,
    cloudinary,
    btMarketing,
    btRestrictionService,
    btAuthService,
    btDeviceService,
    btBranchService,
    btToastrService,
    btSettingsService,
    $ionicPopup
  ) {
    console.log('Running btAccountSettingsController');

    $scope.country = '';

    $scope.isBigBrainBank = btSettingsService.getDomain() === 'bigbrainbank';

    btShareScopeService.wait().then(function () {
      var location = btShareScopeService.getAccountInfoField('location');
      if (location && location.country) $scope.country = '(' + location.country.code + ') ' + location.country.name;
    });

    // in our case tags is a name of instruments
    // var gTagList = [];
    // var gNotSortedListOfTimeZones = btShareScopeService.getListOfTimeZones();

    // we have only one file, but I get code from "photo-album" where users can upload several images in one time
    var gAccountImageFile = [];

    $scope.hasPersonalization = btRestrictionService.hasFeature('personalization');

    $scope.wasProfileChanged = false;
    $scope.wasProfileUpdated = false;
    $scope.isRestartRequired = false;

    $scope.wasTimeZoneChanged = false;
    $scope.timeZoneList = $filter('orderBy')(btShareScopeService.getListOfTimeZones(), 'offset');
    $scope.account = btShareScopeService.getAccountSettings();

    $scope.previous = {
      name: $scope.account.firstName,
      company: $scope.account.companyName,
      timezone: $scope.account.timeZoneOption,
    };

    $scope.profile = {
      name: $scope.account.firstName,
      company: $scope.account.companyName,
      timezone: $scope.account.timeZoneOption,
    };

    $scope.usersDevices = {
      mobile: $scope.account.devices.indexOf('ios') !== -1 || $scope.account.devices.indexOf('android') !== -1,
      web: $scope.account.devices.indexOf('web') !== -1,
    };

    $scope.wasPasswordSent = false;
    $scope.isPasswordSending = false;

    if ($scope.isBigBrainBank) {
      $scope.mail =
        'mailto:' +
        $scope.account.email +
        '?subject=' +
        encodeURI('Link to TheBrain web terminal') +
        '&body=' +
        encodeURI(
          'Click on the following link from desktop to open TheBrain web terminal.\nhttps://thebrain.bigbrainbank.org'
        );
    } else {
      $scope.mail =
        'mailto:' +
        $scope.account.email +
        '?subject=' +
        encodeURI('Link to BetterTrader.co web terminal') +
        '&body=' +
        encodeURI(
          'Click on the following link from desktop to open BetterTrader web terminal.\nhttps://app.bettertrader.co'
        );
    }

    // $scope.traderTypeOptions = btShareScopeService.getTraderTypes();
    // $scope.submitButtonIsNotActive = true;

    $scope.openFileSelector = openFileSelector;
    $scope.updateProfile = updateProfile;
    // $scope.loadTagsInAutocomplete = loadTags;
    // $scope.activateSubmitButton = activateSubmitButton;

    $scope.onNameChanged = onNameChanged;
    $scope.onCompanyChanged = onCompanyChanged;
    $scope.onTimeZoneChanged = onTimeZoneChanged;
    // $scope.onTagsChanged = onTagsChanged;

    $scope.changePassword = changePassword;
    $scope.getInvitationByPhone = getInvitationByPhone;
    $scope.shareInvitation = shareInvitation;
    $scope.restartApplication = restartApplication;
    $scope.deleteAccount = deleteAccount;

    //noinspection JSValidateTypes
    var $input = angular.element(document.getElementById('upload'));
    $input.on('change', onUploadChange);

    $scope.$watch('profile.name', onNameChanged);
    $scope.$watch('profile.company', onCompanyChanged);
    $scope.$watch('profile.timezone', onTimeZoneChanged);

    $scope.$on('subscription:updated', onSubscriptionUpgradeSuccess);

    activate();

    /**
     *
     */
    function activate() {
      // preLoadTags();
    }

    /**
     *
     * @param {*} e
     */
    function onUploadChange(e) {
      gAccountImageFile.length = 0; // Remove all elements from a list
      gAccountImageFile.push(e.target.files[0]);
      uploadFilesToCloudinary(gAccountImageFile);
    }

    /**
     * This function reacts on subscription update.
     */
    function onSubscriptionUpgradeSuccess() {
      $scope.hasPersonalization = btRestrictionService.hasFeature('personalization');
    }

    // /**
    //  * Reads list of the instruments from cache.
    //  *
    //  * @ngdoc method
    //  * @name preLoadTags
    //  * @memberOf ecapp.btAccountSettingsController
    //  */
    // function preLoadTags() {
    //   // if (window.btInstrumentsCache) {
    //   //   var instruments = window.btInstrumentsCache.get('btInstrumentsCache');
    //   //   if (instruments !== undefined && instruments.length > 0) {
    //   //     gTagList = instruments.map(function (value) {
    //   //       return {text: value};
    //   //     });
    //   //   }
    //   // }
    // }

    /**
     *
     */
    function openFileSelector() {
      $input[0].click();
    }

    /**
     *
     * @param {*} files
     */
    function uploadFilesToCloudinary(files) {
      console.log(files);
      if (!files) {
        return;
      }

      if (!files[0]) {
        return;
      }

      $scope.files = files;
      if (!$scope.files) {
        return;
      }

      var cloudinaryImageElement = angular.element(document.getElementById('cloudinary'));
      cloudinaryImageElement.on('load', function () {
        btShareScopeService
          .updateProfileSettingsFromFieldsList(['imageSrc'])
          .then(function () {
            $ionicLoading.hide();
          })
          .catch(function (reason) {
            $ionicLoading.hide();
            console.log('btAccountSettingsController: error', reason);
          });
      });

      cloudinaryImageElement.on('error', function () {
        window.localStorage.setItem(
          'accountImage',
          'https://res.cloudinary.com/dmpdhoctp/image/upload/v1475865832/UserIcon_k5mfal.png'
        );
        btShareScopeService.setAccountImage(
          'https://res.cloudinary.com/dmpdhoctp/image/upload/v1475865832/UserIcon_k5mfal.png'
        );
        $ionicLoading.hide();
      });

      $ionicLoading.show({ template: '<ion-spinner icon="ios"></ion-spinner><p>Uploading image...</p>' });
      angular.forEach(files, function (file) {
        if (file && !file.$error) {
          file.upload = $upload
            .upload({
              url: 'https://api.cloudinary.com/v1_1/' + cloudinary.config().cloud_name + '/upload',
              data: {
                upload_preset: cloudinary.config().upload_preset,
                tags: 'btImages',
                //context: 'avatar=' + $scope.title,
                file: file,
              },
            })
            .progress(function (e) {
              file.progress = Math.round((e.loaded * 100.0) / e.total);
              file.status = 'Uploading... ' + file.progress + '%';
              console.log(file.status);
            })
            .success(function (data) {
              var imageSrc = data.public_id;
              window.localStorage.setItem('accountImage', imageSrc);
              btShareScopeService.setAccountImage(imageSrc);
            })
            .error(function (data) {
              file.result = data;
              $ionicLoading.hide();
            });
        }
      });
    }

    /**
     * This function updates fields in mongoDB:
     * At first makes request to get userObject, then checks if there are fields in object,
     * then set new data to this fields, if not - then creates in object fields, and then
     * sends updates to mongoDB
     *
     * @ngdoc method
     * @name updateProfile
     * @memberOf ecapp.btAccountSettingsController
     */
    function updateProfile() {
      $ionicLoading.show({ template: '<ion-spinner icon="ios"></ion-spinner><p>Updating personal data...</p>' });

      $scope.wasProfileUpdated = false;

      var fieldsSet = [];

      if ($scope.profile.name !== $scope.previous.name) {
        $scope.account.firstName = $scope.profile.name;
        btMarketing.updateClientData({ name: $scope.profile.name });
        fieldsSet.push('firstName');
      }

      if ($scope.profile.company !== $scope.previous.company) {
        $scope.account.companyName = $scope.profile.company;
        fieldsSet.push('companyName');
      }

      if ($scope.profile.timezone !== $scope.previous.timezone) {
        $scope.account.timeZone = $scope.profile.timezone.name;
        fieldsSet.push('timeZone');
      }
      // $scope.account.traderType = $scope.account.traderTypeOption.value;

      // btShareScopeService.setListSelectedTags(getTagsFromScope());
      btShareScopeService
        .updateProfileSettingsFromFieldsList(fieldsSet)
        .then(function (data) {
          btShareScopeService.setAccountInitials(data.firstName);
          btShareScopeService.setAccountCompany(data.companyName);

          var nameArray = btShareScopeService.getFirstLastNames(data.firstName);
          if ($scope.hasPersonalization) {
            btShareScopeService.setMailChimpField('FNAME', nameArray[0]);
            btShareScopeService.setMailChimpField('LNAME', nameArray[1]);
          }

          $analytics.setUserProperties({
            $first_name: nameArray[0],
            $last_name: nameArray[1],
            company: data.companyName,
          });

          // if restart is not required hide button
          if (!$scope.isRestartRequired) $scope.wasProfileChanged = false;
          // $scope.submitButtonIsNotActive = true;

          $scope.wasProfileUpdated = true;

          $scope.previous = {
            name: $scope.account.firstName,
            company: $scope.account.companyName,
            timezone: $scope.account.timeZoneOption,
          };

          $ionicLoading.hide();
        })
        .catch(function (reason) {
          console.error(reason);
          btToastrService.error(reason.message, 'Update failed');
          $ionicLoading.hide();
        });
    }

    // /**
    //  * Callback for auto-complete element in tags-input.
    //  *
    //  * @ngdoc method
    //  * @name loadTagsInAutocomplete
    //  * @memberOf ecapp.btAccountSettingsController
    //  * @param {angular.IQService} $query - input string "from" html
    //  */
    // function loadTags($query) {
    //   return $filter('filter')(gTagList, $query);
    // }

    // /**
    //  *
    //  */
    // function activateSubmitButton() {
    //   makeSubmitButtonActivated();
    // }

    /**
     *
     */
    function onNameChanged() {
      $scope.wasProfileUpdated = false;
      $scope.wasProfileChanged = $scope.previous.name !== $scope.profile.name;
    }

    /**
     *
     */
    function onCompanyChanged() {
      $scope.wasProfileUpdated = false;
      $scope.wasProfileChanged = $scope.previous.company !== $scope.profile.company;
    }

    /**
     *
     */
    function onTimeZoneChanged() {
      $scope.wasProfileUpdated = false;

      if ($scope.previous.timezone !== $scope.profile.timezone) {
        $scope.wasProfileChanged = true;
        $scope.wasTimeZoneChanged = true;
        $scope.isRestartRequired = true;
      } else {
        $scope.wasProfileChanged = false;
        $scope.wasTimeZoneChanged = false;
        $scope.isRestartRequired = false;
      }
    }

    // /**
    //  *
    //  */
    // function onTagsChanged() {
    //   makeSubmitButtonActivated();
    //   btShareScopeService.setListSelectedTags(getTagsFromScope());
    // }

    // /**
    //  *
    //  * @return {[]}
    //  */
    // function getTagsFromScope() {
    //   var listObjects = $scope.account.tags;
    //   var listStrings = [];
    //   angular.forEach(listObjects, function (value) {
    //     listStrings.push(value['text']);
    //   });
    //   return listStrings;
    // }

    // /**
    //  *
    //  */
    // function makeSubmitButtonActivated() {
    //   // $scope.submitButtonIsNotActive = false;
    //   $scope.wasProfileChanged = true;
    // }

    /**
     * This function sends instructions how to change password to user's email
     */
    function changePassword() {
      $scope.isPasswordSending = true;
      btAuthService
        .resetPassword($scope.account.email)
        .then(function onSuccess() {
          $scope.wasPasswordSent = true;
          $scope.isPasswordSending = false;
        })
        .catch(function onFailure(error) {
          console.log('Error during resetting of password', error);
          $scope.wasPasswordSent = false;
          $scope.isPasswordSending = false;
        });
    }

    /**
     * This function sends link to download application to the phone number.
     */
    function getInvitationByPhone() {
      btDeviceService.sendGetMobileAlert();
    }

    /**
     * This function sends link to download application to the user's email.
     */
    function shareInvitation() {
      if ($scope.isBigBrainBank) {
        btBranchService.share(
          'TheBrain',
          'BigBrainBank’s Artificial Intelligence (AI) driven system combines advanced financial trading expertise, experience and technology to help traders move ahead.',
          { link: 'https://thebrain.bigbrainbank.org' }
        );
      } else {
        btBranchService.share(
          'BetterTrader.co',
          'Real-time Analysis tool for Key Economic Events and Price Movements',
          { link: 'https://app.bettertrader.co' }
        );
      }
    }

    /**
     * This function restarts application.
     */
    function restartApplication() {
      window.location.href = 'refresh.html';
    }

    /**
     * Requests account deletion.
     */
    function deleteAccount() {
      var confirmPopup = $ionicPopup.confirm({
        title: 'Delete Account',
        okText: 'Confirm',
        template:
          '<div class="">' +
          'Your account (' +
          $scope.account.email +
          ') will be deleted in 48 hours. ' +
          'If you have any active subscription please cancel it before. ' +
          'Do you confirm this request?' +
          '</div>',
      });

      confirmPopup
        .then(function (confirmed) {
          if (confirmed) {
            return btAuthService.deleteAccount($scope.account.email);
          }
        })
        .catch(function (reason) {
          btToastrService.error(reason.message, 'Delete Account');
        });
    }
  }
})();
