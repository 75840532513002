/**
 * Created by Sergey Panpurin on 11/18/2018.
 */

/** @namespace window.Intercom */

// @ts-check
(function btMarketingClosure() {
  'use strict';

  angular.module('ecapp').factory('btMarketing', btMarketing);

  btMarketing.$inject = [
    '$q',
    '$state',
    '$interval',
    '$ionicHistory',
    '$rootScope',
    'btWaitingService',
    'btRestrictionService',
    'btTagService',
    'btSettingsService',
    'btToolsService',
  ];

  /**
   * This service manages back button for releases' and instruments' pages.
   *
   * @ngdoc service
   * @name btMarketing
   * @memberOf ecapp
   * @param {angular.IQService} $q - promise interface
   * @param {angular.ui.IStateService} $state
   * @param {angular.IIntervalService} $interval
   * @param {ionic.IHistoryService} $ionicHistory - ionic history service
   * @param {ecapp.ICustomRootScope} $rootScope
   * @param {ecapp.IWaitingService} btWaitingService
   * @param {ecapp.IRestrictionService} btRestrictionService
   * @param {ecapp.ITagService} btTagService
   * @param {ecapp.ISettingsService} btSettingsService
   * @param {ecapp.IToolsService} btToolsService
   * @return {ecapp.IMarketing}
   */
  function btMarketing(
    $q,
    $state,
    $interval,
    $ionicHistory,
    $rootScope,
    btWaitingService,
    btRestrictionService,
    btTagService,
    btSettingsService,
    btToolsService
  ) {
    console.log('Running btMarketing');

    var gDebug = false;

    // Hide Intercom launcher on mobile, bigbrainbank and linkdataservices.
    var gHideIntercomLauncher =
      !window.isDesktop || btSettingsService.isBigBrainBank() || btSettingsService.isLinkDataService();

    var gInitialIntercomObject = {
      app_id: window.btSettings.BT_INTERCOM_ID,
      hide_default_launcher: gHideIntercomLauncher,
    };

    var gIsIntercom = false;
    var gIsIntercomBooted = !!window.btSettings.BT_INTERCOM_ID;
    var gIsIntercomShown = false;

    var gIsOptimusLiveChat = false;
    var gIntervalHandler = null;

    if (window.isLiveHelpNowInitialized === undefined) {
      window.isLiveHelpNowInitialized = {
        lhnHocButton: false,
        lhnHocInvite: false,
        lhnHelpOutCenter: false,
      };
    }

    /**
     * Intercom user window.intercomSettings as a default settings for update commands.
     * @namespace window.intercomSettings
     */

    /**
     * @namespace window.lhnJsSdk
     * @property {Function} openHOC - ?
     */

    /**
     * @name lhnJsSdkReady - This function will be called when sdk is done loading and ready
     * @type {Function}
     */

    /**
     * @name lhnJsSdkInviteReceived - This function will be called when a new chat invitation is received.
     * @type {Function}
     */

    /**
     * @typedef {Record<string, any>} intercomMessengerAttributes
     * {String} app_id - The app_id of your Intercom app which will indicate where to store any data
     * {String} custom_launcher_selector - The CSS selector of an element to trigger Intercom("show") in order to activate the messenger (See docs). To target an element by ID: "#id_of_element". To target elements by class ".class_name_of_elements"
     * {String} alignment - Dictate the alignment of the default launcher icon to be on the left/right. Possible values: "left" or "right" (any other value is treated as right). (See docs)
     * {Integer} vertical_padding - Move the default launcher icon vertically. Padding from bottom of screen. Minimum value: 20. Does not work on mobile. (See docs)
     * {Integer} horizontal_padding - Move the default launcher icon horizontally. Padding from right side of screen Minimum value: 20. Does not work on mobile. (See docs)
     * {Boolean} hide_default_launcher - Hide the default launcher icon. Setting to false will forcefully show the launcher icon (See docs)
     * {Integer} session_duration - Time in milliseconds for the Intercom session to be considered active. A value of 5 * 60 * 1000 would set the expiry time to be 5 minutes
     */

    /**
     * @typedef {Record<string, any>} intercomDataObject
     */

    if (window.btSettings.BT_DOMAIN === 'optimusfutures') {
      window.lhnJsSdkInviteReceived = lhnJsSdkInviteReceived;
    }

    return {
      visit: visit,
      login: login,
      logout: logout,
      askQuestion: askQuestion,
      updateClientData: updateClientData,
      updateVisitedPage: updateVisitedPage,
    };

    // Public API

    /**
     * User visit application
     */
    function visit() {
      openVisitorIntercom();
    }

    /**
     * User log in to application
     * @param {btUser} user - user object
     * @param {btSubscriptionObject} subscription - subscription object
     */
    function login(user, subscription) {
      if (window.intercom) {
        window.intercom.registerIdentifiedUser({ email: user.email });
      }

      var tag = btTagService.remarketing.parse(user, subscription);
      btTagService.remarketing.send(tag);

      if (btSettingsService.isOptimusFutures() && !btRestrictionService.hasFeature('personalization')) {
        openVisitorIntercom();
      } else {
        hidePreviousIntercom({ shutdown: true }).then(function () {
          openClientIntercom(user, subscription, tag);
        });
      }
    }

    /**
     * User log out from application
     */
    function logout() {
      if (window.intercom) {
        window.intercom.logout();
      }

      hidePreviousIntercom({ shutdown: true }).then(function () {
        openVisitorIntercom();
      });
    }

    /**
     * User ask have questions
     * @param {String} [text] - text of questions
     */
    function askQuestion(text) {
      if (btSettingsService.isBigBrainBank()) {
        // window.open('http://chatwasap.com/9ae009', '_system');
        if (window.isApp) {
          window.Tawk_API.showWidget();
          setTimeout(function () {
            window.Tawk_API.maximize();
          }, 100);
        } else {
          window.Tawk_API.maximize();
        }
      } else {
        if (gIsIntercom) {
          openIntercomWindow(text);
        } else {
          openOptimusLiveChat();
        }
      }
    }

    /**
     * Update user data
     * @param {Object} [data] - update of user data
     */
    function updateClientData(data) {
      if (gIsIntercom) {
        updateIntercom(data);
      }
    }

    /**
     * Update current page
     * @param {String} pageName - current page name
     */
    function updateVisitedPage(pageName) {
      void pageName;
      if (gIsIntercom) {
        updateIntercom();
      }
    }

    // End Public API

    // Common Messenger Interface

    /**
     * Open live chat for visitor
     */
    function openVisitorIntercom() {
      if (window.btSettings.BT_DOMAIN === 'optimusfutures') {
        setIntercomDefault('hide_default_launcher', true);
        window.intercomSettings.hide_default_launcher = true;

        showOptimusLiveChat()
          .then(function () {
            if (gDebug) console.log('btMarketing: Optimus live chat was shown');
          })
          .catch(function (reason) {
            if (gDebug) console.log("btMarketing: Optimus live chat wasn't shown due to error");
            console.error(reason);
          });

        if (gIsIntercomBooted) {
          shutdownIntercom();
        }
      } else {
        setIntercomDefault('hide_default_launcher', gHideIntercomLauncher);
        window.intercomSettings.hide_default_launcher = gHideIntercomLauncher;

        bootIntercom({ app_id: window.btSettings.BT_INTERCOM_ID, hide_default_launcher: gHideIntercomLauncher });
      }
    }

    /**
     * Open live chat for clients.
     *
     * @param {btUser} user - user object
     * @param {btSubscriptionObject} subscription - subscription object
     * @param {String} tag - remarketing tag
     */
    function openClientIntercom(user, subscription, tag) {
      setIntercomDefault('hide_default_launcher', gHideIntercomLauncher);
      window.intercomSettings.hide_default_launcher = gHideIntercomLauncher;

      var data = {
        app_id: window.btSettings.BT_INTERCOM_ID,
        name: user.firstName,
        email: user.email,
        created_at: new Date(user.created),
        opened_at: new Date(user.opened),
        user_hash: user.intercomHash,
        plan: subscription.name,
        variant: user.variant,
        app_version: window.mainVersion,
        remarketing_tag: tag,
        hide_default_launcher: gHideIntercomLauncher,
      };

      data = Object.assign(data, btToolsService.getUTMTags());

      if (subscription.optimus && subscription.optimus.funded) {
        btTagService.optimus.login('funded');
        $rootScope.optimusFunded = true;
        data.company = { company_id: 'optimus-futures', name: 'Optimus Futures' };
      }

      if (btSettingsService.isBigBrainBank() && subscription.bigbrainbank) {
        if (subscription.bigbrainbank.funded) {
          btTagService.bigbrainbank.login('funded');
          $rootScope.bigbrainbankFunded = true;
        }
        data.company = { company_id: 'bigbrainbank', name: 'BigBrainBank' };
      }

      if (btSettingsService.isLinkDataService() && user.domain === 'linkdataservice') {
        data.company = { company_id: 'linkdataservice', name: 'Link Data Services' };
      }

      bootIntercom(data);
    }

    /**
     * Hide previous live chat
     *
     * @param {{shutdown: Boolean}} params - parameters (shutdown - shutdown Intercom)
     * @return {angular.IPromise<*>|angular.IPromise<*>}
     */
    function hidePreviousIntercom(params) {
      if (params === undefined) {
        params = {};
      }

      var deferred = $q.defer();

      if (params.shutdown) {
        window.setTimeout(function () {
          shutdownIntercom();
        }, 1000);
      }

      if (window.btSettings.BT_DOMAIN === 'optimusfutures') {
        hideOptimusLiveChat()
          .then(function () {
            if (gDebug) console.log('btMarketing: Optimus live chat was hided');
          })
          .catch(function (reason) {
            if (gDebug) console.log("btMarketing: Optimus live chat wasn't hided due to error");
            console.error(reason);
          });
      }

      window.setTimeout(function () {
        deferred.resolve();
      }, 2000);

      return deferred.promise;
    }

    // End Common Messenger Interface

    // Intercom API

    /**
     * Set default Intercom settings
     * @param {String} key - property name
     * @param {*} value - property value
     */
    function setIntercomDefault(key, value) {
      if (window.intercomSettings) {
        window.intercomSettings[key] = value;
      }
    }

    /**
     * Boot Intercom
     * @param {intercomDataObject} [data] - Intercom parameters
     */
    function bootIntercom(data) {
      if (gDebug) console.log('bootIntercom', data);

      if (data === undefined) {
        data = gInitialIntercomObject;
      }

      if (window.Intercom) {
        gIsIntercomBooted = true;
        gIsIntercom = true;
        gIsIntercomShown = !data.hide_default_launcher;

        window.Intercom('boot', data);
      }

      if (window.btSettings.BT_DOMAIN === 'optimusfutures') {
        startAutoHide();
      }
    }

    /**
     * Update Intercom data
     * @param {intercomDataObject} [data] - Intercom parameters
     */
    function updateIntercom(data) {
      if (gDebug) console.log('updateIntercom', data);

      if (window.Intercom) {
        if (data) {
          if (gIsIntercomShown) {
            data.hide_default_launcher = gHideIntercomLauncher;
          }
          window.Intercom('update', data);
        } else {
          window.Intercom('update');
        }
      }
    }

    /**
     * Open Intercom window
     * @param {String} [text] - new message text
     */
    function openIntercomWindow(text) {
      if (window.intercom) {
        if (text) {
          window.intercom.displayMessageComposerWithInitialMessage(text);
        } else {
          window.intercom.displayMessageComposer();
        }
      } else if (window.Intercom) {
        if (text) {
          window.Intercom('showNewMessage', text);
        } else {
          window.Intercom('show');
        }
      }
    }

    /**
     * Shutdown Intercom
     */
    function shutdownIntercom() {
      if (gDebug) console.log('btMarketing: Shutdown Intercom');

      if (window.btSettings.BT_DOMAIN === 'optimusfutures') {
        stopAutoHide();
      }

      if (window.Intercom) {
        gIsIntercomBooted = false;
        gIsIntercomShown = false;
        gIsIntercom = false;
        window.Intercom('shutdown');
      }
    }

    // End Intercom API

    // LiveHelpNow API

    /**
     * Show LiveHelpNow chat button and help out center
     * @return {angular.IPromise<*>}
     */
    function showOptimusLiveChat() {
      if (gDebug) console.log('btMarketing: Show Optimus live chat');

      if (window.btSettings.BT_DOMAIN === 'optimusfutures') {
        stopAutoHide();

        var lhnHocButton = showElement('lhnHocButton').then(function () {
          gIsOptimusLiveChat = true;
        });

        var lhnHocInvite = showElement('lhnHocInvite').catch(skipError);

        var lhnHelpOutCenter = showElement('lhnHelpOutCenter');

        return $q.all([lhnHocButton, lhnHocInvite, lhnHelpOutCenter]);
      } else {
        return $q.resolve();
      }
    }

    /**
     * Hide LiveHelpNow chat button and help out center
     * @return {angular.IPromise<*>}
     */
    function hideOptimusLiveChat() {
      if (gDebug) console.log('btMarketing: Hide Optimus live chat');

      if (window.btSettings.BT_DOMAIN === 'optimusfutures') {
        var lhnHocButton = hideElement('lhnHocButton').then(function () {
          gIsOptimusLiveChat = false;
        });

        var lhnHocInvite = hideElement('lhnHocInvite').catch(skipError);

        var lhnHelpOutCenter = hideElement('lhnHelpOutCenter');

        return $q.all(lhnHocButton, lhnHocInvite, lhnHelpOutCenter);
      } else {
        return $q.resolve();
      }
    }

    /**
     * Hide LiveHelpNow element
     *
     * @param {String} id - element id
     * @param {Number} [attempts] - number of attempts
     * @param {Number} [delay] - delay between attempts
     * @return {angular.IPromise<String>}
     */
    function hideElement(id, attempts, delay) {
      return $q.resolve();

      // attempts = attempts || 5;
      // delay = delay || 500;
      //
      // return btWaitingService.retry(attempts, delay, function () {
      //   var element = document.getElementById(id);
      //   if (element && element.style) {
      //     if (gDebug) console.log('btMarketing: Hide ' + id + ' (' + element.style.display + ')');
      //     element.style.display = 'none';
      //     window.isLiveHelpNowInitialized[id] = true;
      //     return true;
      //   } else {
      //     return false;
      //   }
      // }).catch(function (reason) {
      //   if (reason && reason.message === 'failed') {
      //     return $q.reject(new Error('Can\'t find ' + id));
      //   } else {
      //     return $q.reject(reason);
      //   }
      // });
    }

    /**
     * Show LiveHelpNow element
     *
     * @param {String} id - element id
     * @param {Number} [attempts] - number of attempts
     * @param {Number} [delay] - delay between attempts
     * @return {angular.IPromise<String>}
     */
    function showElement(id, attempts, delay) {
      return $q.resolve();

      // attempts = attempts || 5;
      // delay = delay || 500;
      // return btWaitingService.retry(attempts, delay, function () {
      //   var element = document.getElementById(id);
      //   if (element && element.style) {
      //     if (gDebug) console.log('btMarketing: Element ' + id + 'was found (' + element.style.display + ')');
      //     if (window.isLiveHelpNowInitialized[id]) {
      //       if (gDebug) console.log('btMarketing: Reinitialize');
      //       element.style.display = 'block';
      //     } else {
      //       if (gDebug) console.log('btMarketing: Use default initialization');
      //       window.isLiveHelpNowInitialized[id] = true;
      //     }
      //     return true;
      //   } else {
      //     return false;
      //   }
      // }).catch(function (reason) {
      //   if (reason && reason.message === 'failed') {
      //     return $q.reject(new Error('Can\'t find ' + id));
      //   } else {
      //     return $q.reject(reason);
      //   }
      // });
    }

    /**
     *  Open LiveHelpNow help out center
     */
    function openOptimusLiveChat() {
      if (gDebug) console.log('btMarketing: open Optimus live chat window');

      if (window.lhnJsSdk) {
        window.lhnJsSdk.openHOC();
      }
    }

    /**
     * Launch on new invite
     *
     * @param {String} message - message text
     * @param {Number} delay - delay in seconds
     * @param {String} type - type (manual)
     */
    function lhnJsSdkInviteReceived(message, delay, type) {
      if (gDebug) console.log('btMarketing: receive new invite', message, delay, type);

      if (!gIsOptimusLiveChat) {
        setTimeout(function () {
          hideElement('lhnHocInvite').catch(printError);
        }, delay * 1000);
      }
    }

    /**
     *
     */
    function startAutoHide() {
      gIntervalHandler = $interval(
        function () {
          var lhnHocButton = hideElement('lhnHocButton', 1, 500);
          var lhnHocInvite = hideElement('lhnHocInvite', 1, 500);
          var lhnHelpOutCenter = hideElement('lhnHelpOutCenter', 1, 500);

          $q.all([lhnHocButton, lhnHocInvite, lhnHelpOutCenter]).catch(skipError);
        },
        10000,
        0,
        false
      );
    }

    /**
     *
     */
    function stopAutoHide() {
      if (gIntervalHandler) {
        $interval.cancel(gIntervalHandler);
        gIntervalHandler = null;
      }
    }

    /**
     *
     * @param {*} reason
     */
    function printError(reason) {
      console.error('btMarketing:', reason);
    }

    /**
     *
     * @param {*} reason
     */
    function skipError(reason) {
      console.log('btMarketing:', reason.message);
    }

    // End LiveHelpNow API
  }
})();
