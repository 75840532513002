(function btNewsAlertsSettingsController() {
  'use strict';

  var gDebug = false;
  var gPrefix = 'btNewsAlertsSettingsController';

  angular.module('ecapp').controller('btNewsAlertsSettingsController', btNewsAlertsSettingsController);

  btNewsAlertsSettingsController.$inject = [
    '$scope',
    '$q',
    '$ionicLoading',
    'btVoiceAssistantHelperService',
    'btNewsAlertsService',
    'btToastrService',
  ];

  /**
   * Controller for news alerts
   *
   * @ngdoc controller
   * @name ecapp.btNewsAlertsController
   * @param {*} $scope - controller scope
   * @param {angular.IQService} $q
   * @param {ionic.ILoadingService} $ionicLoading
   * @param {ecapp.IVoiceAssistantHelperService} btVoiceAssistantHelperService -
   * @param {ecapp.INewsAlertsService} btNewsAlertsService -
   * @param {ecapp.IShareScopeService} btShareScopeService -
   */
  function btNewsAlertsSettingsController(
    $scope,
    $q,
    $ionicLoading,
    btVoiceAssistantHelperService,
    btNewsAlertsService,
    btToastrService,
  ) {
    if (gDebug) console.log('Running btNewsAlertsSettingsController');

    $scope.setting = [];
    $scope.isNewsReadable = btVoiceAssistantHelperService.getNewsReadable();
    $scope.isNewsNotifiable = btVoiceAssistantHelperService.getNewsNotifiable();
    $scope.hasPushNotification = false;

    $scope.isLoadingCustomChannels = false;

    $scope.toggleChannel = toggleChannel;

    $scope.toggleNewsAlerts = toggleNewsAlerts;
    $scope.toggleNewsReadable = changeNewsReadable;
    $scope.toggleInAppNotification = changeInAppNotification;
    $scope.togglePushNotification = changePushNotification;
    $scope.openIntercom = openIntercom;

    $scope.openCaseStudy = function () {
      window.open(
        'https://bettertrader.co/case-studies/how-trumps-tweets-influence-the-oil-market-in-real-time.html',
        '_system'
      );
    };

    $scope.$on('$destroy', onDestroy);

    activate();

    /**
     * This function activates controller
     */
    function activate() {
      if (gDebug) console.log(gPrefix, 'activate');
      $scope.isLoadingCustomChannels = true;

      // Getting default sources
      btVoiceAssistantHelperService
        .initialize()
        .then(function () {
          $scope.setting = btVoiceAssistantHelperService.findSettings('news-crawler');
          $scope.hasPushNotification = btVoiceAssistantHelperService.getNewsPushNotification();
          $scope.isLoadingCustomChannels = false;
        })
        .catch(function (reason) {
          console.error(reason);
          btToastrService.error('Initialization failed! ' + (reason.message || 'Unknown error.'), 'News Alerts');
        });
    }

    /**
     * This function is a controller destructor
     */
    function onDestroy() {
      if (gDebug) console.log('Destroy btNewsAlertsSettingsController');
    }

    /**
     * This function turns on and turn off News Alerts
     * @param {String} name - name of settings
     * @param {Boolean} position - toggle position
     * @return {angular.IPromise<Boolean>}
     */
    function toggleNewsAlerts(name, position) {
      if (gDebug) console.log('btNewsAlertsController: toggleNewsAlerts', name, position);
      return btVoiceAssistantHelperService.changeConfiguration(name, position).catch(function (reason) {
        console.error(reason);
        btToastrService.error('Adding failed! ' + (reason.message || 'Unknown error.'), 'News Alerts');
        return false;
      });
    }

    /**
     * This function toggles the source following
     *
     * @param {String} name - settings name
     * @param {Boolean} position - toggle position
     * @return {angular.IPromise<Boolean>}
     */
    function toggleChannel(name, position) {
      var channel = btNewsAlertsService.findByName(name);

      if (channel === undefined) {
        return $q.resolve(false);
      }

      return changePosition()
        .catch(function (reason) {
          if (reason && reason.message === 'Upgrade is required!') {
            btToastrService.warning('Update your plan to follow custom twitter channels', 'News Alerts');
          } else {
            console.error(reason);
            btToastrService.error('Action failed! ' + (reason.message || 'Unknown error.'), 'News Alerts');
          }
          return false;
        })
        .finally(function () {
          $ionicLoading.hide();
        });

      /**
       *
       * @return {any}
       */
      function changePosition() {
        if (position) {
          $ionicLoading.show({ template: '<ion-spinner icon="ios"></ion-spinner><p>Start following...</p>' });
          return followChannel(channel);
        } else {
          $ionicLoading.show({ template: '<ion-spinner icon="ios"></ion-spinner><p>Stop following...</p>' });
          return unfollowChannel(channel);
        }
      }
    }

    /**
     * This function starts follow source
     *
     * @param {NewsSource} source - selected source
     * @return {angular.IPromise<Boolean>}
     */
    function followChannel(source) {
      return btNewsAlertsService.follow(source).then(function (status) {
        if (status) {
          btToastrService.success('You started following news on ' + source.name, 'News Alerts');
          return btVoiceAssistantHelperService.changeConfiguration('news-crawler.' + source.name, true);
        } else {
          return $q.reject(new Error('Unknown error.'));
        }
      });
    }

    /**
     * This function stops follow source
     *
     * @param {NewsSource} source - selected source
     * @return {angular.IPromise<Boolean>}
     */
    function unfollowChannel(source) {
      return btNewsAlertsService.unfollow(source).then(function (status) {
        if (status) {
          btToastrService.success('You stopped following news on ' + source.name, 'News Alerts');
          return btVoiceAssistantHelperService.changeConfiguration('news-crawler.' + source.name, false);
        } else {
          return $q.reject(new Error('Unknown error.'));
        }
      });
    }

    /**
     * This function changes news alerts readable
     *
     * @param {String} name - settings name
     * @param {Boolean} position - toggle position
     * @return {Boolean}
     */
    function changeNewsReadable(name, position) {
      void name;
      return btVoiceAssistantHelperService.setNewsReadable(position);
    }

    /**
     * This function changes news alerts notifiable
     *
     * @param {String} name - settings name
     * @param {Boolean} position - toggle position
     * @return {Boolean}
     */
    function changeInAppNotification(name, position) {
      void name;
      btVoiceAssistantHelperService.setNewsNotifiable(position);
      return true;
    }

    /**
     * This function changes push notification settings.
     *
     * @param {string} name - settings name
     * @param {boolean} position - toggle position
     * @return {boolean}
     */
    function changePushNotification(name, position) {
      void name;
      btVoiceAssistantHelperService.setNewsPushNotification(position);
      return true;
    }

    /**
       * This function opens Intercom popup.
       *
       * @return {void}
     */
    function openIntercom() {
      Intercom('showNewMessage', 'Please add this source of news:')
    }
  }
})();
