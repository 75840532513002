/**
 * Created by Sergey Panpurin on 09/04/2017.
 */

// @ts-check
(function btCTraderApiServiceClosure() {
  'use strict';

  angular
    .module('ecapp')
    /**
     * This factory works with cTrader API.
     *
     * @ngdoc service
     * @name btCTraderApiService
     * @memberOf ecapp
     */
    .factory('btCTraderApiService', btCTraderApiService);

  btCTraderApiService.$inject = [
    '$q',
    '$http',
    '$interval',
    '$timeout',
    'btSettings',
    'btInstrumentsService',
    '$ionicPopup',
    'btOauthService',
    'btDateService',
    'btPriceService',
    'btTemplateApiService',
  ];

  /**
   *
   * @param {angular.IQService} $q
   * @param {angular.IHttpService} $http
   * @param {angular.IIntervalService} $interval
   * @param {angular.ITimeoutService} $timeout
   * @param {ecapp.ISettingsService} btSettings
   * @param {ecapp.IInstrumentsService} btInstrumentsService
   * @param {ionic.IPopupService} $ionicPopup
   * @param {ecapp.IOauthService} btOauthService
   * @param {ecapp.IDateService} btDateService
   * @param {ecapp.IPriceService} btPriceService
   * @param {ecapp.ITemplateApiService} btTemplateApiService
   * @return {ecapp.ICTraderApiService}
   */
  function btCTraderApiService(
    $q,
    $http,
    $interval,
    $timeout,
    btSettings,
    btInstrumentsService,
    $ionicPopup,
    btOauthService,
    btDateService,
    btPriceService,
    btTemplateApiService
  ) {
    console.log('Running btCTraderApiService');

    var gUserData = null;
    var gIsLoggedIn = false;
    var gAccounts = [];
    var gSelectedAccountId = null;
    var gInstruments = {};
    var gRateMeter = {
      balances: 0,
      positions: 0,
      orders: 0,
      deals: 0,
      all: 0,
    };
    var gSubscriptions = {};

    var gDateFormat = 'YYYYMMDDHHmmss';
    var gDateFormatZero = 'YYYYMMDD000000';

    // var gBaseUrl = 'http://localhost:3000';
    var gBaseUrl = btSettings.BT_BACKEND_URL;

    /**
     * Get one instrument last prices. Send request to oanda every 5 second for realtime Values
     * Used in Markets tab, Event Related instruments
     * @param {string} instrument - Instrument name known by oanda API
     * @return {angular.IPromise<ecapp.ITradingLiveCandle>}
     */
    function getLiveCandleData(instrument) {
      var deferred = $q.defer();

      var accountId = getSelectedAccountId();
      if (accountId === null) {
        deferred.reject(new Error('Select account at first'));
      } else {
        // var url = gBaseUrl + '/spotware/accounts/' + accountId + '/' + encodeURIComponent(instrument) + '/snapshot';
        //
        // var params = {
        //   headers: {'Content-Type': 'application/json'},
        //   params: {
        //     from: btDateService.getYesterday(gDateFormatZero),
        //     to: btDateService.getNowDate(gDateFormat),
        //     access_token: gUserData.token,
        //     mode: gUserData.mode
        //   }
        // };
        _subscribeSpot(instrument)
          // .then(function () {
          //   return $http.get(url, params);
          // })
          // .then(_handleHTTPError)
          .then(function () {
            _getSpot(instrument).then(function (response2) {
              if (response2.data.data.bid === null && response2.data.data.ask === null) {
                response2.data.data.bid = 0;
                response2.data.data.ask = 0;
              } else {
                if (response2.data.data.bid === null) {
                  response2.data.data.ask = response2.data.data.bid;
                }
                if (response2.data.data.ask === null) {
                  response2.data.data.bid = response2.data.data.ask;
                }
              }
              deferred.resolve(_convertLiveCandle(instrument, null, response2.data.data));
            });
          })
          .catch(function (error) {
            deferred.reject(error);
          });
      }
      return deferred.promise;
    }

    /**
     * Convert live candle data
     * @param {string} symbol - symbol
     * @param {ctCandleObject[]} candles - list of hourly candles
     * @param {{bid: Number, ask: Number}} data - bid and ask data
     * @return {ecapp.ITradingLiveCandle} - BetterTrader live candle object
     * @private
     */
    function _convertLiveCandle(symbol, candles, data) {
      var p;
      if (candles === null) {
        p = Math.max(btPriceService.getPricePrecision(data.bid), btPriceService.getPricePrecision(data.ask));
        return {
          symbol: symbol,
          yesterday: {
            close: 0,
            closeText: '0',
          },
          today: {
            low: 0,
            lowText: '0',
            high: 0,
            highText: '0',
            open: 0,
            openText: '0',
          },
          now: {
            bid: data.bid,
            bidText: data.bid.toString(),
            ask: data.ask,
            askText: data.ask.toString(),
            last: parseFloat(((data.bid + data.ask) / 2).toFixed(p)),
            lastText: ((data.bid + data.ask) / 2).toFixed(p),
            // !!! > Function toFixed could produce rounding problems
          },
        };
      }

      if (candles.length < 2) {
        return undefined;
      } else {
        var today = _getLastDay(candles);
        var yesterday = _getDayBeforeLastDay(candles, today);
        p = Math.max(btPriceService.getPricePrecision(data.bid), btPriceService.getPricePrecision(data.ask));
        return {
          yesterday: {
            close: yesterday.close,
            closeText: yesterday.close.toString(),
          },
          today: {
            low: Math.min(today.low, data.bid),
            lowText: Math.min(today.low, data.bid).toString(),
            high: Math.max(today.high, data.ask),
            highText: Math.max(today.high, data.ask).toString(),
            open: today.open,
            openText: today.open.toString(),
          },
          now: {
            bid: data.bid,
            bidText: data.bid.toString(),
            ask: data.ask,
            askText: data.ask.toString(),
            last: parseFloat(((data.bid + data.ask) / 2).toFixed(p)),
            lastText: ((data.bid + data.ask) / 2).toFixed(p),
            // !!! > Function toFixed could produce rounding problems
          },
        };
      }
    }

    /**
     * Aggregate 1 hour candles to daily candles - get last day candle
     * @param {ctCandleObject[]} candles - list of hourly candles
     * @return {btCandleAggregate} - candle aggregate for last day
     * @private
     */
    function _getLastDay(candles) {
      var lastDate = new Date(candles[candles.length - 1].timestamp);
      var lastCandles = candles.filter(function (item) {
        return btDateService.sameDay(new Date(item.timestamp), lastDate);
      });

      return {
        open: lastCandles[0].open,
        close: lastCandles[lastCandles.length - 1].close,
        high: Math.max.apply(
          null,
          lastCandles.map(function (t) {
            return t.high;
          })
        ),
        low: Math.min.apply(
          null,
          lastCandles.map(function (t) {
            return t.low;
          })
        ),
        date: lastDate,
      };
    }

    /**
     * Aggregate 1 hour candles to daily candles - get day before last day candle
     * @param {ctCandleObject[]} candles - list of hourly candles
     * @param {btCandleAggregate} lastDay - candle aggregate for last day
     * @return {btCandleAggregate} - candle aggregate for day before last day
     * @private
     */
    function _getDayBeforeLastDay(candles, lastDay) {
      var date = btDateService.nDaysAgo(lastDay.date, 1);
      var lastCandles = candles.filter(function (item) {
        return btDateService.sameDay(new Date(item.timestamp), date);
      });

      if (lastCandles.length !== 0) {
        return {
          open: lastCandles[0].open,
          close: lastCandles[lastCandles.length - 1].close,
          high: Math.max.apply(
            null,
            lastCandles.map(function (t) {
              return t.high;
            })
          ),
          low: Math.min.apply(
            null,
            lastCandles.map(function (t) {
              return t.low;
            })
          ),
          date: date,
        };
      } else {
        return {
          open: 0,
          close: lastDay.open,
          high: 0,
          low: 0,
          date: lastDay.date,
        };
      }
    }

    /**
     * Get one instrument n Candles with frequency defined by granularity parameter. Send request to oanda
     * @param {*} instrument - Instrument name known by oanda API
     * @param {*} granularity - Set the candle time representation
     * @param {number} [count] - number of candles
     * @return {angular.IPromise<*>}
     * TODO: Add granularity support
     */
    function getLastCandlesData(instrument, granularity, count) {
      var deferred = $q.defer();

      var accountId = getSelectedAccountId();
      if (accountId === null) {
        deferred.reject(new Error('Select account at first'));
      } else {
        var url = gBaseUrl + '/spotware/accounts/' + accountId + '/' + encodeURIComponent(instrument) + '/snapshot';

        var params = {
          headers: { 'Content-Type': 'application/json' },
          params: {
            from: btDateService.getYesterday(gDateFormatZero),
            to: btDateService.format(btDateService.getNowDate(), gDateFormat),
            access_token: gUserData.token,
            interval: granularity,
            mode: gUserData.mode,
            count: count,
          },
        };
        $http
          .get(url, params)
          .then(function (response) {
            var convertedCandles = [];

            response.data.data.forEach(function (candle) {
              convertedCandles.push(_convertLastCandle(candle));
            });

            deferred.resolve({ candles: convertedCandles });
          })
          .catch(function (error) {
            deferred.reject(error);
          });
      }
      return deferred.promise;
    }

    /**
     * Convert last candle data
     * @param {ctCandleObject} candle - cTrader candle object
     * @return {ecapp.ITradingLastCandle} - BetterTrader last candle object
     * @private
     */
    function _convertLastCandle(candle) {
      return {
        complete: true,
        mid: {
          c: candle.close,
          h: candle.high,
          l: candle.low,
          o: candle.open,
        },
        time: candle.timestamp / 1000,
        volume: candle.volume / 100,
      };
    }

    /* --- Public functions --- */

    /**
     * Initialize service
     * @param {Object} data - access data
     * @return {angular.IPromise<*>}
     */
    function initialize(data) {
      var deferred = $q.defer();

      if (data == null) {
        deferred.reject(new Error('Bad access data'));
      } else {
        gUserData = data;
        if (gUserData.defaultAccount) {
          gSelectedAccountId = gUserData.defaultAccount;
        }
        deferred.resolve({});
      }
      return deferred.promise;
    }

    /**
     * Connect Broker
     * @return {angular.IPromise<*>}
     */
    function connect() {
      var deferred = $q.defer();
      console.log('btCTraderApiService: connect');
      if (gUserData != null) {
        if (gIsLoggedIn) {
          deferred.resolve({});
        } else {
          setTradingMode(gUserData.mode);
          gIsLoggedIn = true;
          deferred.resolve({});
        }
      } else {
        gIsLoggedIn = false;
        deferred.reject(new Error('Error'));
      }
      return deferred.promise;
    }

    /**
     * Disconnect broker
     * @return {angular.IPromise<*>}
     */
    function disconnect() {
      console.log('btCTraderApiService: disconnect');
      return logout();
    }

    /**
     * Login broker
     * @param {String} mode - trading mode: real ot demo
     * @param {Boolean} isForceLogin - force login or not
     * @return {angular.IPromise<*>}
     */
    function login(mode, isForceLogin) {
      void isForceLogin;

      return btOauthService.login('spotware-' + mode).then(function (data) {
        console.log('btCTraderApiService: login');
        // reset selected account after default broker
        gSelectedAccountId = null;
        gUserData = { token: data.accessToken, mode: mode };
        // window.localStorage.setItem('OANDAToken', JSON.stringify(userData));
        setTradingMode(mode);

        return gUserData;
      });
    }

    /**
     * Fast Login Broker
     *
     * @param {String} mode - trading mode: real ot demo
     * @param {Object} data - access data
     * @return {angular.IPromise<*>}
     */
    function fastLogin(mode, data) {
      console.log('btCTraderApiService: login');
      // reset selected account after default broker
      gSelectedAccountId = null;
      gUserData = { token: data.sso_token, mode: mode };
      setTradingMode(mode);

      return $q.resolve(gUserData);
    }

    /**
     * Logout Broker
     * @return {angular.IPromise<*>}
     */
    function logout() {
      var deferred = $q.defer();
      console.log('btCTraderApiService: logout');
      gUserData = null;
      gIsLoggedIn = false;
      gAccounts = [];
      gSelectedAccountId = null;
      gInstruments = {};
      gRateMeter = {
        balances: 0,
        positions: 0,
        orders: 0,
        deals: 0,
        all: 0,
      };
      gSubscriptions = {};
      deferred.resolve({});
      return deferred.promise;
    }

    /**
     * Check user data (now just username)
     * @param {Object} userData - user data
     * @return {angular.IPromise<*>}
     */
    function checkUser(userData) {
      void userData;
      return $q.reject(new Error('Wait'));
    }

    /**
     * Create username from email
     * @param {String} email - user email as feed to create username
     * @return {angular.IPromise<any>}
     */
    function getUsername(email) {
      void email;
      return $q.reject(new Error('Wait'));
    }

    /**
     * Create new OANDA account via API
     * @param {Object} userData - user data
     * @return {angular.IPromise<any>}
     */
    function signUp(userData) {
      void userData;
      return $q.reject(new Error('Wait'));
    }

    /**
     * Is user logged in to TradeStation API
     * @return {Boolean}
     */
    function isLoggedIn() {
      return gUserData !== null;
    }

    /**
     * Get trading mode: live or practice
     * @return {String} - trading mode: "demo" or "real"
     */
    function getTradingMode() {
      return gUserData.mode;
    }

    /**
     * Set trading mode. Return true on success.
     * @param {*} mode
     * @return {boolean} trading mode was changed
     */
    function setTradingMode(mode) {
      gUserData.mode = mode;
      return true;
    }

    /* --- User data --- */
    /**
     * Get list of user accounts for selected broker
     * @return {angular.IPromise<Array | Error>} - list of accounts
     */
    function getAccounts() {
      var deferred = $q.defer();

      if (gAccounts.length > 0) {
        deferred.resolve(gAccounts);
        return deferred.promise;
      }

      var params = {
        headers: { 'Content-Type': 'application/json' },
        params: {
          access_token: gUserData.token,
          mode: gUserData.mode,
        },
      };

      $http
        .get(gBaseUrl + '/spotware/accounts', params)
        .then(function (response) {
          // console.log(response);

          // filter accounts and convert to BetterTrader format
          gAccounts = response.data.data.filter(_filterDeleted).map(_convertAccount);

          // reset selected account
          gSelectedAccountId = btTemplateApiService.resetSelectedAccount(gAccounts, gSelectedAccountId);

          deferred.resolve(gAccounts);
        })
        .catch(function (error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    /**
     * Filter deleted accounts
     * @param {ctAccountObject} account - cTrader account data
     * @return {Boolean} - account pass filter
     * @private
     */
    function _filterDeleted(account) {
      return !account.deleted;
    }

    /**
     * Convert account data
     * @param {ctAccountObject} account - cTrader account object
     * @return {ecapp.ITradingAccount} - BetterTrader account object
     * @private
     */
    function _convertAccount(account) {
      return {
        // alias: account.accountId,
        // altId: account.accountId,
        // displayName: account.accountId,
        // isStockLocateEligible: false,
        acc: account.accountId,
        key: account.accountId,
        name: account.accountId,
        // type: '',
        // typeDescription: '',
        rawData: account,
      };
    }

    /**
     * Get balance for accounts
     * @param {Array} accountIds - list of account ids
     * @return {angular.IPromise<Array>}
     */
    function getBalances(accountIds) {
      var deferred = $q.defer();

      // var params = {
      //   headers: {'Content-Type': 'application/json'},
      //   params: {
      //     access_token: gUserData.token,
      //     mode: gUserData.mode
      //   }
      // };

      var promises = accountIds.map(function (accountId) {
        return _getPromise(accountId, 'balances');
        // return $http.get(gBaseUrl + '/spotware/accounts/' + accountId + '/balances', params);
      });

      $q.all(promises)
        .then(function (responses) {
          // console.log(responses);

          var convertedBalances = responses.map(function (response) {
            return _convertBalances(response.data.data);
          });

          deferred.resolve(convertedBalances);
        })
        .catch(function (error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    /**
     * Convert balance data
     * @param {ctBalanceObject} balance - cTrader balance object
     * @return {ecapp.ITradingBalance} - BetterTrader balance object
     * @private
     */
    function _convertBalances(balance) {
      // TODO: Use real data for margin
      return {
        acc: balance.accountId.toString(),
        key: balance.accountId.toString(),
        name: balance.accountId.toString(),
        type: balance.depositCurrency,
        NAV: 100,
        UPL: 0,
        Balance: parseFloat(balance.balance / 100),
        RPL: 0,
        MarginUsed: 1,
        MarginAvailable: 100,
        rawData: balance,

        // typeDescription: '',
        // alias: balance.accountId,
        // displayName: balance.accountId,
      };
    }

    /**
     * Get all positions for accounts
     * @param {Array} accountIds - list of account ids
     * @return {angular.IPromise<Array | Error>}
     */
    function getPositions(accountIds) {
      var deferred = $q.defer();

      // var params = {
      //   headers: {'Content-Type': 'application/json'},
      //   params: {
      //     access_token: gUserData.token,
      //     mode: gUserData.mode
      //   }
      // };

      var promises = accountIds.map(function (accountId) {
        return _getPromise(accountId, 'positions');
        // return $http.get(gBaseUrl + '/spotware/accounts/' + accountId + '/positions', params);
      });

      $q.all(promises)
        .then(function (responses) {
          // console.log(responses);

          var convertedPositions = [];

          responses.forEach(function (response, i) {
            response.data.data.forEach(function (position) {
              convertedPositions.push(_convertPosition(position, accountIds[i]));
            });
          });

          deferred.resolve(convertedPositions);
        })
        .catch(function (error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    /**
     *
     * @param {ctPositionObject} position
     * @param {String} accountId
     * @return {ecapp.ITradingPosition}
     * @private
     */
    function _convertPosition(position, accountId) {
      // TODO: Add some values
      // monetary: volume, profit, commission, swap

      /**
       *
       * @param {*} position
       * @return {any}
       */
      function netProfit(position) {
        return (position.profit + 2 * position.commission + position.swap) / 100;
      }

      return {
        alias: accountId,
        description: position.symbolName,
        key: position.positionId.toString(),
        acc: accountId,
        symbol: position.symbolName,
        position: position.tradeSide === 'BUY' ? 'Long' : 'Short',
        quantity: position.tradeSide === 'BUY' ? position.volume / 100 : -(position.volume / 100),
        OPL: position.profit ? netProfit(position) : 'N/A',
        acct: position.profit ? netProfit(position) / (position.entryPrice * (position.volume / 100)) : 'N/A',
        total: 0,
        margin: 0, // position.marginRate * position.volume / account.leverage,
        avg: position.entryPrice,
        mrkValue: position.entryPrice * (position.volume / 100),
        lastPrice: position.currentPrice,
        rawData: position,
      };
    }

    /**
     * Get all orders for accounts
     * @param {Array} accountIds - list of account ids
     * @return {angular.IPromise<Array | Error>}
     */
    function getOrders(accountIds) {
      var deferred = $q.defer();

      var promises = [];
      accountIds.forEach(function (accountId) {
        promises.push(_getPromise(accountId, 'orders'));
        promises.push(_getPromise(accountId, 'deals'));
      });

      $q.all(promises)
        .then(function (responses) {
          // console.log(responses);

          var convertedOrders = [];

          responses.forEach(function (response, i) {
            var positions = response.data.data;
            positions.forEach(function (order) {
              convertedOrders.push(_convertOrder(order, accountIds[i]));
            });
          });

          deferred.resolve(convertedOrders);
        })
        .catch(function (error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    /**
     *
     * @param {*} accountId
     * @param {*} type
     * @return {any}
     */
    function _getPromise(accountId, type) {
      var deferred = $q.defer();

      var m_type = 'all';
      console.log('Test cTrader add:', type, gRateMeter[m_type], new Date());

      gRateMeter[m_type] += 1;
      var delay = gRateMeter[m_type] * 1000;

      var url = gBaseUrl + '/spotware/accounts/' + accountId + '/' + type;
      var params = {
        headers: {
          'Content-Type': 'application/json',
        },
        params: {
          access_token: gUserData.token,
          mode: gUserData.mode,
        },
      };

      $timeout(someFunc, delay, false);

      /**
       *
       */
      function someFunc() {
        console.log('Test cTrader execute:', type, gRateMeter[m_type], new Date());
        $http
          .get(url, params)
          .then(function (promiseValue) {
            deferred.resolve(promiseValue);
          })
          .catch(function (reason) {
            deferred.reject(reason);
          })
          .finally(function () {
            gRateMeter[m_type] -= 1;
            gRateMeter[m_type] = gRateMeter[m_type] < 0 ? 0 : gRateMeter[m_type];
            console.log('Test cTrader finish:', type, gRateMeter[m_type], new Date());
          });
      }

      return deferred.promise;
    }

    /**
     *
     * @param {*} order
     * @param {*} accountId
     * @return {any}
     */
    function _convertOrder(order, accountId) {
      var convertedSides = {
        SELL: 'Short',
        BUY: 'Long',
      };

      var convertedActions = {
        SELL: 'Sell',
        BUY: 'Buy',
      };

      if (order.dealId) {
        return {
          alias: accountId,
          displayName: order.symbolName,
          key: order.orderId,
          acc: accountId,
          symbol: order.symbolName,
          quantity: Math.abs(order.volume / 100),
          side: convertedSides[order.tradeSide],
          action: convertedActions[order.tradeSide],
          limit: '-',
          stop: '-',
          price: '-',
          status: 'Filled',
          fillPrice: order.executionPrice,
          filled: '-',
          placeTime: order.createTimestamp,
          executeTime: order.executionTimestamp,
        };
      } else {
        return {
          alias: accountId,
          displayName: order.symbolName,
          key: order.orderId,
          acc: accountId,
          symbol: order.symbolName,
          quantity: Math.abs(order.volume / 100),
          side: order.tradeSide === 'BUY' ? 'Long' : 'Short',
          action: 'sell',
          limit: order.orderType === 'LIMIT' ? parseFloat(order.price) : '-',
          stop: order.orderType === 'STOP' ? parseFloat(order.price) : '-',
          price: parseFloat(order.price),
          status: 'Received',
          fillPrice: '-',
          filled: '-',
          placeTime: order.createTimestamp,
          executeTime: '-',
        };
      }
    }

    /* --- Market Data --- */
    /**
     * Get information about selected symbol
     *
     * @param {String} symbol - symbol name
     * @return {angular.IPromise<ecapp.ITradingInstrument>}
     */
    function getSymbolInfo(symbol) {
      void symbol;
      var deferred = $q.defer();
      deferred.reject(new Error('Coming soon!'));
      return deferred.promise;
    }

    /**
     * Search symbol using query string
     *
     * @param {String} query - symbol name
     * @return {angular.IPromise<Array>} - list of symbol names
     */
    function searchSymbol(query) {
      void query;
      var deferred = $q.defer();
      deferred.reject(new Error('Coming soon!'));
      return deferred.promise;
    }

    /**
     * Search symbol using query string
     *
     * @param {String} text - search text
     * @param {Number} limit - limit number of results
     * @param {Object} params - additional parameters
     * @return {angular.IPromise<Array>} - list of symbol names
     */
    function suggestSymbols(text, limit, params) {
      void params;
      var deferred = $q.defer();

      if (gInstruments[getSelectedAccountId()] === undefined) {
        // load data
        if (getSelectedAccountId() === 0) {
          deferred.reject(new Error('Select account at first'));
        } else {
          var options = {
            headers: { 'Content-Type': 'application/json' },
            params: {
              access_token: gUserData.token,
              mode: gUserData.mode,
            },
          };
          $http
            .get(gBaseUrl + '/spotware/accounts/' + getSelectedAccountId() + '/symbols', options)
            .then(_handleHTTPError)
            .then(function (response) {
              gInstruments[getSelectedAccountId()] = response.data.data;
              deferred.resolve(search(gInstruments[getSelectedAccountId()]));
            })
            .catch(function (error) {
              deferred.reject(error);
            });
        }
      } else {
        deferred.resolve(search(gInstruments[getSelectedAccountId()]));
      }

      return deferred.promise;

      /**
       *
       * @param {*} instrumentList
       * @return {any}
       */
      function search(instrumentList) {
        var data = instrumentList.filter(function (item) {
          return (
            item.symbolName.toLowerCase().indexOf(text.toLowerCase()) !== -1 ||
            item.description.toLowerCase().indexOf(text.toLowerCase()) !== -1
          );
        });

        if (data.length > limit) {
          data = data.slice(0, limit);
        }

        return data.map(function (item) {
          return {
            ticker: item.symbolName,
            name: item.symbolName,
            desc: item.description,
            rawData: item,
          };
        });
      }
    }

    /**
     * Get quotes for list of symbols
     *
     * @param {String[]} symbols
     * @return {angular.IPromise<Array>}
     */
    function getQuotes(symbols) {
      void symbols;
      var deferred = $q.defer();

      var options = {
        headers: { 'Content-Type': 'application/json' },
        params: {
          account_id: getSelectedAccountId(),
          access_token: gUserData.token,
          symbol: symbols[0],
          mode: gUserData.mode,
        },
      };

      var promise = null;
      if (symbols[1] === 'delete') {
        options.params.sub_id = symbols[2];
        promise = $http.delete(gBaseUrl + '/spotware/quote', options);
      } else if (symbols[1] === 'sub') {
        promise = $http.post(gBaseUrl + '/spotware/quote', {}, options);
      } else {
        promise = $http.get(gBaseUrl + '/spotware/quote', options);
      }
      promise
        .then(_handleHTTPError)
        .then(function (response) {
          deferred.resolve(response);
        })
        .catch(function (error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    var gSubscriptionId;

    /**
     *
     * @param {*} symbol
     * @return {any}
     */
    function _subscribeSpot(symbol) {
      var deferred = $q.defer();

      if (gSubscriptions[symbol] === undefined) {
        var options = {
          headers: { 'Content-Type': 'application/json' },
          params: {
            account_id: getSelectedAccountId(),
            access_token: gUserData.token,
            symbol: symbol,
            mode: gUserData.mode,
          },
        };

        $http
          .post(gBaseUrl + '/spotware/quote', {}, options)
          // .then(_handleHTTPError)
          .then(function (response) {
            if (response.data.data && response.data.data.subscriptionId) {
              gSubscriptionId = response.data.data.subscriptionId;
            }
            gSubscriptions[symbol] = response;
            deferred.resolve(response);
          })
          .catch(function (error) {
            deferred.reject(error);
          });
      } else {
        deferred.resolve(gSubscriptions[symbol]);
      }

      return deferred.promise;
    }

    /**
     *
     * @param {*} symbol
     * @return {any}
     */
    function _unsubscribeSpot(symbol) {
      // eslint-disable-line no-unused-vars
      var deferred = $q.defer();

      var options = {
        headers: { 'Content-Type': 'application/json' },
        params: {
          account_id: getSelectedAccountId(),
          access_token: gUserData.token,
          symbol: symbol,
          sub_id: gSubscriptionId,
          mode: gUserData.mode,
        },
      };

      $http
        .delete(gBaseUrl + '/spotware/quote', options)
        .then(_handleHTTPError)
        .then(function (response) {
          deferred.resolve(response);
        })
        .catch(function (error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    /**
     *
     * @param {*} symbol
     * @return {any}
     */
    function _getSpot(symbol) {
      var deferred = $q.defer();

      var options = {
        headers: { 'Content-Type': 'application/json' },
        params: {
          account_id: getSelectedAccountId(),
          access_token: gUserData.token,
          symbol: symbol,
          mode: gUserData.mode,
        },
      };

      $http
        .get(gBaseUrl + '/spotware/quote', options)
        .then(_handleHTTPError)
        .then(function (response) {
          deferred.resolve(response);
        })
        .catch(function (error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    /**
     * Stream snapshot for selected symbol
     *
     * @param {String} symbol - symbol name
     * @param {Function} onProgress - function to call on progress
     * @return {angular.IPromise<*>} - return object to terminate streaming
     */
    function streamQuote(symbol, onProgress) {
      void symbol;
      void onProgress;
      var deferred = $q.defer();
      deferred.reject(new Error('Coming soon!'));
      return deferred.promise;
    }

    /**
     * Get snapshots for list of symbols
     *
     * @param {String[]} symbols
     * @param {Object} range
     * @param {Number} interval -
     * @param {String} unit -
     * @return {angular.IPromise<Array>}
     */
    function getSnapshots(symbols, range, interval, unit) {
      void symbols;
      void range;
      void interval;
      void unit;
      var deferred = $q.defer();

      var options = {
        headers: { 'Content-Type': 'application/json' },
        params: {
          account_id: getSelectedAccountId(),
          access_token: gUserData.token,
          mode: gUserData.mode,
        },
      };

      var promise = null;
      if (symbols[1] === 'un') {
        promise = $http.delete(gBaseUrl + '/spotware/trade', options);
      } else {
        promise = $http.get(gBaseUrl + '/spotware/trade', options);
      }
      promise
        .then(_handleHTTPError)
        .then(function (response) {
          deferred.resolve(response);
        })
        .catch(function (error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    /**
     * Stream snapshot for selected symbol
     *
     * @param {string} ticker - ticker
     * @param {string} granularity - granularity
     * @param {number} back - candles back
     * @param {(bar: ecapp.ITradingCandle) => void} onUpdate - function to call on progress
     * @return {angular.IPromise<{stop: () => void}>} - return object to terminate streaming
     */
    function streamSnapshot(ticker, granularity, back, onUpdate) {
      console.log('streamSnapshot', ticker, granularity, back, onUpdate);
      var deferred = $q.defer();
      deferred.reject(new Error('Coming soon!'));
      return deferred.promise;
    }

    /* --- Order execution --- */
    /**
     * Confirm order
     *
     * @param {ecapp.ITradingOrderRequest} order - order request data
     * @return {angular.IPromise<ecapp.ITradingOrderConfirmation>}
     */
    function confirmOrder(order) {
      void order;
      var deferred = $q.defer();
      deferred.reject(new Error('Coming soon!'));
      return deferred.promise;
    }

    /**
     * Submit order
     *
     * @param {ecapp.ITradingOrderRequest} order - order request data
     * @return {angular.IPromise<ecapp.ITradingOrderResponse>}
     */
    function submitOrder(order) {
      var deferred = $q.defer();

      if (getSelectedAccountId() === null) {
        deferred.reject(new Error('Select account at first'));
      } else {
        var newOrder = _prepareOrder(order);
        var params = { headers: { 'Content-Type': 'application/json' }, params: { mode: gUserData.mode } };
        $http
          .post(gBaseUrl + '/spotware/orders', newOrder, params)
          .then(_handleHTTPError)
          .then(function (response) {
            try {
              response.rawAccount = gAccounts.filter(function (item) {
                return item.key === getSelectedAccountId();
              })[0].rawData;
            } catch (e) {
              console.log(e);
            }

            deferred.resolve(response);
          })
          .catch(function (error) {
            if (!(error instanceof Error)) {
              if (error.data === null) {
                deferred.reject(new Error('Unknown error'));
              } else {
                deferred.reject(error);
              }
            } else {
              deferred.reject(error);
            }
          });
      }
      return deferred.promise;
    }

    /**
     *
     * @param {ecapp.ITradingOrderRequest} order
     * @return {Object}
     * @private
     */
    function _prepareOrder(order) {
      var orderTypes = {
        Market: 1,
        Limit: 2,
        Stop: 3,
        OA_PROTECTION: 4,
        OA_MARKET_RANGE: 5,
        OA_STOP_LIMIT: 6,
      };

      var timesInForce = {
        GTD: 1, // GOOD_TILL_DATE
        GTC: 2, // GOOD_TILL_CANCEL
        IOC: 3, // IMMEDIATE_OR_CANCEL
      };

      var tradeSides = {
        BUY: 1,
        SELL: 2,
        SELL_SHORT: 2,
        BUY_COVER: 1,
      };

      return {
        mode: gUserData.mode,
        account_id: getSelectedAccountId(),
        access_token: gUserData.token,
        symbol: order.symbol,
        order_type: orderTypes[order.type],
        trade_side: tradeSides[order.action],
        volume: order.quantity * 100,
        limit: order.type === 'Limit' ? order.limitPrice : undefined,
        stop: order.type === 'Stop' ? order.limitPrice : undefined,
        stop_loss: order.stopLoss,
        take_profit: order.takeProfit,
        time_in_force: timesInForce[order.timeInForce],
        expiration: order.gtdTime,
        position_id: order.rawPosition ? order.rawPosition.key : undefined,
      };
    }

    /**
     * Update order
     *
     * @param {String} orderId - order id
     * @param {Object} orderChanges - changes in order
     * @return {angular.IPromise<ecapp.ITradingOrderResponse>}
     */
    function updateOrder(orderId, orderChanges) {
      void orderId;
      void orderChanges;
      var deferred = $q.defer();
      deferred.reject(new Error('Coming soon!'));
      return deferred.promise;
    }

    /**
     * Cancel order
     *
     * @param {String} orderId - order id
     * @return {angular.IPromise<ecapp.ITradingOrderResponse>}
     */
    function cancelOrder(orderId) {
      var deferred = $q.defer();

      if (getSelectedAccountId() === null) {
        deferred.reject(new Error('Select account at first'));
      } else {
        var params = {
          headers: { 'Content-Type': 'application/json' },
          params: {
            account_id: getSelectedAccountId(),
            access_token: gUserData.token,
            mode: gUserData.mode,
          },
        };
        $http
          .delete(gBaseUrl + '/spotware/orders/' + orderId, params)
          .then(_handleHTTPError)
          .then(function (response) {
            deferred.resolve(response);
          })
          .catch(function (error) {
            deferred.reject(error);
          });
      }
      return deferred.promise;
    }

    /**
     * Select account
     *
     * @param {String} id - account id
     * @return {?String} id of selected account or null
     */
    function selectAccount(id) {
      var res = gAccounts.filter(function (item) {
        return item.key == id;
      });

      gSelectedAccountId = res ? res[0].key : null;

      return gSelectedAccountId;
    }

    /**
     *
     * @return {any}
     */
    function getSelectedAccountId() {
      return gSelectedAccountId;
    }

    /**
     *
     * @param {*} id
     * @return {any}
     */
    function isAccountSelected(id) {
      return gSelectedAccountId ? gSelectedAccountId == id : false;
    }

    /**
     *
     * @return {any}
     */
    function getAccessData() {
      var deferred = $q.defer();

      deferred.resolve(gUserData);

      return deferred.promise;
    }

    /**
     * Handle Tradier API error
     * @param {Object} response - response object
     * @return {angular.IPromise<Object | Error>} - response contain error
     * @private
     */
    function _handleHTTPError(response) {
      //noinspection EqualityComparisonWithCoercionJS
      if (response.status !== 200 && response.status !== 201) {
        //noinspection EqualityComparisonWithCoercionJS
        if (response.status === 401 || response.status === 405) {
          return $q.reject(new Error(JSON.parse(response.rawBody).errorMessage));
        } else {
          return $q.reject(new Error(response.body.errorMessage));
        }
      } else {
        if (response.data.data.errorCode) {
          return $q.reject(new Error(response.data.data.description));
        } else {
          return $q.resolve(response);
        }
      }
    }

    return {
      // getAllInstruments: getAllInstruments,
      getLiveCandleData: getLiveCandleData,
      getLastCandlesData: getLastCandlesData,

      // trading api
      initialize: initialize,

      connect: connect,
      disconnect: disconnect,

      login: login,
      fastLogin: fastLogin,
      logout: logout,
      signUp: signUp,
      checkUser: checkUser,
      getUsername: getUsername,
      isLoggedIn: isLoggedIn,

      getTradingMode: getTradingMode,
      setTradingMode: setTradingMode,

      getAccounts: getAccounts,
      getBalances: getBalances,
      getPositions: getPositions,
      getOrders: getOrders,

      getSymbolInfo: getSymbolInfo,
      searchSymbol: searchSymbol,
      suggestSymbols: suggestSymbols,
      getQuotes: getQuotes,
      streamQuote: streamQuote,
      getSnapshots: getSnapshots,
      streamSnapshot: streamSnapshot,

      confirmOrder: confirmOrder,
      submitOrder: submitOrder,
      updateOrder: updateOrder,
      cancelOrder: cancelOrder,

      selectAccount: selectAccount,
      isAccountSelected: isAccountSelected,
      getSelectedAccountId: getSelectedAccountId,

      getAccessData: getAccessData,
    };
  }
})();
