/**
 * Created by yatree on 30/09/16.
 */

(function btTutorialCtrlClosure() {
  'use strict';

  var gDebug = false;
  var gModule = 'btTutorialController';
  var gPrefix = gModule + ':';

  angular.module('ecapp').controller('btTutorialController', btTutorialController);

  btTutorialController.$inject = [
    '$scope',
    '$timeout',
    '$interval',
    '$state',
    '$rootScope',
    '$q',
    '$location',
    '$ionicLoading',
    '$ionicSlideBoxDelegate',
    '$ionicScrollDelegate',
    '$analytics',
    'btShareScopeService',
    'btCurrenciesService',
    'btWindowsSizeService',
    'btPushNotificationService',
    'btTradingService',
    'btSettingsService',
    'btToastrService',
    'btMarketing',
    'btRestrictionService',
    'btEventsService',
    'btErrorService',
    'btInstrumentsService',
  ];

  /**
   * This is tutorial page controller.
   *
   * @ngdoc controller
   * @name btTutorialController
   * @memberOf ecapp
   * @param {ecapp.ICustomScope} $scope - common angular scope service
   * @param {angular.ITimeoutService} $timeout -
   * @param {angular.IIntervalService} $interval -
   * @param {angular.ui.IStateService} $state - ?
   * @param {ecapp.ICustomRootScope} $rootScope - ?
   * @param {angular.IQService} $q - promise interface
   * @param {angular.ILocationService} $location - ?
   * @param {ionic.ILoadingService} $ionicLoading - ionic loading service
   * @param {ionic.ISlideBoxDelegateService} $ionicSlideBoxDelegate - ?
   * @param {ionic.IScrollDelegateService} $ionicScrollDelegate
   * @param {angulartics.IAnalyticsService} $analytics
   * @param {ecapp.IShareScopeService} btShareScopeService - ?
   * @param {ecapp.ICurrenciesService} btCurrenciesService - factory for working with currencies
   * @param {ecapp.IWindowsSizeService} btWindowsSizeService - ?
   * @param {ecapp.IPushNotificationService} btPushNotificationService - ?
   * @param {ecapp.ITradingService} btTradingService
   * @param {ecapp.ISettingsService} btSettingsService
   * @param {ecapp.IToastrService} btToastrService
   * @param {ecapp.IMarketing} btMarketing
   * @param {ecapp.IRestrictionService} btRestrictionService
   * @param {ecapp.IEventsService} btEventsService
   * @param {btUtils.btErrorService} $btError
   * @param {ecapp.IInstrumentsService} btInstrumentsService
   */
  function btTutorialController(
    $scope,
    $timeout,
    $interval,
    $state,
    $rootScope,
    $q,
    $location,
    $ionicLoading,
    $ionicSlideBoxDelegate,
    $ionicScrollDelegate,
    $analytics,
    btShareScopeService,
    btCurrenciesService,
    btWindowsSizeService,
    btPushNotificationService,
    btTradingService,
    btSettingsService,
    btToastrService,
    btMarketing,
    btRestrictionService,
    btEventsService,
    $btError,
    btInstrumentsService
  ) {
    console.log('Running btTutorialController');

    $location.search({ step: 'welcome' });

    // var stages = [
    //   {id: 'welcome'},
    //   {id: 'currencies'},
    //   {id: 'economic-events'},
    //   {id: 'markets'},
    //   {id: 'notifications'},
    //   {id: 'account'},
    //   {id: 'trading'},
    //   {id: 'finish'}
    // ];

    var slides = ['welcome', 'currencies', 'economic-events', 'markets', 'notifications', 'account'];

    // $scope.showFirstPage = true;
    // $scope.showSlider = false;
    // $scope.showOptions1 = false;
    // $scope.showOptions2 = false;

    var MAX_ATTEMPT = 5;
    var gCurAttempt = 0;

    var TITLE_TRADING = 'Trading Practice Account';
    var TITLE_ISSUE = 'Tutorial Issue';
    var TRY_LATE = 'Try to finish tutorial late.';
    var TRADING_COUNTRY = 'Singapore';
    var gLaunchTimeout;

    $scope.selectEvent = selectEvent;
    $scope.setEventsGroup = setOrder;
    $scope.updateWatchlist = updateWatchlist;
    // $scope.hideFirstPageShowTutorial = hideFirstPageShowTutorial;
    $scope.onSlideChanged = onSlideChanged;
    $scope.clickBackButton = clickBackButton;
    $scope.clickNextButton = clickNextButton;
    $scope.onFullNameChange = onFullNameChange;
    // $scope.endTutorial = finishTutorial;
    $scope.refresh = refresh;
    $scope.changePushNotification = changePushNotification;
    $scope.askQuestion = askQuestion;

    activate();

    /**
     *
     */
    function refresh() {
      activate();
    }

    /**
     *
     */
    function activate() {
      $scope.settings = {
        pushNotification: true,
      };

      $scope.hasUserName = true;

      $scope.isBigBrainBank = btSettingsService.getDomain() === 'bigbrainbank';

      $scope.isDemoStage = false;
      $scope.isAccountStage = false;
      $scope.isFirstStage = true;
      $scope.isFinalStage = false;
      $scope.isCongratulationsStage = false;
      $scope.suggestedVersion = window.isApp ? 'Web' : 'Mobile';
      $scope.secondsToLaunch = 20;

      $scope.nextClass = 'bt-start icon-right ion-chevron-right';
      $scope.backClass = 'bt-hide icon-left';
      $scope.nextText = 'Start';
      $scope.backText = ' - ';

      $scope.hasPersonalization = btRestrictionService.hasFeature('personalization');
      $scope.hasTutorial = $scope.hasPersonalization;

      // Disable practice account.
      $scope.hasFullTutorial = false;
      // $scope.hasFullTutorial = btSettingsService.domain === 'bettertrader';

      $scope.currencyFlags = [];

      $scope.defaultEvents = { commodities: [], usd: [], eur: [], gbp: [], cad: [], jpy: [] };
      $scope.watchlist = [];

      $scope.eventSelectedGroup = $scope.defaultEvents['usd'];
      $scope.eventSelectedGroupHeader = { field: 'usd', desc: 'desc' };

      $scope.user = btShareScopeService.getAccountSettings();

      $scope.demoAccount = {
        name: 'BTrader_demo1',
        pass: generatePassword(),
        email: $rootScope.currentUser.email,
      };

      // (Optional) Load currency flags (it used on events page)
      btCurrenciesService
        .getAllCachedCurrenciesFlags()
        // .then(function (value) {
        //   throw new Error('Test');
        // })
        .then(function (data) {
          $scope.currencyFlags = data.allCachedCurrenciesFlags;
        })
        .catch($btError.print);

      // (Mandatory) Load event information
      var promise1 = btEventsService
        .getAllEvents()
        // .then(function (value) {
        //   throw new Error('Test');
        // })
        .then(function () {
          Object.keys($scope.defaultEvents).forEach(function (key) {
            DEFAULT_EVENTS[key].forEach(function (value, i) {
              btEventsService.getEventById(value.eventId).then(function (event) {
                $scope.defaultEvents[key][i] = event;
              });
            });
          });
        })
        .catch(function (reason) {
          $btError.reportAppError(reason, gModule, $btError.ErrorCode.noEvents, $btError.ErrorLevel.FATAL);
          // $scope.errorMessage = $btError.ErrorMessage.SOMETHING_WRONG;
          // $scope.errorCode = $btError.ErrorCode.noEvents;
        });

      // (Mandatory) Load event information
      var promise2 = btInstrumentsService
        .init()
        // .then(function (value) {
        //   throw new Error('Test');
        // })
        .then(function () {
          DEFAULT_WATCHLIST.forEach(function (value) {
            $scope.watchlist.push({
              // instrument: btInstrumentsService.getInstrumentByComplexSymbol(value.symbol + ':OANDA'),
              instrument: btInstrumentsService.getInstrumentSmart(value.symbol),
              selected: value.isDefault,
            });
          });
        });

      // (Mandatory) Initialize user account
      var promise3 = btShareScopeService
        .wait()
        // .then(function (value) {
        //   throw new Error('Test');
        // })
        .then(function () {
          return btTradingService
            .getUsername($rootScope.currentUser.email)
            .then(function (name) {
              $scope.demoAccount.name = name;
            })
            .catch($btError.skip);
        })
        .then(function () {
          $scope.user = btShareScopeService.getAccountSettings();
          btShareScopeService.increaseVisitPage('tutorial').catch($btError.skip);
        })
        .catch(function (reason) {
          $btError.reportAppError(reason, gModule, $btError.ErrorCode.noUser, $btError.ErrorLevel.FATAL);
          // $scope.errorMessage = $btError.ErrorMessage.SOMETHING_WRONG;
          // $scope.errorCode = $btError.ErrorCode.noEvents;
        });

      $q.all([promise1, promise2, promise3]).then(function () {
        // Skip tutorial if user don't have a personalization feature
        if (!$scope.hasTutorial) return finishTutorial(false);
      });
    }

    /**
     *
     */
    function askQuestion() {
      if (window.Intercom) window.Intercom('show');
    }

    /**
     *
     * @param {*} name
     * @param {*} position
     */
    function changePushNotification(name, position) {
      // console.log('TEST:', name, position);
    }

    /**
     *
     * @param {*} item
     */
    function updateWatchlist(item) {
      // console.log('TEST:', item);
    }

    /**
     *
     * @param {*} event
     */
    function selectEvent(event) {
      // console.log('TEST: broadcast tutorial:toggle-event', event.id, ' - ', $scope.$id);
      $scope.$broadcast('tutorial:toggle-event', event.id);
    }

    /**
     * Generate password
     * @return {number} - random numbers
     */
    function generatePassword() {
      return Math.floor(Math.random() * 9999999) + 20000000;
    }

    /**
     * Set order (set events group)
     * @param {string} field - field name
     * @param {string} desc - some description
     * @return {{field: string, desc: string}}
     */
    function setOrder(field, desc) {
      $ionicScrollDelegate.scrollTop();
      $scope.eventSelectedGroup = $scope.defaultEvents[field];
      return {
        field: field,
        desc: desc,
      };
    }

    /**
     * React on slider change
     * @param {number} index
     */
    function onSlideChanged(index) {
      var i = $ionicSlideBoxDelegate.currentIndex();
      var n = $ionicSlideBoxDelegate.slidesCount();

      updateSlideLocation(i);
      updateControls('slides', i, n);
    }

    /**
     * This function processes on next button click.
     */
    function clickNextButton() {
      if ($scope.isCongratulationsStage) {
        // Launch application immediately
        forceApplicationLaunch();
      } else if ($scope.isDemoStage) {
        // Finish trading stage
        finishTradingStage();
      } else if ($scope.isAccountStage) {
        // Finish account stage
        finishAccountStage();
      } else {
        // Show next slide
        $ionicSlideBoxDelegate.next();
        var i = $ionicSlideBoxDelegate.currentIndex();
        var n = $ionicSlideBoxDelegate.slidesCount();

        updateControls('slides', i, n);
      }
    }

    /**
     * This function processes on back button click.
     */
    function clickBackButton() {
      if ($scope.isDemoStage) {
        finishTutorial(false);
      } else {
        // Show back slide
        $ionicSlideBoxDelegate.previous();
        var i = $ionicSlideBoxDelegate.currentIndex();
        var n = $ionicSlideBoxDelegate.slidesCount();

        updateControls('slides', i, n);
      }
    }

    /**
     * This function updates controls.
     *
     * @param {string} stage - stage name
     * @param {number} [i] - slider index
     * @param {number} [n] - slider count
     */
    function updateControls(stage, i, n) {
      if (i === undefined) i = 2;
      if (n === undefined) n = i + 2;

      if (stage === 'congratulations') {
        $scope.isAccountStage = false;
        $scope.isFirstStage = false;
        $scope.isFinalStage = true;
        $scope.nextClass = 'icon-right bt-done';
        $scope.backClass = 'icon-left bt-hide';
        $scope.nextText = 'Open the App';
        $scope.backText = ' - ';
      } else if (stage === 'trading') {
        $scope.isAccountStage = false;
        $scope.isFirstStage = false;
        $scope.isFinalStage = true;
        $scope.nextClass = 'icon-right';
        $scope.backClass = 'icon-left bt-skip';
        $scope.nextText = 'Finish';
        $scope.backText = 'Skip';
      } else if (i === 0) {
        $scope.isAccountStage = false;
        $scope.isFirstStage = true;
        $scope.isFinalStage = false;
        $scope.nextClass = 'icon-right ion-chevron-right bt-start';
        $scope.backClass = 'icon-left bt-hide';
        $scope.nextText = 'Start';
        $scope.backText = ' - ';
      } else if (i === n - 1) {
        $scope.isAccountStage = true;
        $scope.isFirstStage = false;
        $scope.isFinalStage = !$scope.hasFullTutorial;
        $scope.nextClass = 'icon-right' + ($scope.hasFullTutorial ? ' ion-chevron-right' : '');
        $scope.backClass = 'icon-left ion-chevron-left';
        $scope.nextText = $scope.hasFullTutorial ? 'Next' : 'Finish';
        $scope.backText = 'Back';
      } else {
        $scope.isAccountStage = false;
        $scope.isFirstStage = false;
        $scope.isFinalStage = false;
        $scope.nextClass = 'icon-right ion-chevron-right bt-next';
        $scope.backClass = 'icon-left ion-chevron-left bt-back';
        $scope.nextText = 'Next';
        $scope.backText = 'Back';
      }
    }

    /**
     * This function updates the location when changing slides.
     * @param {number} i - slide index
     */
    function updateSlideLocation(i) {
      $location.search({ step: slides[i] });
    }

    /**
     * This function reacts on full name change.
     */
    function onFullNameChange() {
      $scope.hasUserName = !!$scope.user.firstName;
    }

    /**
     * This function finishes account stage.
     */
    function finishAccountStage() {
      if ($scope.user.firstName) {
        if ($scope.hasFullTutorial) {
          $scope.isDemoStage = true;
          $location.search({ step: 'trading' });
          updateControls('trading');
        } else {
          finishTutorial(false);
        }
      } else {
        $scope.hasUserName = false;
      }
    }

    /**
     * This function finishes trading stage.
     */
    function finishTradingStage() {
      finishTutorial(true);
    }

    /**
     * This function finishes the tutorial.
     *
     * @param {boolean} hasTrading - whether a trading account should be created
     */
    function finishTutorial(hasTrading) {
      $ionicLoading.show({ template: '<ion-spinner icon="ios"></ion-spinner><p>Update User Data...</p>' });

      btMarketing.updateClientData({ name: $scope.user.firstName });

      var nameArray = btShareScopeService.getFirstLastNames($scope.user.firstName);

      try {
        if ($scope.hasPersonalization) updateMailChimp(nameArray);
        setAnalytics(nameArray, $scope.user.companyName);
      } catch (err) {
        console.error(err);
      }

      // FIXME We should use some API.
      $scope.user.watchList.default = $scope.watchlist
        .filter(function (value) {
          return value.selected;
        })
        .map(function (value) {
          return value.instrument.OandaSymbol;
        });

      if (gDebug) console.log(gPrefix, 'finishing tutorial...');
      updateUserAccount($scope.user.firstName, $scope.user.companyName)
        .catch(handleUserError)
        .then(function (value) {
          return hasTrading ? createTradingAccount() : $q.resolve(value);
        })
        .then(grantPushNotification)
        .then(function () {
          return launchApplication(!$scope.hasFullTutorial);
        })
        .catch(function (reason) {
          $btError.reportAppError(reason, gModule, $btError.ErrorCode.tutorialNotFinished, $btError.ErrorLevel.FATAL);
          return $q.reject(reason);
        })
        .then(function () {
          if (gDebug) console.log(gPrefix, 'tutorial finished');
        })
        .finally(function () {
          $ionicLoading.hide();
        });
    }

    /**
     * This function asks user to grant push notification.
     *
     * @return {any}
     */
    function grantPushNotification() {
      if ($scope.settings.pushNotification) {
        btPushNotificationService.allow();
      } else {
        btPushNotificationService.delay();
      }
      return $q.resolve();
    }

    /**
     * This function force application launching.
     */
    function forceApplicationLaunch() {
      if (gLaunchTimeout) $timeout.cancel(gLaunchTimeout);

      if (gDebug) console.log(gPrefix, 'launching application');
      $state.go('ecapp.app.main.reviews').then(function (value) {
        if (gDebug) console.log(gPrefix, 'application launched');
        return value;
      });
    }

    /**
     * This function handles errors during updating of user account.
     *
     * @param {Error} error - error object
     * @return {angular.IPromise}
     */
    function handleUserError(error) {
      if (gDebug) console.log(gPrefix, 'user error');

      if (error.message) {
        btToastrService.error(error.message + ' ' + TRY_LATE, TITLE_ISSUE, { type: 'trade' });
      } else if (error.data && error.data.error && error.data.error.message) {
        btToastrService.error(error.data.error.message + ' ' + TRY_LATE, TITLE_ISSUE, { type: 'trade' });
      } else {
        btToastrService.error('Error: Unknown error.' + ' ' + TRY_LATE, TITLE_ISSUE, { type: 'trade' });
      }

      gCurAttempt++;

      if (gCurAttempt > MAX_ATTEMPT) {
        //???
        $state.go('ecapp.user.error', { error: error.code });
      }

      return $q.reject(error);
    }

    /**
     * This function launches application.
     *
     * @param {boolean} skipCongratulations - whether congratulations should be skipped
     * @return {angular.IPromise<void>}
     */
    function launchApplication(skipCongratulations) {
      btWindowsSizeService.setSize();
      if (skipCongratulations) {
        $rootScope.isTutorialPassed = true;
        $state.go('ecapp.app.main.reviews');
      } else {
        $rootScope.isTutorialPassed = true;
        $scope.isDemoStage = false;
        $scope.isCongratulationsStage = true;
        updateControls('congratulations');

        $interval(countDown, 1000, 10);

        gLaunchTimeout = $timeout(function () {
          if (gDebug) console.log(gPrefix, 'launching application');

          $state.go('ecapp.app.main.reviews').then(function (value) {
            if (gDebug) console.log(gPrefix, 'application launched');
            return value;
          });
        }, $scope.secondsToLaunch * 1000);
      }

      return $q.resolve();
    }

    /**
     * This function ???.
     */
    function countDown() {
      // console.log('TEST:', '!');
      $scope.secondsToLaunch--;
    }

    /**
     * Update mailchimp settings
     * @param {string[]} nameArray - first and last names
     * @return {*}
     */
    function updateMailChimp(nameArray) {
      btShareScopeService.setMailChimpField('FNAME', nameArray[0]).catch(function (reason) {
        console.error(reason);
      });

      btShareScopeService.setMailChimpField('LNAME', nameArray[1]).catch(function (reason) {
        console.error(reason);
      });

      return $q.resolve();
    }

    /**
     * Set analytics
     * @param {string[]} nameArray - first and last names
     * @param {string} companyName - company name
     * @return {*}
     */
    function setAnalytics(nameArray, companyName) {
      $analytics.setUserProperties({
        $first_name: nameArray[0] || 'Joe',
        $last_name: nameArray[1] || 'Doe',
        company: companyName,
      });

      return $q.resolve();
    }

    /**
     * This function updates user account.
     *
     * @param {string} name - user name
     * @param {string} companyName - company name
     * @return {angular.IPromise<*>}
     */
    function updateUserAccount(name, companyName) {
      if (gDebug) console.log(gPrefix, 'updating user data');

      btShareScopeService.setTutorialStatusToTrue();
      btShareScopeService.setAccountInfoField('firstName', name);
      btShareScopeService.setAccountInitials(name);
      btShareScopeService.setAccountCompany(companyName);

      var fields = ['firstName', 'companyName', 'phone', 'country', 'tutorialWasFinished', 'watchList'];

      // return $q.reject(new Error('Test.'));
      return btShareScopeService.updateProfileSettingsFromFieldsList(fields).then(function () {
        if (gDebug) console.log(gPrefix, 'user data updated');

        // Store information about the tutorial completion
        if ($scope.hasPersonalization)
          return btShareScopeService.increaseVisitPage('end-tutorial').catch($btError.skip);
      });
    }

    /**
     * This function creates trading practice account.
     *
     * @return {angular.IPromise<*>}
     */
    function createTradingAccount() {
      btToastrService.info('Creating trading practice account...', TITLE_TRADING, { type: 'trade' });

      var userData = {
        username: $scope.demoAccount.name,
        password: $scope.demoAccount.pass,
        email: $scope.demoAccount.email,
        country: TRADING_COUNTRY,
      };

      // test username
      // if username is not available

      if (gDebug) console.log(gPrefix, 'creating demo account');

      return (
        btTradingService
          .checkUser(userData)
          .then(btTradingService.signUp)
          .catch(function (reason) {
            if (reason.status && reason.status === 500) {
              var parts = userData.email.split('@');
              userData.email = parts[0] + '+bt' + '@' + parts[1];
              return btTradingService.checkUser(userData).then(btTradingService.signUp);
            } else {
              return $q.reject(reason);
            }
          })
          .then(function (value) {
            if (gDebug) console.log(gPrefix, 'demo account created');
            btToastrService.success(
              'Done! Your demo trading practice account credentials will be sent to your email shortly.',
              TITLE_TRADING,
              { type: 'trade' }
            );
            return value;
          })
          .then(connectTradingAccount)
          .then(function (value) {
            if (gDebug) console.log(gPrefix, 'demo account connected');
            return value;
          })
          // return $q.reject(new Error('Test'))
          .catch(handleTradingAccountError)
      );
    }

    /**
     * This function connects trading practice account.
     *
     * @param {object} data - trading account data
     * @return {*}
     */
    function connectTradingAccount(data) {
      if (gDebug) console.log(gPrefix, 'connecting demo account');
      if (data) {
        return btTradingService.fastLogin('oanda', 'demo', data).then(function () {
          btToastrService.success('Trading practice account has been connected to the application!', TITLE_TRADING, {
            type: 'trade',
          });
        });
      } else {
        return $q.reject(new Error("Can't connect trading practice account. Try again late."));
      }
    }

    /**
     * This function handles errors during creation and connection of trading account.
     *
     * @param {Error|object} error - error object
     */
    function handleTradingAccountError(error) {
      if (gDebug) console.log(gPrefix, 'demo account error');

      if (error.message) {
        if (error.message === 'Email address is already in use') {
          btToastrService.info(
            'It looks like you already have OANDA trading practice account. You can connect it on broker page.',
            TITLE_TRADING,
            { type: 'trade' }
          );
        } else {
          btToastrService.error('Error: ' + error.message, TITLE_TRADING, { type: 'trade' });
        }
      } else if (error.data && error.data.error && error.data.error.message) {
        btToastrService.error('Error: ' + error.data.error.message, TITLE_TRADING, { type: 'trade' });
      } else {
        btToastrService.error(
          'Error: ' + (error.message || 'Trading practice account creation failed.'),
          TITLE_TRADING,
          { type: 'trade' }
        );
      }
    }
  }

  var DEFAULT_WATCHLIST = [
    { symbol: 'EUR_USD', isDefault: true },
    { symbol: 'USD_CAD', isDefault: true },
    { symbol: 'GBP_USD', isDefault: true },
    { symbol: 'USD_JPY', isDefault: true },
    { symbol: 'AUD_USD', isDefault: true },
    { symbol: 'XAU_USD', isDefault: true },
    { symbol: 'WTICO_USD', isDefault: true },
    { symbol: 'BCO_USD', isDefault: true },
    { symbol: 'NATGAS_USD', isDefault: true },
    { symbol: 'SPX500_USD', isDefault: true },
    { symbol: 'NAS100_USD', isDefault: true },
    { symbol: 'JP225_USD', isDefault: true },
    { symbol: 'USB10Y_USD', isDefault: true },
  ];

  var DEFAULT_EVENTS = {
    commodities: [
      { eventId: 386, isDefault: true },
      { eventId: 75, isDefault: true },
      { eventId: 656, isDefault: false },
      { eventId: 1652, isDefault: true },
      { eventId: 1653, isDefault: false },
      { eventId: 1618, isDefault: false },
      { eventId: 230, isDefault: false },
      { eventId: 1674, isDefault: false },
    ],
    usd: [
      { eventId: 1, isDefault: true },
      { eventId: 222, isDefault: true },
      { eventId: 300, isDefault: true },
      { eventId: 1062, isDefault: true },
      { eventId: 320, isDefault: true },
      { eventId: 294, isDefault: true },
      { eventId: 375, isDefault: true },
      { eventId: 99, isDefault: true },
      { eventId: 168, isDefault: true },
      { eventId: 25, isDefault: false },
      { eventId: 236, isDefault: false },
      { eventId: 256, isDefault: false },
      { eventId: 571, isDefault: false },
      { eventId: 176, isDefault: false },
      { eventId: 1492, isDefault: false },
      { eventId: 227, isDefault: false },
    ],
    eur: [
      { eventId: 140, isDefault: true },
      { eventId: 136, isDefault: true },
      { eventId: 359, isDefault: true },
      { eventId: 128, isDefault: true },
      { eventId: 332, isDefault: true },
      { eventId: 132, isDefault: true },
      { eventId: 68, isDefault: true },
      { eventId: 565, isDefault: true },
      { eventId: 272, isDefault: true },
      { eventId: 201, isDefault: true },
      { eventId: 160, isDefault: true },
      { eventId: 310, isDefault: false },
      { eventId: 164, isDefault: false },
      { eventId: 120, isDefault: false },
      { eventId: 255, isDefault: false },
      { eventId: 1032, isDefault: false },
      { eventId: 144, isDefault: false },
      { eventId: 138, isDefault: false },
    ],
    gbp: [
      { eventId: 121, isDefault: true },
      { eventId: 170, isDefault: true },
      { eventId: 258, isDefault: true },
      { eventId: 297, isDefault: true },
      { eventId: 1034, isDefault: false },
      { eventId: 1649, isDefault: true },
      { eventId: 205, isDefault: true },
      { eventId: 274, isDefault: true },
      { eventId: 44, isDefault: true },
      { eventId: 204, isDefault: true },
    ],
    cad: [
      { eventId: 123, isDefault: true },
      { eventId: 1033, isDefault: false },
      { eventId: 57, isDefault: true },
      { eventId: 65, isDefault: true },
      { eventId: 95, isDefault: true },
      { eventId: 301, isDefault: true },
      { eventId: 150, isDefault: true },
      { eventId: 24, isDefault: true },
      { eventId: 166, isDefault: true },
      { eventId: 189, isDefault: true },
    ],
    jpy: [
      { eventId: 261, isDefault: true },
      { eventId: 159, isDefault: true },
      { eventId: 344, isDefault: true },
      { eventId: 1191, isDefault: false },
      { eventId: 834, isDefault: false },
      { eventId: 210, isDefault: true },
      { eventId: 370, isDefault: true },
      { eventId: 165, isDefault: true },
      { eventId: 1614, isDefault: true },
      { eventId: 119, isDefault: true },
      { eventId: 725, isDefault: false },
      { eventId: 32, isDefault: false },
    ],
  };
})();
