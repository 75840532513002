/**
 * Created by David on 11/03/2016.
 */

// @ts-check
(function btInstrumentsServiceClosure() {
  'use strict';

  var gDebug = false;
  var MODULE = 'btInstrumentsService';
  var gPrefix = 'btInstrumentsService:';

  angular.module('ecapp').factory('btInstrumentsService', btInstrumentsService);

  btInstrumentsService.$inject = [
    'Instruments',
    'MarketCharacteristic',
    '$q',
    'CacheFactory',
    'btDateService',
    'btErrorService',
    'btSymbolPrefix',
  ];

  /**
   * This service wraps `Instrument` class that is used in client to works with market instruments.
   *
   * We use Instrument to store instrument prices and description.
   *
   * Currently we have some issues with instrument identification. We have next identifiers:
   *
   *  | Type of identifier               | Example #1      | Example #2      |
   *  |:---------------------------------|:----------------|:----------------|
   *  | Internal BetterTrader identifier | Silver_AUD      | EURUSD          |
   *  | Internal BetterTrader name       | Silver/AUD      | EURUSD          |
   *  | Internal BetterTrader key        | SILVER_AUD      | EURUSD          |
   *  | Oanda identifier                 | XAG_AUD         | EUR_USD         |
   *  | HistData identifier              | N/A             | EURUSD          |
   *  | Complex identifier               | XAG_AUD:OANDA   | EUR_USD:OANDA   |
   *  | Ticker                           | OANDA:XAG_AUD   | OANDA:EUR_USD   |
   *  | Broker Symbol (TradeStation)     | SIH2021         | ECH2021         |
   *  | Broker Symbol (Tradier)          | SLV             | URR             |
   *
   * We store information about some instruments in database. Previous it was enough, but now we want to support stocks
   * so we can not store all instruments in database. Some we need to generate some instrument dynamically using server
   * response. Local cache is used to optimize performance.
   *
   * Features connected to instrument:
   *  - Watchlist
   *  - Economic Event Related Instruments
   *  - BackTesting
   *  - News-Driven Trade Ideas
   *  - Price-Driven Trade Ideas
   *  - Support & Resistance Levels
   *  - Market Sense & Market Wakeup
   *  - Realtime Price Updates
   *  - Historical Charts
   *  - Risk Monitor
   *
   * Instrument properties:
   *  - Name, Description, Pronunciation
   *  - Aliases
   *  - Market Characteristics
   *  - Prices
   *  - Level Settings
   *  - Orders, Positions
   *
   * Currently we store in watchlist BetterTrader names of instruments. For backward compatibility we should store
   * BetterTrader names for Oanda instruments and ticker for new instruments.
   *
   * Watchlist: [EUR_USD, S:AAPL]
   *
   *
   *
   * @ngdoc service
   * @name btInstrumentsService
   * @memberOf ecapp
   * @param {ecapp.IGeneralLoopbackService} lbInstruments
   * @param {ecapp.IGeneralLoopbackService} lbMarketCharacteristic
   * @param {angular.IQService} $q - promise interface
   * @param {ext.ICacheFactoryService} CacheFactory
   * @param {ecapp.IDateService} btDateService
   * @param {ecapp.IErrorService} $btError -
   * @param {ecapp.ISymbolPrefix} btSymbolPrefix -
   * @return {ecapp.IInstrumentsService}
   */
  function btInstrumentsService(
    lbInstruments,
    lbMarketCharacteristic,
    $q,
    CacheFactory,
    btDateService,
    $btError,
    btSymbolPrefix
  ) {
    if (gDebug) console.log(gPrefix, 'Running...');

    /**
     * In-memory storage for instruments.
     * @type {ecapp.ITradingInstrument[]}
     */
    var gInstrumentsList = null;

    /**
     * Service initialization promise.
     * @type {angular.IPromise<void>}
     */
    var gInitializationPromise = null;

    /**
     * List of OANDA instruments. It is used to store market characteristics.
     * @type {Record<string, ecapp.ITradingInstrument>}
     */
    var gOandaInstruments = {};

    /**
     * In-memory storage of custom broker instruments.
     * @type {Record<string, ecapp.ITradingInstrument>}
     */
    var gCustomInstrument = {};
    window.btStore.customInstrument = gCustomInstrument;

    /**
     * List of fields to load from database.
     * @type {Record<string, boolean>}
     */
    var gInstrumentFields = {
      id: true,
      pip: true,
      histData: true,
      displayName: true,
      pronunciation: true,
      OandaSymbol: true,
      type: true,
      btName: true,
      btSymbol: true,
      description: true,
      tick: true,
      precision: true,
      // 'Country1': true, 'Country2': true, 'TVSymbol': true,
    };

    /**
     * Local cache of instruments.
     * @type {ext.ICacheObject}
     */
    var gInstrumentsCache = null;

    /**
     * Month-symbol table
     * @type {Record<string, number>}
     */
    var gSymbolToMonth = {
      F: 0, // January
      G: 1, // February
      H: 2, // March
      J: 3, // April
      K: 4, // May
      M: 5, // June
      N: 6, // July
      Q: 7, // August
      U: 8, // September
      V: 9, // October
      X: 10, // November
      Z: 11, // December
    };

    /**
     * Symbol-month table
     * @type {Record<number, string>}
     */
    var gMonthToSymbol = {
      0: 'F',
      1: 'G',
      2: 'H',
      3: 'J',
      4: 'K',
      5: 'M',
      6: 'N',
      7: 'Q',
      8: 'U',
      9: 'V',
      10: 'X',
      11: 'Z',
    };

    // Number of seconds in two weeks
    var BT_TWO_WEEKS = 2 * 7 * 24 * 60 * 60 * 1000;

    /**
     * Table TradeStation instrument aliases
     * @type {Record<string, string>}
     */
    var gTradeStationSymbols = {
      EURUSD: 'EC' + getExpiration(['H', 'M', 'U', 'Z']),
      GBPUSD: 'BP' + getExpiration(['H', 'M', 'U', 'Z']),
      USDJPY: 'JY' + getExpiration(['H', 'M', 'U', 'Z']),
      USDCHF: 'SF' + getExpiration(['H', 'M', 'U', 'Z']),
      AUDUSD: 'AD' + getExpiration(['H', 'M', 'U', 'Z']),
      USDCAD: 'CD' + getExpiration(['H', 'M', 'U', 'Z']),
      USDCNH: 'CNH' + getExpiration(['H', 'M', 'U', 'Z']),

      'S&P500': 'ES' + getExpiration(['H', 'M', 'U', 'Z']),
      'Nasdaq 100': 'NQ' + getExpiration(['H', 'M', 'U', 'Z']),
      'Dow Jones': 'YM' + getExpiration(['H', 'M', 'U', 'Z']),
      'Nikkei 225': 'NK' + getExpiration(['H', 'M', 'U', 'Z']),
      DAX: 'FDAX' + getExpiration(['H', 'M', 'U', 'Z']),
      'Europe 50': 'FESX' + getExpiration(['H', 'M', 'U', 'Z']),

      'US 2Y T-Note': 'TU' + getExpiration(['H', 'M', 'U', 'Z']),
      'US 5Y T-Note': 'FV' + getExpiration(['H', 'M', 'U', 'Z']),
      US10Y: 'TY' + getExpiration(['H', 'M', 'U', 'Z']),
      'US T-Bond': 'US' + getExpiration(['H', 'M', 'U', 'Z']),
      'EURO BUND': 'FGBL' + getExpiration(['H', 'M', 'U', 'Z']),
      'UK GILT': 'LJ' + getExpiration(['H', 'M', 'U', 'Z']),

      Gold: 'GC' + getExpiration(['G', 'J', 'M', 'Q', 'V', 'Z']),
      Silver: 'SI' + getExpiration(['H', 'K', 'N', 'U', 'Z']),
      Copper: 'HG' + getExpiration(['H', 'K', 'N', 'U', 'Z']),
      'West Texas Oil': 'CL' + getExpiration(['F', 'G', 'H', 'J', 'K', 'M', 'N', 'Q', 'U', 'V', 'X', 'Z']),
      OIL: 'BRN' + getExpiration(['F', 'G', 'H', 'J', 'K', 'M', 'N', 'Q', 'U', 'V', 'X', 'Z']),
      'Natural Gas': 'NG' + getExpiration(['F', 'G', 'H', 'J', 'K', 'M', 'N', 'Q', 'U', 'V', 'X', 'Z']),
    };

    if (gDebug) {
      console.log(gPrefix, gTradeStationSymbols);
      console.log(gPrefix, 'Test getExpiration');

      testGetExpiration(new Date());
      testGetExpiration(new Date(Date.UTC(2018, 4, 18)));
      testGetExpiration(new Date(Date.UTC(2018, 4, 28)));
      testGetExpiration(new Date(Date.UTC(2018, 5, 28)));
      testGetExpiration(new Date(Date.UTC(2018, 11, 28)));
    }

    /**
     * Table of Tradier instrument aliases
     * @type {Record<string, string | undefined>}
     */
    var gTradierSymbols = {
      EURUSD: 'URR',
      GBPUSD: 'GBB',
      USDJPY: 'YCS',
      USDCHF: undefined,
      AUDUSD: undefined,
      USDCAD: undefined,
      USDCNH: undefined,

      'S&P500': 'SPY',
      'Nasdaq 100': 'QQQ',
      'Dow Jones': 'DIA',
      'Nikkei 225': 'EWJ',
      DAX: 'DAX',
      'Europe 50': 'FEU',

      'US 2Y T-Note': undefined,
      'US 5Y T-Note': undefined,
      US10Y: 'UST',
      'US T-Bond': undefined,
      'EURO BUND': undefined,
      'UK GILT': undefined,

      Gold: 'GLD',
      Silver: 'SLV',
      Copper: 'JJC',
      'West Texas Oil': 'USO',
      OIL: 'USO',
      'Natural Gas': 'UGAZ',
    };

    /**
     * Table of Tradier instrument aliases (reverse)
     * @type {Record<string, string>}
     */
    var gTradierSymbolsReversed = {
      URR: 'EURUSD',
      GBB: 'GBPUSD',
      YCS: 'USDJPY',

      SPY: 'S&P500',
      QQQ: 'Nasdaq 100',
      DIA: 'Dow Jones',
      EWJ: 'Nikkei 225',
      DAX: 'DAX',
      FEU: 'Europe 50',

      UST: 'US10Y',

      GLD: 'Gold',
      SLV: 'Silver',
      JJC: 'Copper',
      USO: 'West Texas Oil',
      UGAZ: 'Natural Gas',
    };

    /**
     * Table of cTrader instrument aliases
     * @type {Record<string, string | undefined>}
     */
    var gCTraderSymbols = {
      EURUSD: 'EURUSD',
      GBPUSD: 'GBPUSD',
      USDJPY: 'USDJPY',
      USDCHF: 'USDCHF',
      AUDUSD: 'AUDUSD',
      USDCAD: 'USDCAD',
      USDCNH: 'USDCAD',

      'S&P500': 'US 500', //!
      'Nasdaq 100': 'US TECH 100',
      'Dow Jones': 'US 30', //!
      'Nikkei 225': 'JAPAN 225', //!
      DAX: 'GERMANY 30', //!
      'Europe 50': 'EUROPE 50',

      'US 2Y T-Note': undefined,
      'US 5Y T-Note': undefined,
      US10Y: undefined,
      'US T-Bond': undefined,
      'EURO BUND': undefined,
      'UK GILT': undefined,

      Gold: 'XAUUSD',
      Silver: 'XAGUSD',
      Copper: undefined,
      'West Texas Oil': 'WTI', // !
      OIL: 'BRENT', // !
      'Natural Gas': 'NAT.GAS',
    };

    /**
     * Table of cTrader instrument aliases (reverse)
     * @type {Record<string, string>}
     */
    var gCTraderSymbolsReversed = {
      EURUSD: 'EURUSD',
      GBPUSD: 'GBPUSD',
      USDJPY: 'USDJPY',
      USDCHF: 'USDCHF',
      AUDUSD: 'AUDUSD',
      USDCAD: 'USDCAD',
      USDCNH: 'USDCAD',

      SP500: 'S&P500',
      NASDAQ: 'Nasdaq 100',
      DJIA: 'Dow Jones',
      NIY225: 'Nikkei 225',
      '#FDAX': 'DAX',
      'Europe 50': 'Europe 50',

      XAUUSD: 'Gold',
      XAGUSD: 'Silver',
      'XTI/USD': 'West Texas Oil',
      'XBR/USD': 'OIL',
      XNGUSD: 'Natural Gas',
    };

    /** @type {Record<string, string>} */
    var MonthText = {
      M1: 'M₁',
      M2: 'M₂',
      M3: 'M₃',
      M4: 'M₄',
      M5: 'M₅',
      M6: 'M₆',
      M7: 'M₇',
      M8: 'M₈',
      M9: 'M₉',
      M10: 'M₁₀',
      M11: 'M₁₁',
      M12: 'M₁₂',
    };

    var OLD_CRYPTO_SYMBOLS = ['BTC_USD'];

    activate();

    /**
     *
     *  | Static Fields  | Silver          | EUR/USD         | AAPL            |
     *  |:---------------|:----------------|:----------------|:----------------|
     *  | id             | Silver          | EURUSD          | TRADIER:AAPL    |
     *  | btName         | Silver          | EURUSD          | -               |
     *  | btSymbol       | OANDA:XAG_USD   | OANDA:EUR_USD   | TRADIER:AAPL    |
     *  | OandaSymbol    | XAG_USD         | EUR_USD         | -               |
     *
     * ### Default
     *
     *  | Dynamic Fields | Silver          | EUR/USD         | AAPL            |
     *  |:---------------|:----------------|:----------------|:----------------|
     *  | brokerName     | default         | default         | default         |
     *  | brokerSymbol   | XAG_USD         | EUR_USD         | AAPL            |
     *  | provider       | oanda           | oanda           | tradier         |
     *  | ticker         | FX:XAG_USD   | FX:EUR_USD   | ST:AAPL    |
     *
     * ### Oanda
     *
     *  | Dynamic Fields | Silver          | EUR/USD         | AAPL            |
     *  |:---------------|:----------------|:----------------|:----------------|
     *  | brokerName     | oanda           | oanda           | oanda           |
     *  | brokerSymbol   | XAG_USD         | EUR_USD         | UNKNOWN         |
     *  | provider       | oanda           | oanda           | oanda           |
     *  | ticker         | OANDA:XAG_USD   | OANDA:EUR_USD   | OANDA:UNKNOWN   |
     *
     * ### TradeStation
     *
     *  | Dynamic Fields | Silver          | EUR/USD         | AAPL            |
     *  |:---------------|:----------------|:----------------|:----------------|
     *  | brokerName     | tradestation    | tradestation    | tradestation    |
     *  | brokerSymbol   | GCZ2021         | TS:EURUSD       | UNKNOWN         |
     *  | provider       | tradestation    | tradestation    | tradestation    |
     *  | ticker         | TS:GCZ2021      | TS:EURUSD       | TS:UNKNOWN      |
     *
     * @see ecapp.ITradingInstrument
     * @class
     * @param {ecapp.ITradingInstrumentData} data - data
     */
    function Instrument(data) {
      var mandatory = ['id', 'type', 'displayName', 'pronunciation', 'description', 'pip'];

      mandatory.some(function (key) {
        if (data[key] === undefined) {
          console.error('Instrument (' + data.id + '): Property "' + key + '" is undefined.');
          return true;
        } else {
          return false;
        }
      });

      // var optional = ['ticker', 'OandaSymbol', 'Country1', 'Country2', 'TVSymbol', 'histData', 'brokerName', 'brokerSymbol', 'tick', 'precision'];

      this.missed = data.missed || false;
      this.id = Instrument.checkId(data.id);
      this.type = data.type;
      this.displayName = data.displayName;
      this.pronunciation = data.pronunciation;
      this.description = data.description;

      this.pip = data.pip;
      this.tick = data.tick;
      this.precision = data.precision;
      this.unit = null;

      this.btName = data.btName || 'N/A';
      // this.histData = data.histData || '';
      this.OandaSymbol = data.OandaSymbol || '';
      // this.TVSymbol = data.TVSymbol || '';
      // this.Country1 = data.Country1 || '';
      // this.Country2 = data.Country2 || '';

      this.btSymbol = data.btSymbol || (data.OandaSymbol ? btSymbolPrefix.FOREX + ':' + data.OandaSymbol : '');
      this.brokerName = data.brokerName || 'default';
      this.brokerSymbol = data.brokerSymbol || data.OandaSymbol || '';
      this.complexSymbol = this.getComplexName();

      this.provider = data.provider || (this.brokerName === 'default' ? 'oanda' : this.brokerName);
      this.ticker = data.ticker || this.createTicker();

      this.hasDifference = ['ctrader'].indexOf(this.provider) === -1;
      this.hasDailyRange = ['ctrader', 'lds'].indexOf(this.provider) === -1;
      this.hasPositions = false;
      this.hasMarketSense = false;
      this.hasMarketWakeup = false;

      this.characteristics = null;
      this.levelVoice = 'none';
      this.numOrders = 0;
      this.ordersData = [];
      this.positionData = null;
      this.price = null;

      this.month = this.provider === 'lds' ? 'M1' : null;
      this.raw = data.raw;

      this.history = [];
    }

    Instrument.$identifiers = {};

    Instrument.checkId = function (id) {
      id = id.toUpperCase().replace(/:/g, '|');
      if (Instrument.$identifiers[id]) {
        Instrument.$identifiers[id]++;
        if (gDebug) console.error('>>> Duplicate instrument identifier:', id);
        return id + 'x' + Instrument.$identifiers[id];
      } else {
        Instrument.$identifiers[id] = 0;
        return id;
      }
    };

    /**
     *
     * @return {string}
     */
    Instrument.prototype.getDisplayName = function () {
      if (this.provider === 'lds') {
        return this.displayName + ' (' + MonthText[this.month] + ')';
      } else {
        return this.displayName;
      }
    };

    Instrument.prototype.getComplexName = function () {
      let name;
      if (this.OandaSymbol) {
        name = this.OandaSymbol.toUpperCase() + ':OANDA';
      } else if (this.brokerSymbol) {
        name = this.brokerSymbol.toUpperCase().replace(':', '|') + ':' + this.brokerName.toUpperCase();
      } else {
        name = this.id + ':' + this.brokerName.toUpperCase();
      }
      return name;
    };
    /**
     *
     * @return {string}
     */
    Instrument.prototype.getSymbol = function () {
      if (this.provider === 'lds') {
        return this.brokerSymbol + ':' + this.month;
      } else {
        return this.brokerSymbol;
      }
    };

    /**
     * Update broker symbol.
     *
     * @param {string} brokerName - broker name
     */
    Instrument.prototype.setBroker = function (brokerName) {
      this.brokerName = brokerName;
      this.brokerSymbol = getBrokerSymbol(this, brokerName);

      if (this.brokerName === 'default') {
        this.provider = 'bettertrader';
        this.ticker = this.brokerSymbol;
      } else {
        this.provider = brokerName;
        this.ticker = this.createTicker();
      }

      this.complexSymbol = this.getComplexName();

      this.hasDifference = ['ctrader'].indexOf(this.provider) === -1;
      this.hasDailyRange = ['ctrader', 'lds'].indexOf(this.provider) === -1;

      this.hasMarketSense = !!this.OandaSymbol;
      this.hasMarketWakeup = !!this.OandaSymbol;

      if (gDebug) console.log('>>>', this.brokerName, this.brokerSymbol, this.provider, this.ticker);
    };

    /**
     *
     * @return {string}
     */
    Instrument.prototype.createTicker = function () {
      return this.provider.toUpperCase() + ':' + this.brokerSymbol.toUpperCase();
    };

    return {
      init: init,
      // getInstrumentsList: getInstrumentsList,
      cache: gInstrumentsCache,

      getInstrumentByTicker: getInstrumentByTicker,
      getInstrumentSmart: getInstrumentSmart,
      getInstrumentBySomeSymbol: getInstrumentBySomeSymbol,
      getInstrumentByField: getInstrumentByField,
      getInstrumentByComplexSymbol: getInstrumentByComplexSymbol,
      hasOandaSymbol: hasOandaSymbol,
      // convertMarketName: convertMarketName,
      convertBrokerName: convertBrokerName,
      convertBTName: convertBTName,
      convertComplexName2BTName: convertComplexName2BTName,
      // createComplexSymbol: createComplexSymbol,
      addBrokerSymbols: addBrokerSymbols,
      getSymbol: getSymbol,
      getBroker: getBroker,
      getPronunciation: getPronunciation,
      getTradeStationSymbols: getTradeStationSymbols,
      getPrecision: getPrecision,
      getPriceUnitValue: getPriceUnitValue,
      getPriceUnitName: getPriceUnitName,
      createBrokerInstrument: createBrokerInstrument,
      createFromUDFSymbol: createFromUDFSymbol,
      addInstrument: addInstrument,
      isTicker: isTicker,
      upgradeSymbol: upgradeSymbol,
      downgradeSymbol: downgradeSymbol,
    };

    /**
     * Activates service
     */
    function activate() {
      setupCache();
    }

    /**
     * This function returns list of all instruments (cached them before).
     *
     * @return {angular.IPromise<*>}
     */
    function getInstrumentsList() {
      var deferred = $q.defer();

      if (gInstrumentsList === null) {
        if (gDebug) console.log(gPrefix, 'Initializing instruments...');

        var cachedInstruments = gInstrumentsCache.get('list');
        if (cachedInstruments === undefined) {
          if (gDebug) console.log(gPrefix, 'Loading instruments from database...');

          loadInstruments()
            .then(function (instruments) {
              deferred.resolve({ instrumentsList: instruments });
            })
            .catch($btError.handleHTTPError)
            .catch(function (reason) {
              console.error(gPrefix, reason);
              deferred.reject(reason);
            });
        } else {
          if (gDebug) console.log(gPrefix, 'Use instruments from local cache.');

          gInstrumentsList = cachedInstruments.map(function (value) {
            return new Instrument(value);
          });

          window.btStore.instruments = gInstrumentsList;

          if (gDebug) console.log(gPrefix, 'gInstrumentsList', gInstrumentsList);

          fillOandaInstruments(gOandaInstruments, gInstrumentsList);
          addBrokerSymbols('default');
          deferred.resolve({ instrumentsList: gInstrumentsList });
        }
      } else {
        if (gDebug) console.log(gPrefix, 'Use instruments from in-memory cache.');
        deferred.resolve({ instrumentsList: gInstrumentsList });
      }
      return deferred.promise;
    }

    /**
     * This function loads instruments from database.
     *
     * @return {angular.IPromise<ecapp.ITradingInstrument[]>}
     */
    function loadInstruments() {
      return lbInstruments
        .find({ filter: { order: ['_id ASC'], fields: gInstrumentFields } })
        .$promise.then(processInstruments);
    }

    /**
     *
     * @param {ecapp.ITradingInstrument[]} instruments
     * @return {ecapp.ITradingInstrument[]}
     */
    function processInstruments(instruments) {
      if (gDebug) console.log(gPrefix, 'Instruments were loaded and cached.');
      gInstrumentsCache.put('list', instruments);

      gInstrumentsList = /**@type {ecapp.ITradingInstrument[]}*/ instruments.map(function (value) {
        return new Instrument(value);
      });

      window.btStore.instruments = gInstrumentsList;

      if (gDebug) console.log(gPrefix, 'gInstrumentsList', gInstrumentsList);

      fillOandaInstruments(gOandaInstruments, gInstrumentsList);
      addBrokerSymbols('default');

      return gInstrumentsList;
    }

    /**
     *
     * @param {Record<string, ecapp.ITradingInstrument>} obj - oanda instruments as a dictionary
     * @param {ecapp.ITradingInstrument[]} list - list of instruments
     */
    function fillOandaInstruments(obj, list) {
      list.reduce(function (result, item) {
        if (item.OandaSymbol) {
          result[item.OandaSymbol] = item;
        }
        return result;
      }, obj);
    }

    /**
     * This function returns first instrument with field 'fieldName' equal to 'fieldValue'.
     *
     * @param {string} fieldName - field name
     * @param {string} fieldValue - field value
     * @return {ecapp.ITradingInstrument|undefined}
     */
    function getInstrumentByField(fieldName, fieldValue) {
      var a = gInstrumentsList.filter(function (row) {
        return row[fieldName] === fieldValue;
      })[0];

      var b = Object.values(gCustomInstrument).filter(function (row) {
        return row[fieldName] === fieldValue;
      })[0];

      return a || b || undefined;
    }

    /**
     * This function checks whether oanda symbol is available for this instrument.
     *
     * @param {ecapp.ITradingInstrument} instrument - instrument object
     * @return {Boolean}
     */
    function hasOandaSymbol(instrument) {
      return instrument.OandaSymbol !== '';
    }

    /**
     * This function initializes btInstrumentsService.
     *
     * @alias ecapp.btInstrumentsService#init
     * @return {angular.IPromise<*>}
     */
    function init() {
      if (gInitializationPromise) {
        if (gDebug) console.log(gPrefix, 'Service already initialized.');
      } else {
        if (gDebug) console.log(gPrefix, 'Initializing service...');

        gInitializationPromise = $q
          .all([getInstrumentsList(), loadMarketCharacteristics()])
          .then(function (results) {
            results[1].forEach(function (item) {
              gOandaInstruments[item.symbol].characteristics = {
                time: item['time'],
                'true-range': item['true-range'],
                'significant-thresholds': item['significant-thresholds'],
                'hourly-change-scale': item['hourly-change-scale'],
                'daily-change-scale': item['daily-change-scale'],
                'yearly-scale': item['yearly-scale'],
              };
            });
            if (gDebug) console.log(gPrefix, 'Service was initialized.');
          })
          .catch(function (reason) {
            gInitializationPromise = null;
            console.error(gPrefix, reason);
            return $q.reject(reason);
          });
      }

      return gInitializationPromise;
    }

    // /**
    //  * This function tries to convert market name to BetterTrader instrument name.
    //  *
    //  * @param {string} name - market name should be BetterTrader of HistData instrument name
    //  * @return {string}
    //  */
    // function convertMarketName(name) {
    //   if (getInstrumentByField('btName', name)) {
    //     return name;
    //   } else {
    //     var test = getInstrumentByField('histData', name);
    //     if (test !== undefined && test.btName !== undefined) {
    //       return test.btName;
    //     } else {
    //       return name
    //     }
    //   }
    // }

    /**
     * This function returns trade station expiration.
     *
     * @param {string[]} months
     * @param {Date} [devNow]
     * @return {string}
     */
    function getExpiration(months, devNow) {
      var now = devNow || btDateService.getNowDate();
      var year = now.getFullYear();
      var timestamps = getMonthsTimestamps(months, year).concat(getMonthsTimestamps(months, year + 1));

      var nowTimestamp = now.getTime();
      var timestamp = timestamps.filter(function (t) {
        return t - nowTimestamp > BT_TWO_WEEKS;
      })[0];

      var expiration = new Date(timestamp);
      var expirationYear = (expiration.getUTCFullYear() % 100).toString();
      var expirationMonth = gMonthToSymbol[expiration.getUTCMonth()];
      return expirationMonth + expirationYear;
    }

    /**
     * This function returns timestamps of the first day of each months.
     *
     * @param {string[]} months - list of months
     * @param {number} year - specific year
     * @return {number[]}
     */
    function getMonthsTimestamps(months, year) {
      return months.map(function (t) {
        return new Date(Date.UTC(year, gSymbolToMonth[t])).getTime();
      });
    }

    /**
     * This function returns broker's symbol for selected instrument or empty string.
     *
     * @param {ecapp.ITradingInstrument} instrument - instrument object
     * @param {string} brokerName - broker id
     * @return {string} - broker name of symbol
     */
    function getBrokerSymbol(instrument, brokerName) {
      if (instrument.btName === 'N/A') {
        return instrument.brokerSymbol;
      }

      if (brokerName === 'tradestation') {
        return gTradeStationSymbols[instrument.btName] || '';
      }

      if (brokerName === 'tradier') {
        return gTradierSymbols[instrument.btName] || '';
      }

      if (brokerName === 'ctrader') {
        return gCTraderSymbols[instrument.btName] || '';
      }

      if (brokerName === 'oanda') {
        return instrument.OandaSymbol || '';
      }

      if (brokerName === 'default') {
        return instrument.btSymbol || '';
      }

      return '';
    }

    /**
     * This function converts BetterTrader symbol name to broker symbol name or returns empty string.
     *
     * @param {string} btName - BetterTrader symbol name
     * @param {string} brokerId - broker id
     * @return {string} broker name of symbol
     */
    function convertBrokerName(btName, brokerId) {
      var instrument = getInstrumentByField('btName', btName);
      if (instrument) {
        return getBrokerSymbol(instrument, brokerId);
      } else {
        return '';
      }
    }

    /**
     * This function converts broker symbol to bettertrader or returns empty string.
     *
     * @param {string} symbol - broker symbol
     * @param {string} broker - broker name
     * @return {string}
     */
    function convertBTName(symbol, broker) {
      if (broker === 'tradestation') {
        for (var key in gTradeStationSymbols) {
          if (gTradeStationSymbols.hasOwnProperty(key)) {
            if (gTradeStationSymbols[key] === symbol) {
              return key;
            }
          }
        }
        return '';
      }

      if (broker === 'oanda') {
        var instrument1 = getInstrumentByField('OandaSymbol', symbol);
        return instrument1 ? instrument1.btName : '';
      }

      if (broker === 'default') {
        // !!!
        var instrument2 = getInstrumentByField('OandaSymbol', symbol);
        return instrument2 ? instrument2.btName : '';
      }

      if (broker === 'ctrader') {
        if (gCTraderSymbolsReversed[symbol] !== undefined) {
          return gCTraderSymbolsReversed[symbol];
        } else {
          return '';
        }
      }

      if (broker === 'tradier') {
        if (gTradierSymbolsReversed[symbol] !== undefined) {
          return gTradierSymbolsReversed[symbol];
        } else {
          return '';
        }
      }

      return '';
    }

    /**
     * This function converts instrument name to BetterTrader name or returns original name.
     *
     * @param {string} instrument - instrument and broker name
     * @return {string} BetterTrader name
     */
    function convertComplexName2BTName(instrument) {
      if (instrument.indexOf(':') !== -1) {
        return convertBTName(instrument.split(':')[0], instrument.split(':')[1].toLowerCase());
      } else {
        return instrument;
      }
    }

    /**
     * This function gets instrument by complex symbol.
     *
     * @alias ecapp.btInstrumentsService#getInstrumentByComplexSymbol
     * @param {string} complexSymbol - complex name of instrument
     * @param {boolean} [skip] - skip
     * @return {ecapp.ITradingInstrument} instrument object
     */
    function getInstrumentByComplexSymbol(complexSymbol, skip) {
      var symbol = getSymbol(complexSymbol);
      var broker = getBroker(complexSymbol).toLowerCase();

      var btName = convertBTName(symbol, broker);
      var instrument = getInstrumentByField('btName', btName);

      if (instrument === undefined) {
        if (skip) {
          return undefined;
        } else {
          if (gDebug) console.log('>>> ComplexSymbol', complexSymbol);
          var provider = broker === 'default' ? 'oanda' : broker;
          return createBrokerInstrument(broker, symbol, provider, 'Unknown', true);
        }
      } else {
        return instrument;
      }
    }

    /**
     * Returns instrument by ticker.
     *
     * @param {string} ticker - symbol
     * @return {ecapp.ITradingInstrument}
     */
    function getInstrumentByTicker(ticker) {
      const instrument = getInstrumentByField('ticker', ticker);
      return instrument;
    }

    /**
     *
     * @param {string} symbol - symbol
     * @param {string} [broker] - broker
     * @return {ecapp.ITradingInstrument}
     */
    function getInstrumentSmart(symbol, broker) {
      void broker;

      var i1 = getInstrumentByField('ticker', symbol);
      var i2 = getInstrumentByField('brokerSymbol', symbol);
      var i3 = getInstrumentByField('OandaSymbol', symbol);
      var i4 = getInstrumentByField('btName', symbol);
      var i5 = getInstrumentByComplexSymbol(symbol, true);

      var res = i1 || i2 || i3 || i4 || i5 || null;

      if (gDebug) console.log('>>> Smart', 'symbol:', symbol, 'broker:', broker, 'instrument:', res);

      return res;
    }

    /**
     *
     * @param {string} symbol
     * @return {ecapp.ITradingInstrument}
     */
    function getInstrumentBySomeSymbol(symbol) {
      if (symbol.indexOf(':') !== -1 && symbol.split(':')[1].toUpperCase() !== 'IND') {
        return getInstrumentByComplexSymbol(symbol);
      } else {
        return getInstrumentByField('btName', symbol);
      }
    }

    /**
     * This function create broker instrument.
     *
     * @param {string} broker - broker name
     * @param {string} symbol - symbols name
     * @param {string} provider - provider name
     * @param {string} desc - description
     * @param {boolean} missed - missed
     * @param {Record<string, any>} [raw] - raw instrument data
     * @return {ecapp.ITradingInstrument}
     */
    function createBrokerInstrument(broker, symbol, provider, desc, missed, raw) {
      raw = raw || {};
      var id = symbol.toUpperCase() + ':' + broker.toUpperCase();
      var ticker = provider.toUpperCase() + ':' + symbol.toUpperCase();

      if (!gCustomInstrument[id]) {
        if (gDebug) console.log('>>> gCustomInstrument[id]', gCustomInstrument[id], ticker);

        gCustomInstrument[id] = new Instrument({
          missed: missed,
          id: id,
          ticker: ticker,
          type: raw.AssetType || 'Custom',
          btName: 'N/A',
          provider: provider,
          brokerName: broker,
          brokerSymbol: symbol,
          displayName: symbol,
          pronunciation: symbol,
          description: desc,
          pip: '0.0001',
          tick: 0.0001,
          precision: provider === 'lds' ? 2 : 4,
          raw: raw,
        });
      }

      return gCustomInstrument[id];
    }

    /**
     *
     * @param {ecapp.UDFSymbol} symbol - searched symbol
     * @return {ecapp.ITradingInstrument}
     */
    function createFromUDFSymbol(symbol) {
      var pip = symbol.pricescale.toString();

      if (gDebug) console.log('>>>');
      if (gDebug) console.log('>>> Symbol', symbol);

      if (symbol.exchange === 'F') {
        if (gDebug) console.log('>>> OandaSymbol', symbol.name);

        var oandaInstrument = getInstrumentByField('OandaSymbol', symbol.name);
        if (oandaInstrument) {
          if (gDebug) console.log('>>> Instrument', oandaInstrument);
          return oandaInstrument;
        }
      }

      if (gDebug) console.log('>>> Ticker', symbol.ticker);
      var btInstrument = getInstrumentByField('ticker', symbol.ticker);
      // var i1 = getInstrumentByField('btSymbol', symbol.ticker);

      if (btInstrument) {
        if (gDebug) console.log('>>> Instrument', btInstrument);
        return btInstrument;
      }

      var i = new Instrument({
        id: symbol.ticker,
        ticker: symbol.ticker,
        type: symbol.type,
        btName: symbol.ticker,
        OandaSymbol: symbol.exchange === 'F' ? symbol.name : undefined,
        provider: 'bettertrader',
        brokerName: 'default',
        brokerSymbol: symbol.ticker,
        btSymbol: symbol.ticker,
        displayName: symbol.full_name,
        pronunciation: symbol.full_name,
        description: symbol.description,
        pip: pip,
        tick: symbol.pricescale,
        precision: pip.length - 1,
        raw: symbol,
      });

      if (gDebug) console.log(gPrefix, 'Create From UDF Symbol', JSON.parse(JSON.stringify(i)));

      gInstrumentsList.push(i);

      if (gDebug) console.log('>>> Instrument', i);
      return i;
    }

    /**
     * This function adds broker name and broker symbols to each instrument.
     *
     * @param {string} brokerName - name of broker
     */
    function addBrokerSymbols(brokerName) {
      if (gDebug) console.log('>>> addBrokerSymbols');

      gInstrumentsList.forEach(function (item) {
        item.setBroker(brokerName);
      });
    }

    /**
     * This function returns uppercase instrument part of complex symbol.
     *
     * @param {string} complexSymbol - complex name of instrument
     * @return {string}
     */
    function getSymbol(complexSymbol) {
      return complexSymbol.split(':').shift().toUpperCase();
    }

    /**
     * This function returns uppercase broker part of complex symbol.
     *
     * @param {string} complexSymbol - complex name of instrument
     * @return {string}
     */
    function getBroker(complexSymbol) {
      return complexSymbol.split(':').pop().toUpperCase();
    }

    // /**
    //  * This function creates complex symbol.
    //  *
    //  * @param {string} brokerSymbol - broker name of instrument
    //  * @param {string} brokerName - name of broker
    //  * @return {string} - complex name of instrument
    //  */
    // function createComplexSymbol(brokerSymbol, brokerName) {
    //   if (brokerSymbol === undefined || brokerSymbol === null) {
    //     alert('Bad Broker symbol');
    //   }

    //   if (brokerName === 'default') {
    //     return brokerSymbol.toUpperCase() + ':OANDA';
    //   } else {
    //     return brokerSymbol.toUpperCase() + ':' + brokerName.toUpperCase();
    //   }
    // }

    /**
     * This function gets instrument pronunciation.
     *
     * @alias ecapp.btInstrumentsService#getPronunciation
     * @param {ecapp.ITradingInstrument} instrument - instrument object
     * @return {string}
     */
    function getPronunciation(instrument) {
      if (instrument.pronunciation) {
        return instrument.pronunciation;
      } else {
        var text = instrument.displayName;
        text = text.replace('EUR/USD', 'Euro USD');
        return text;
      }
    }

    /**
     * This function returns TradeStation symbols.
     *
     * @return {Object}
     */
    function getTradeStationSymbols() {
      return gTradeStationSymbols;
    }

    /**
     * This function initializes instrument cache.
     */
    function setupCache() {
      if (gDebug) console.log(gPrefix, 'Initializing local cache...');

      if (!CacheFactory.get('instruments')) {
        gInstrumentsCache = CacheFactory('instruments', {
          storageMode: 'localStorage',
          maxAge: 1000 * 60 * 60 * 24,
          // maxAge: 10 * 1000,
          deleteOnExpire: 'passive',
          recycleFreq: 1000,
          storagePrefix: 'bt.caches.',
          onExpire: onExpire,
        });
        checkCacheVersion(gInstrumentsCache);
      } else {
        gInstrumentsCache = CacheFactory.get('instruments');
      }

      /**
       * This function refreshes instrument cache on expire.
       *
       * @param {string} key - key
       * @param {*} value - value
       */
      function onExpire(key, value) {
        void value;
        if (gDebug) console.log(gPrefix, 'Local cache value ' + key + ' was expired.');
        if (key === 'list') {
          //
        }

        if (key === 'names') {
          //
        }
      }
    }

    /**
     *
     * @param {ext.ICacheObject} cache
     */
    function checkCacheVersion(cache) {
      if (cache.get('version') !== window.mainVersion) {
        cache.removeAll();
        cache.put('version', window.mainVersion);
        if (gDebug) console.log(gPrefix, 'Remove instrument cache');
      }
    }

    /**
     *
     * @alias ecapp.btInstrumentsService#getPrecision
     * @param {ecapp.ITradingInstrument} instrument - instrument object
     * @return {number}
     */
    function getPrecision(instrument) {
      return instrument.precision ? instrument.precision : guessPrecision(instrument);
    }

    /**
     *
     * @param {ecapp.ITradingInstrument} instrument - instrument object
     * @return {number}
     */
    function guessPrecision(instrument) {
      return Math.max(getPriceUnitValue(instrument).toString().length - 1, 1);
    }

    /**
     * This function returns value of instrument unit.
     *
     * @alias ecapp.btInstrumentsService#getPriceUnitValue
     * @param {ecapp.ITradingInstrument} instrument - instrument object
     * @return {number}
     */
    function getPriceUnitValue(instrument) {
      if (!instrument.unit) {
        var name = getPriceUnitName(instrument);
        if (name === 'pip') {
          instrument.unit = instrument.pip ? parseFloat(instrument.pip) : guessPip(instrument);
        } else if (name === 'tick') {
          if (instrument.tick) {
            instrument.unit = instrument.tick;
          } else if (instrument.pip) {
            instrument.unit = parseFloat(instrument.pip);
          } else {
            instrument.unit = guessTick(instrument);
          }
        } else {
          instrument.unit = 0.0001;
        }
      }

      return instrument.unit;
    }

    /**
     * This function tries to guess instrument pip.
     *
     * @param {ecapp.ITradingInstrument} instrument - instrument object
     * @return {number}
     */
    function guessPip(instrument) {
      void instrument;
      return 0.0001;
    }

    /**
     * This function tries to guess instrument tick.
     *
     * @param {ecapp.ITradingInstrument} instrument - instrument object
     * @return {number}
     */
    function guessTick(instrument) {
      void instrument;
      return 0.1;
    }

    /**
     * This function returns name of instrument units: pip, tick or unit.
     *
     * @alias ecapp.btInstrumentsService#getPriceUnitName
     * @param {ecapp.ITradingInstrument} instrument - instrument object
     * @return {string}
     */
    function getPriceUnitName(instrument) {
      switch (instrument.type) {
        case 'Forex':
          return 'pip';
        case 'Commodity':
          return 'tick';
        case 'Index':
          return 'tick';
        case 'Bond':
          return 'tick';
        default:
          return 'unit';
      }
    }

    /**
     * This function loads the latest market characteristics.
     * Market specifications are currently optional, so we skip errors.
     *
     * @return {angular.IPromise<ecapp.IMarketCharacteristicsDocument[]>}
     */
    function loadMarketCharacteristics() {
      if (gDebug) console.log(gPrefix, 'Loading market characteristics...');

      // Load one item to determine the latest date.
      return lbMarketCharacteristic
        .find({ filter: { where: {}, limit: 1, order: 'time DESC' } })
        .$promise.then(function (results) {
          // No data
          if (!(results && results[0])) {
            return $q.reject(new Error('Market Characteristics.' + $btError.ErrorMessage.DATA_LOADING_FAILED));
          }

          // Outdated market characteristics
          if (Date.now() - results.time * 1000 > 691200000 /* 8 days */) {
            $btError.reportAppError(
              new Error($btError.ErrorMessage.MARKET_CHARACTERISTICS_OUTDATED),
              MODULE,
              $btError.ErrorCode.OUTDATED,
              $btError.ErrorLevel.WARNING
            );
          }

          // Load latest market characteristics
          return lbMarketCharacteristic.find({ filter: { where: { time: results[0].time } } }).$promise;
        })
        .then(function (results) {
          if (results && results[0]) {
            if (gDebug) console.log(gPrefix, 'Market characteristics was loaded for', results.length, 'instruments.');
            return results;
          } else {
            return $q.reject(new Error('Market Characteristics.' + $btError.ErrorMessage.DATA_LOADING_FAILED));
          }
        })
        .catch(function (reason) {
          $btError.reportAppError(reason, MODULE, $btError.ErrorCode.unexpectedData, $btError.ErrorLevel.WARNING);
          return [];
        });
    }

    /**
     * Adds new custom instrument to the list.
     *
     * @param {ecapp.ITradingInstrument} instrument - instrument
     */
    function addInstrument(instrument) {
      // TODO: It could be cached on client side
      gCustomInstrument[instrument.id] = instrument;
    }

    /**
     * Checks if the symbol is in ticker format. This can be a complex format too.
     *
     * @param {string} symbol - instrument identifier
     * @return {boolean}
     */
    function isTicker(symbol) {
      return symbol.indexOf(':') !== -1;
    }

    /**
     *
     * @param {string} ticker - ticker
     * @return {ecapp.ITicker}
     */
    function _parseTicker(ticker) {
      var parts = ticker.split(':');
      var prefix = parts[0];
      var name = parts[1];
      var suffix = parts[2];

      if (gDebug && parts.length > 3) console.log('Broken ticker');

      return {
        prefix: name ? prefix : undefined,
        name: name ? name : prefix,
        suffix: suffix,
      };
    }

    /**
     * Upgrades symbol to new format.
     *  USD_CAD > FX:USD_CAD
     *  BTC_USD > CR:BTCUSD
     *
     * @param {string} symbol - symbol from watchlist
     * @return {string}
     */
    function upgradeSymbol(symbol) {
      if (OLD_CRYPTO_SYMBOLS.indexOf(symbol) !== -1) {
        return btSymbolPrefix.CRYPT + ':' + symbol.replace('_', '');
      } else {
        return btSymbolPrefix.FOREX + ':' + symbol;
      }
    }

    /**
     * Downgrades symbol to old format.
     *
     * @param {string} symbol - symbol from watchlist
     * @return {string}
     */
    function downgradeSymbol(symbol) {
      var ticker = _parseTicker(symbol);

      if (ticker.prefix === btSymbolPrefix.FOREX) {
        return ticker.name;
      }

      if (ticker.prefix === btSymbolPrefix.CRYPT && OLD_CRYPTO_SYMBOLS.includes(ticker.name)) {
        return 'BTC_USD';
      }

      return null;
    }

    /**
     *
     * @param {Date} devNow
     */
    function testGetExpiration(devNow) {
      console.log(gPrefix, devNow);
      console.log(gPrefix, 1, getExpiration(['H', 'M', 'U', 'Z'], devNow));
      console.log(gPrefix, 2, getExpiration(['G', 'J', 'M', 'Q', 'V', 'Z'], devNow));
      console.log(gPrefix, 3, getExpiration(['F', 'G', 'H', 'J', 'K', 'M', 'N', 'Q', 'U', 'V', 'X', 'Z'], devNow));
      console.log(gPrefix, 4, getExpiration(['H', 'K', 'N', 'U', 'Z'], devNow));
    }
  }
})();
