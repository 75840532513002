/**
 * Created by Orly on 11/03/2019.
 */

(function btTwitterScannerControllerClosure() {
  'use strict';

  var gDebug = false;
  var gPrefix = 'btTwitterScannerSettingsController';

  angular.module('ecapp').controller('btTwitterScannerSettingsController', btTwitterScannerSettingsController);

  btTwitterScannerSettingsController.$inject = [
    '$scope',
    '$q',
    '$templateCache',
    '$timeout',
    '$ionicPopup',
    '$ionicLoading',
    '$ionicScrollDelegate',
    'btVoiceAssistantHelperService',
    'btTwitterScannerService',
    'btToastrService',
    'btRestrictionService',
  ];

  /**
   * Controller for twitter scanner
   *
   * @ngdoc controller
   * @name ecapp.btTwitterScannerController
   * @param {*} $scope - controller scope
   * @param {angular.IQService} $q
   * @param {angular.ITemplateCacheService} $templateCache -
   * @param {angular.ITimeoutService} $timeout -
   * @param {ionic.IPopupService} $ionicPopup -
   * @param {ionic.ILoadingService} $ionicLoading
   * @param {ionic.IScrollDelegateService} $ionicScrollDelegate
   * @param {ecapp.IVoiceAssistantHelperService} btVoiceAssistantHelperService -
   * @param {ecapp.ITwitterScannerService} btTwitterScannerService -
   * @param {ecapp.IToastrService} btToastrService -
   * @param {ecapp.IRestrictionService} btRestrictionService -
   */
  function btTwitterScannerSettingsController(
    $scope,
    $q,
    $templateCache,
    $timeout,
    $ionicPopup,
    $ionicLoading,
    $ionicScrollDelegate,
    btVoiceAssistantHelperService,
    btTwitterScannerService,
    btToastrService,
    btRestrictionService
  ) {
    if (gDebug) console.log('Running btTwitterScannerController');

    $scope.setting = [];
    $scope.isTweetReadable = btVoiceAssistantHelperService.getTweetReadable();
    $scope.isTweetNotifiable = btVoiceAssistantHelperService.getTweetNotifiable();
    $scope.hasPushNotification = false;

    $scope.isLoadingCustomChannels = false;

    $scope.addChannel = addChannel;
    $scope.removeChannel = removeChannel;
    $scope.editChannel = editChannel;
    $scope.toggleChannel = toggleChannel;
    $scope.isDemoChannel = isDemoChannel;

    $scope.toggleTwitterScanner = toggleTwitterScanner;
    $scope.toggleTweetReadable = changeTweetReadable;
    $scope.toggleInAppNotification = changeInAppNotification;
    $scope.togglePushNotification = changePushNotification;

    $scope.openCaseStudy = function () {
      window.open(
        'https://bettertrader.co/case-studies/how-trumps-tweets-influence-the-oil-market-in-real-time.html',
        '_system'
      );
    };

    $scope.$on('$destroy', onDestroy);

    activate();

    /**
     * This function activates controller
     */
    function activate() {
      if (gDebug) console.log(gPrefix, 'activate');
      $scope.isLoadingCustomChannels = true;

      // Getting default accounts
      btVoiceAssistantHelperService
        .initialize()
        .then(function () {
          $scope.setting = btVoiceAssistantHelperService.findSettings('twitter');
          $scope.hasPushNotification = btVoiceAssistantHelperService.getTweetPushNotification();
          $scope.isLoadingCustomChannels = false;
        })
        .catch(function (reason) {
          console.error(reason);
          btToastrService.error('Initialization failed! ' + (reason.message || 'Unknown error.'), 'Twitter Scanner');
        });
    }

    /**
     * This function is a controller destructor
     */
    function onDestroy() {
      if (gDebug) console.log('Destroy btTwitterScannerController');
    }

    /**
     * This function turns on and turn off Twitter Scanner
     * @param {String} name - name of settings
     * @param {Boolean} position - toggle position
     * @return {angular.IPromise<Boolean>}
     */
    function toggleTwitterScanner(name, position) {
      if (gDebug) console.log('btTwitterScannerController: toggleTwitterScanner', name, position);
      return btVoiceAssistantHelperService.changeConfiguration(name, position).catch(function (reason) {
        console.error(reason);
        btToastrService.error('Adding failed! ' + (reason.message || 'Unknown error.'), 'Twitter Scanner');
        return false;
      });
    }

    /**
     * This function opens the search window so that the user can add a new channel.
     */
    function addChannel() {
      btTwitterScannerService
        .addNewChannel()
        .then(function (channel) {
          if (channel) {
            $timeout(function () {
              $ionicScrollDelegate.scrollBottom(true);
            });
            btToastrService.success('Channel ' + channel.name + ' was added to your list', 'Twitter Scanner');
            return toggleChannel('twitter.' + channel.name, true);
          } else {
            return null;
          }
        })
        .catch(function (reason) {
          console.error(reason);
          btToastrService.error('Adding failed! ' + (reason.message || 'Unknown error.'), 'Twitter Scanner');
        });
    }

    /**
     * This function toggles the channel following
     *
     * @param {String} name - settings name
     * @param {Boolean} position - toggle position
     * @return {angular.IPromise<Boolean>}
     */
    function toggleChannel(name, position) {
      var channel = btTwitterScannerService.findByName(name.replace('twitter.', ''));

      if (channel === undefined) {
        return $q.resolve(false);
      }

      return changePosition()
        .catch(function (reason) {
          if (reason && reason.message === 'Upgrade is required!') {
            btToastrService.warning('Update your plan to follow custom twitter channels', 'Twitter Scanner');
          } else {
            console.error(reason);
            btToastrService.error('Action failed! ' + (reason.message || 'Unknown error.'), 'Twitter Scanner');
          }
          return false;
        })
        .finally(function () {
          $ionicLoading.hide();
        });

      /**
       *
       * @return {any}
       */
      function changePosition() {
        if (position) {
          $ionicLoading.show({ template: '<ion-spinner icon="ios"></ion-spinner><p>Start following...</p>' });
          return followChannel(channel);
        } else {
          $ionicLoading.show({ template: '<ion-spinner icon="ios"></ion-spinner><p>Stop following...</p>' });
          return unfollowChannel(channel);
        }
      }
    }

    /**
     * This function starts follow channel
     *
     * @param {btCustomTwitterAccount} channel - selected channel
     * @return {angular.IPromise<Boolean>}
     */
    function followChannel(channel) {
      return btTwitterScannerService.follow(channel).then(function (status) {
        if (status) {
          btToastrService.success('You started following channel ' + channel.displayName, 'Twitter Scanner');
          return btVoiceAssistantHelperService.changeConfiguration('twitter.' + channel.name, true);
        } else {
          return $q.reject(new Error('Unknown error.'));
        }
      });
    }

    /**
     * This function stops follow channel
     *
     * @param {btCustomTwitterAccount} channel - selected channel
     * @return {angular.IPromise<Boolean>}
     */
    function unfollowChannel(channel) {
      return btTwitterScannerService.unfollow(channel).then(function (status) {
        if (status) {
          btToastrService.success('You stopped following channel ' + channel.displayName, 'Twitter Scanner');
          return btVoiceAssistantHelperService.changeConfiguration('twitter.' + channel.name, false);
        } else {
          return $q.reject(new Error('Unknown error.'));
        }
      });
    }

    /**
     * Shows popup to remove channel from the list
     *
     * @param {btCustomTwitterAccount} channel
     * @return {any}
     */
    function removeChannel(channel) {
      return confirmChannelRemoving(channel)
        .then(function (wasConfirmed) {
          if (wasConfirmed === true)
            return btVoiceAssistantHelperService.changeConfiguration('twitter.' + channel.name, false);
          else return false;
        })
        .then(function (wasUnfollowed) {
          if (wasUnfollowed) return btTwitterScannerService.remove(channel.name);
          else return false;
        })
        .then(function (wasRemoved) {
          if (wasRemoved === true)
            btToastrService.success('You have removed ' + channel.name + ' from Twitter Scanner', 'Twitter Scanner');
          else return false;
        })
        .catch(function (reason) {
          console.error(reason);
          btToastrService.error('Removing failed! ' + (reason.message || 'Unknown error.'), 'Twitter Scanner');
        });
    }

    /**
     * Shows popup to remove channel from the list
     * @param {btCustomTwitterAccount} channel
     * @return {any}
     */
    function confirmChannelRemoving(channel) {
      var params = {
        title: 'Twitter Scanner',
        okText: 'Remove',
        template: 'Would you like to remove <b>' + channel.displayName + '</b>?',
      };

      return $ionicPopup
        .confirm(params)
        .then(function (res) {
          return !!res;
        })
        .catch(function (reason) {
          console.error(reason);
          return false;
        });
    }

    /**
     * Shows popup to edit channel's display name and pronunciation
     *
     * @param {btCustomTwitterAccount} channel
     */
    function editChannel(channel) {
      btTwitterScannerService
        .edit('edit', channel)
        .then(btTwitterScannerService.update)
        .then(function (modifiedChannel) {
          var text = 'Custom channel ' + modifiedChannel.displayName + ' was successfully updated';
          btToastrService.success(text, 'Twitter Scanner');
        })
        .catch(function (reason) {
          console.error(reason);
          btToastrService.error('Update failed! ' + (reason.message || 'Unknown error.'), 'Twitter Scanner');
        });
    }

    /**
     * This function checks whether channel is demo
     *
     * @param {String} name - channel name
     * @return {Boolean}
     */
    function isDemoChannel(name) {
      return btTwitterScannerService.isDemo(name);
    }

    /**
     * This function changes tweet readable
     *
     * @param {String} name - settings name
     * @param {Boolean} position - toggle position
     * @return {Boolean}
     */
    function changeTweetReadable(name, position) {
      void name;
      return btVoiceAssistantHelperService.setTweetReadable(position);
    }

    /**
     * This function changes tweet notifiable
     *
     * @param {String} name - settings name
     * @param {Boolean} position - toggle position
     * @return {Boolean}
     */
    function changeInAppNotification(name, position) {
      void name;
      btVoiceAssistantHelperService.setTweetNotifiable(position);
      return true;
    }

    /**
     * This function changes push notification settings.
     *
     * @param {string} name - settings name
     * @param {boolean} position - toggle position
     * @return {boolean}
     */
    function changePushNotification(name, position) {
      void name;
      btVoiceAssistantHelperService.setTweetPushNotification(position);
      return true;
    }
  }
})();
