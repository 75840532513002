/**
 * Created by David on 11/03/2016.
 */

/* global OandaApi */

// @ts-check
(function btOandaApiServiceClosure() {
  'use strict';

  angular
    .module('ecapp')
    /**
     * This factory works with OANDA API.
     * @ngdoc service
     * @name btOandaApiService
     * @memberOf ecapp
     */
    .factory('btOandaApiService', btOandaApiService);

  btOandaApiService.$inject = [
    '$q',
    '$http',
    '$interval',
    'btSettings',
    'btInstrumentsService',
    '$ionicPopup',
    'btOauthService',
    'btTemplateApiService',
  ];
  /**
   *
   * @param {angular.IQService} $q
   * @param {angular.IHttpService} $http
   * @param {angular.IIntervalService} $interval
   * @param {ecapp.ISettings} btSettings
   * @param {ecapp.IInstrumentsService} btInstrumentsService
   * @param {ionic.IPopupService} $ionicPopup
   * @param {ecapp.IOauthService} btOauthService
   * @param {ecapp.ITemplateApiService} btTemplateApiService
   * @return {ecapp.IOandaApiService}
   */
  function btOandaApiService(
    $q,
    $http,
    $interval,
    btSettings,
    btInstrumentsService,
    $ionicPopup,
    btOauthService,
    btTemplateApiService
  ) {
    console.log('Running btOandaApiService');

    /**
     * User access data
     * @type {ecapp.IBrokerData}
     */
    var gUserData = {};

    /**
     * Is logged in
     * @type {Boolean}
     */
    var gIsLoggedIn = false;

    /**
     * Accounts cache
     * @type {Array}
     */
    var gAccounts = [];

    /**
     * Selected account id
     * @type {String | null}
     */
    var gSelectedAccountId = null;

    /**
     * Default access token
     * @type {String}
     */
    var gApiKey = btSettings.BT_OANDA_TOKEN;

    /**
     * Authorization header
     * @type {String}
     */
    var gAuthorization = 'Bearer ' + gApiKey;

    /**
     * OANDA API HTTP endpoint
     * @type {String}
     */
    var gOandaUrl = btSettings.BT_OANDA_URL;

    /**
     * OANDA API wrapper endpoint
     * @type {String}
     */
    var gOandaAPIUrl = 'api-fxtrade.oanda.com';
    // var oandaStreamAPIUrl = "stream-fxpractice.oanda.com";

    /**
     * OANDA API wrapper context
     * @type {{hostname: String, setToken: Function, account: Object, order: Object, position: Object}}
     */
    var gCtx = {};

    /**
     * Instrument cache
     * @type {Array | null}
     */
    var gInstruments = null;

    var gLastPrices = {};

    var gSimulate = {
      username: false,
      signup: false,
    };

    /**
     *
     * @param {*} email
     * @param {*} username
     * @return {any}
     */
    function getDemoSignupData(email, username) {
      return {
        status: 'success',
        sso_token: 'f090e84b9b8973c7817d1e84d68aa9a5-e74e73933d8cafd9d996f917dfabd246',
        user: {
          languageCode: 'en',
          tradingExperiences: [{ experience_duration: 0, experience_type: 0 }],
          addresses: [
            {
              city: '',
              country: 'Singapore',
              region: '',
              line2: '',
              line1: '',
              iso2: 'SG',
              postcode: '',
              type: 'Primary',
            },
          ],
          entityType: 'individual',
          can_trade: true,
          netWorthRangeStart: 0,
          employmentPosition: '',
          citizenship: '',
          user_id: '8398959',
          homeCurrency: 'SGD',
          mfaRequired: false,
          dateOfBirth: '',
          productsServicesDetails: '',
          ab_tests: { next_steps: 'control' },
          email: email,
          division: 'OAP',
          employmentLength: 0,
          employmentStatus: 'Student',
          corePricing: 0,
          oandaRelationshipType: false,
          netWorthCurrencyType: 'SGD',
          oap_regulatory_info: {},
          suitabilityError: 0,
          validGovernmentId: '',
          desiredRiskTolerance: 0,
          netWorthValue: 0,
          bankruptcyDetails: 'NA',
          userName: username,
          tradingObjective: '',
          name: {},
          industry: 'NA',
          telephoneNumbers: [{ priority: 0, type: 'Telephone', number: '' }],
          netWorthRangeEnd: 0,
          can_deposit: true,
          emailValidated: true,
          marketingEmailOptIn: true,
          emailAddresses: [{ priority: 0, type: 'Primary', address: email }],
          annualUserIncome: '',
        },
      };
    }

    /**
     * Get one instrument last prices. Wrapper.
     * @param {string} instrument - instrument name known by oanda API
     * @return {angular.IPromise<ecapp.ITradingLiveCandle>}
     */
    function getLiveCandleData(instrument) {
      if (btSettings.BT_OANDA_DOWNGRADE === '1') {
        return getLiveCandleDataV1(instrument);
      } else {
        // return $q.reject(new Error('Block'));
        return getLiveCandleDataV3(instrument);
      }
    }

    /**
     * Get one instrument last prices. Send request to oanda every 5 second for realtime Values
     * Used in Markets tab, Event Related instruments
     * @param {string} instrument - instrument name known by oanda API
     * @return {angular.IPromise<ecapp.ITradingLiveCandle>}
     */
    function getLiveCandleDataV3(instrument) {
      var params = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: gAuthorization,
          // 'Accept-Datetime-Format': 'UNIX'
        },
        params: {
          granularity: 'D',
          count: 2,
          price: 'MAB',
        },
      };

      return $http
        .get(gOandaUrl + '/instruments/' + instrument + '/candles', params)
        .then(function (res) {
          if (!res.data || res.data.candles.length < 2) {
            return $q.reject(new Error("Bad response for getLiveCandleData! Can't found enough candles"));
          }

          return $q.resolve(prepareCandles(res.data));
        })
        .catch(function (error) {
          return $q.reject(_getError(error));
        });
    }

    /**
     * Downgrade OANDA API url from v3 to v1
     * @param {String} url - v3 OANDA API url
     * @return {String} - v1 OANDA API url
     */
    function downgradeUrl(url) {
      return url.replace('v3', 'v1');
    }

    /**
     * Wrapper for getLiveCandleDataV1 requests
     * @param {String} instrument - instrument name
     * @param {String} price - price type 'bidask' or ' midpoint'
     * @param {String} oandaUrl - OANDA API url
     * @param {String} authorization - authorization header
     * @return {angular.IPromise<Object>}
     */
    function getPromise(instrument, price, oandaUrl, authorization) {
      return $http.get(downgradeUrl(oandaUrl) + '/candles', {
        headers: {
          'Content-Type': 'application/json',
          Authorization: authorization,
        },
        params: {
          instrument: instrument,
          granularity: 'D',
          count: 2,
          candleFormat: price,
        },
      });
    }

    /**
     * Get one instrument last prices. Send request to oanda every 5 second for realtime Values
     * Used in Markets tab, Event Related instruments
     * @param {string} instrument - instrument name known by oanda API
     * @return {angular.IPromise<ecapp.ITradingLiveCandle>}
     */
    function getLiveCandleDataV1(instrument) {
      var defer = $q.defer();
      var p1 = getPromise(instrument, 'bidask', gOandaUrl, gAuthorization);
      var p2 = getPromise(instrument, 'midpoint', gOandaUrl, gAuthorization);
      $q.all([p1, p2])
        .then(function (response) {
          /** @type {{candles: oandaBidAskCandle[]}} */
          var dataBidAsk = response[0].data;
          /** @type {{candles: oandaMidCandle[]}} */
          var dataMid = response[1].data;

          saveLastPrice(instrument, dataMid.candles[1].closeMid);

          var test = {
            symbol: instrument,
            yesterday: {
              close: parseFloat(dataMid.candles[0].closeMid),
              closeText: dataMid.candles[0].closeMid,
            },
            today: {
              low: parseFloat(dataBidAsk.candles[1].lowBid),
              lowText: dataBidAsk.candles[1].lowBid,
              high: parseFloat(dataBidAsk.candles[1].highAsk),
              highText: dataBidAsk.candles[1].highAsk,
              open: parseFloat(dataMid.candles[1].openMid),
              openText: dataMid.candles[1].openMid,
            },
            now: {
              bid: parseFloat(dataBidAsk.candles[1].closeBid),
              bidText: dataBidAsk.candles[1].closeBid,
              ask: parseFloat(dataBidAsk.candles[1].closeAsk),
              askText: dataBidAsk.candles[1].closeAsk,
              last: parseFloat(dataMid.candles[1].closeMid),
              lastText: dataMid.candles[1].closeMid,
            },
          };
          defer.resolve(test);
        })
        .catch(function (error) {
          defer.reject(_getError(error));
        });
      return defer.promise;
    }

    /**
     *
     * @param {*} symbols
     * @return {any}
     */
    function getLiveCandlesData(symbols) {
      var params = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: gAuthorization,
          // 'Accept-Datetime-Format': 'UNIX'
        },
        params: {
          candleSpecifications: symbols.join(':D:MAB,') + ':D:MAB',
        },
      };

      return $http
        .get(gOandaUrl + '/accounts/' + getSelectedAccountId() + '/candles/latest', params)
        .then(function (res) {
          if (!res.data || !res.data.latestCandles) {
            return $q.reject(new Error("Bad response for getLiveCandleData! Can't found enough candles"));
          }

          return $q.resolve(res.data.latestCandles.map(prepareCandles));
        })
        .catch(function (error) {
          return $q.reject(_getError(error));
        });
    }

    /**
     *
     * @param {*} data
     * @return {ecapp.ITradingLiveCandle}
     */
    function prepareCandles(data) {
      var today = data.candles[data.candles.length - 1];
      var yesterday = data.candles[data.candles.length - 2];

      saveLastPrice(data.instrument, today.mid.c);

      return {
        symbol: data.instrument,
        time: today.time,
        yesterday: {
          close: parseFloat(yesterday.mid.c),
          closeText: yesterday.mid.c,
        },
        today: {
          low: parseFloat(today.bid.l),
          lowText: today.bid.l,
          high: parseFloat(today.ask.h),
          highText: today.ask.h,
          open: parseFloat(today.mid.o),
          openText: today.mid.o,
        },
        now: {
          bid: parseFloat(today.bid.c),
          bidText: today.bid.c,
          ask: parseFloat(today.ask.c),
          askText: today.ask.c,
          last: parseFloat(today.mid.c),
          lastText: today.mid.c,
        },
      };
    }

    /**
     * Get one instrument n Candles with frequency defined by granularity parameter. Wrapper.
     * @param {String} instrument - instrument name known by oanda API
     * @param {String} granularity - set the candle time representation
     * @param {number} [count] - number of candles
     * @return {angular.IPromise<Object>}
     */
    function getLastCandlesData(instrument, granularity, count) {
      if (btSettings.BT_OANDA_DOWNGRADE === '1') {
        return getLastCandlesDataV1(instrument, granularity, count);
      } else {
        return getLastCandlesDataV3(instrument, granularity, count);
      }
    }

    /**
     * Get one instrument n Candles with frequency defined by granularity parameter. Send request to oanda
     * @param {String} instrument - instrument name known by oanda API
     * @param {String} granularity - set the candle time representation
     * @param {number} [count] - number of candles
     * @return {angular.IPromise<Object>}
     */
    function getLastCandlesDataV3(instrument, granularity, count) {
      var defer = $q.defer();
      var params = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: gAuthorization,
          'Accept-Datetime-Format': 'UNIX',
        },
        params: {
          granularity: granularity,
          count: count,
          price: 'M',
        },
      };
      $http
        .get(gOandaUrl + '/instruments/' + instrument + '/candles', params)
        .then(function (response) {
          defer.resolve(response.data);
        })
        .catch(function (error) {
          defer.reject(_getError(error));
        });
      return defer.promise;
    }

    /**
     * Get one instrument n Candles with frequency defined by granularity parameter. Send request to oanda
     * @param {String} instrument - instrument name known by oanda API
     * @param {String} granularity - set the candle time representation
     * @param {number} [count] - number of candles
     * @return {angular.IPromise<Object>}
     */
    function getLastCandlesDataV1(instrument, granularity, count) {
      var defer = $q.defer();
      $http
        .get(downgradeUrl(gOandaUrl) + '/candles', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: gAuthorization,
            'X-Accept-DateTime-Format': 'UNIX',
          },
          params: {
            instrument: instrument,
            granularity: granularity,
            count: count,
            candleFormat: 'midpoint',
          },
        })
        .then(function (response) {
          var data = response.data;
          data.candles = data.candles.map(handleCandles);
          defer.resolve(data);
        })
        .catch(function (error) {
          defer.reject(_getError(error));
        });
      return defer.promise;

      /**
       * Handle candles
       * @param {{complete: Boolean, openMid: String, closeMid: String, lowMid: String, highMid: String, time: Number, volume: Number}} item
       * @return {{complete, mid: oandaCandle, time: Number, volume: Number}}
       */
      function handleCandles(item) {
        return {
          complete: item.complete,
          mid: {
            c: item.closeMid,
            h: item.highMid,
            l: item.lowMid,
            o: item.openMid,
          },
          time: item.time / 1000,
          volume: item.volume,
        };
      }
    }

    /**
     * Get entry price for trade card. Wrapper.
     * @param {String} instrument - instrument name known by oanda API
     * @param {Number} time - timestamp in seconds
     * @param {Number} minAfter - number of minutes after selected moment
     * @return {angular.IPromise<{instrument: string, granularity: string, candles: ecapp.ITradingLastCandle[]}>}
     */
    function getEntryPrice(instrument, time, minAfter) {
      minAfter = minAfter || 2;
      if (btSettings.BT_OANDA_DOWNGRADE === '1') {
        return getEntryPriceV1(instrument, time, minAfter);
      } else {
        return getEntryPriceV3(instrument, time, minAfter);
      }
    }

    /**
     * Get entry price for trade card
     * @param {String} instrument - instrument name known by oanda API
     * @param {Number} time - timestamp in seconds
     * @param {Number} minAfter - number of minutes after selected moment
     * @return {angular.IPromise<{instrument: string, granularity: string, candles: ecapp.ITradingLastCandle[]}>}
     */
    function getEntryPriceV3(instrument, time, minAfter) {
      var defer = $q.defer();
      var params = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: gAuthorization,
          'Accept-Datetime-Format': 'UNIX',
        },
        params: {
          granularity: 'M1',
          count: minAfter + 1,
          price: 'M',
          from: time,
          // includeFirst: true
        },
      };
      $http
        .get(gOandaUrl + '/instruments/' + instrument + '/candles', params)
        .then(function (response) {
          defer.resolve(response.data);
        })
        .catch(function (error) {
          defer.reject(_getError(error));
        });
      return defer.promise;
    }

    /**
     * Get entry price for trade card
     * @param {String} instrument - instrument name known by oanda API
     * @param {Number} time - timestamp in seconds
     * @param {Number} minAfter - number of minutes after selected moment
     * @return {angular.IPromise<Object>}
     */
    function getEntryPriceV1(instrument, time, minAfter) {
      var defer = $q.defer();
      $http
        .get(downgradeUrl(gOandaUrl) + '/candles', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: gAuthorization,
            'X-Accept-DateTime-Format': 'UNIX',
          },
          params: {
            instrument: instrument,
            granularity: 'M1',
            count: minAfter + 1,
            from: time,
            candleFormat: 'midpoint',
          },
        })
        .then(function (response) {
          var data = response.data;
          data.candles = data.candles.map(function (item) {
            return {
              complete: item.complete,
              mid: {
                c: item.closeMid,
                h: item.highMid,
                l: item.lowMid,
                o: item.openMid,
              },
              time: item.time / 1000,
              volume: item.volume,
            };
          });
          defer.resolve(data);
        })
        .catch(function (error) {
          defer.reject(_getError(error));
        });
      return defer.promise;
    }

    /* --- Public functions --- */

    /**
     * Initialize service
     * @param {Object} data - access data
     * @return {angular.IPromise<Object>}
     */
    function initialize(data) {
      var deferred = $q.defer();

      if (data === undefined || data === null) {
        deferred.reject(new Error('Bad access data'));
      } else {
        gUserData = data;
        if (gUserData.defaultAccount) {
          gSelectedAccountId = gUserData.defaultAccount;
        }
        deferred.resolve({});
      }
      return deferred.promise;
    }

    /**
     * Connect Broker
     * @return {angular.IPromise<Object>}
     */
    function connect() {
      var deferred = $q.defer();
      console.log('btOandaApiService: connect');
      if (gUserData.token !== undefined) {
        if (gIsLoggedIn) {
          deferred.resolve({});
        } else {
          setTradingMode(gUserData.mode);
          gIsLoggedIn = true;
          deferred.resolve({});
        }
      } else {
        gIsLoggedIn = false;
        deferred.reject(new Error('Is not initialized'));
      }
      return deferred.promise;
    }

    /**
     * Disconnect broker
     * @return {angular.IPromise<Object>}
     */
    function disconnect() {
      console.log('btOandaApiService: disconnect');
      return logout();
    }

    /**
     * Login Broker
     * @param {String} mode - trading mode: real ot demo
     * @param {Boolean} isForceLogin - force login or not
     * @return {angular.IPromise<Object>}
     */
    function login(mode, isForceLogin) {
      void isForceLogin;

      return btOauthService.login('oanda-' + mode).then(function (data) {
        console.log('btOandaApiService: login');
        // reset selected account after default broker
        gSelectedAccountId = null;
        gUserData = { token: data.accessToken, mode: mode, defaultAccount: '' };
        setTradingMode(mode);

        return gUserData;
      });
    }

    /**
     * Fast Login Broker
     * @param {String} mode - trading mode: real ot demo
     * @param {{sso_token: String}} data - access data
     * @return {angular.IPromise<Object>}
     */
    function fastLogin(mode, data) {
      console.log('btOandaApiService: login');
      // reset selected account after default broker
      gSelectedAccountId = null;
      gUserData = { token: data.sso_token, mode: mode, defaultAccount: '' };
      setTradingMode(mode);

      return $q.resolve(gUserData);
    }

    /**
     * Logout Broker
     *
     * @return {angular.IPromise<Object>}
     */
    function logout() {
      var deferred = $q.defer();
      console.log('btOandaApiService: logout');
      gUserData = {};
      gCtx = {};
      gIsLoggedIn = false;
      gAccounts = [];
      gSelectedAccountId = null;
      // window.localStorage.setItem('OANDAToken', userData);
      deferred.resolve({});
      return deferred.promise;
    }

    /**
     * Check user data (now just username)
     * @param {oandaUserRequest} userData - user data
     * @return {angular.IPromise<*>}
     */
    function checkUser(userData) {
      var params = {
        headers: { 'Content-Type': 'application/json' },
        params: { username: userData.username },
      };

      var isGoodName = /^BTrader_\w{2,42}$/.test(userData.username);
      var isGoodEmail = /^.+$/.test(userData.email);
      var isGoodPassword = /^.{8,20}$/.test(userData.password);

      if (!isGoodName)
        return $q.reject(
          new Error(
            'Bad user name "' +
              userData.username +
              '". Name must have from 2 to 50 characters ' +
              '(letters or number or "_") and start from BTrader_.'
          )
        );

      if (!isGoodEmail) return $q.reject(new Error('Bad user mail.'));

      if (!isGoodPassword)
        return $q.reject(new Error('Bad user password. Password must have from 8 to 20 any symbols.'));

      if (gSimulate.username) {
        return $q.resolve(userData);
      } else {
        return $http
          .get(btSettings.BT_BACKEND_URL + '/auth/oanda/username/availability', params)
          .then(function (response) {
            // console.log(response);
            if (response.data.availability) {
              return $q.resolve(userData);
            } else {
              return $q.reject(new Error('Username already in use'));
            }
          })
          .catch(_handleUserError);
      }
    }

    /**
     * Create username from email
     * @param {String} email - user email as feed to create username
     * @return {angular.IPromise<*>}
     */
    function getUsername(email) {
      var params = {
        headers: { 'Content-Type': 'application/json' },
        params: { email: email },
      };

      return $http
        .get(btSettings.BT_BACKEND_URL + '/auth/oanda/username', params)
        .then(function (response) {
          // console.log(response);
          return $q.resolve(response.data.username);
        })
        .catch(_handleUserError);
    }

    /**
     * Create new OANDA account via API
     * @param {oandaUserRequest} userData - user data
     * @return {angular.IPromise<*>|angular.IPromise<*>}
     */
    function signUp(userData) {
      var params = { headers: { 'Content-Type': 'application/json' } };

      if (gSimulate.signup) {
        var deferred = $q.defer();
        setTimeout(function () {
          deferred.resolve(getDemoSignupData(userData.email, userData.username));
        }, 1000);
        return deferred.promise;
      } else {
        return $http
          .post(btSettings.BT_BACKEND_URL + '/auth/oanda/user', userData, params)
          .then(function (response) {
            // console.log(response);
            if (response.data.status === 'success') {
              return $q.resolve(response.data);
            } else {
              return $q.reject(response);
            }
          })
          .catch(_handleUserError);
      }
    }

    /**
     * Handle OANDA User API errors
     * @param {Object} error - error object
     * @return {angular.IPromise<*>}
     * @private
     */
    function _handleUserError(error) {
      console.log(error);
      if (error && error.message) {
        return $q.reject(error);
      } else if (error.data && error.data.failure_message) {
        return $q.reject(new Error(error.data.failure_message));
      } else {
        return $q.reject(error);
      }
    }

    /**
     * Is user logged in to TradeStation API
     * @return {Boolean}
     */
    function isLoggedIn() {
      return gUserData.token !== undefined;
    }

    /**
     * Get trading mode: live or practice
     * @return {String} - trading mode: "practice" or "trade"
     */
    function getTradingMode() {
      return gCtx.hostname.indexOf('practice') !== -1 ? 'demo' : 'real';
    }

    /**
     * Set trading mode. Return true on success.
     *
     * @param {*} mode
     * @return {boolean} trading mode was changed
     */
    function setTradingMode(mode) {
      if (mode === 'real') {
        gOandaAPIUrl = 'api-fxtrade.oanda.com';
      } else {
        gOandaAPIUrl = 'api-fxpractice.oanda.com';
      }
      gUserData.mode = mode;
      gCtx = new OandaApi.Context(gOandaAPIUrl, 443, true, 'BetterTrader');
      gCtx.setToken(gUserData.token);
      _initializeAccessData(gUserData.token);
      return true;
    }

    /**
     *
     * @param {string} token
     */
    function _initializeAccessData(token) {
      if (token) {
        gApiKey = token;
        gOandaUrl = 'https://' + gOandaAPIUrl + (btSettings.BT_OANDA_DOWNGRADE === '1' ? '/v1' : '/v3');
      } else {
        gApiKey = btSettings.BT_OANDA_TOKEN;
        gOandaUrl = btSettings.BT_OANDA_URL;
      }
      gAuthorization = 'Bearer ' + gApiKey;
    }

    /**
     * Check OANDA API response for error
     * @param {Object} response - response object
     * @return {Boolean} - response contain error
     * @private
     */
    function _hasError(response) {
      //noinspection RedundantIfStatementJS, EqualityComparisonWithCoercionJS
      if (response.statusCode != 200 && response.statusCode != 201) {
        return true;
      } else {
        return false;
      }
    }

    /**
     * Get error from OANDA API response
     * @param {Object} response - response object
     * @return {Object|Error} - error object
     * @private
     */
    function _getError(response) {
      if (response.body && response.body.errorMessage) {
        return new Error(response.body.errorMessage);
      }

      if (response.data && response.data.errorMessage) {
        return new Error(response.data.errorMessage);
      }
      if (response.rawBody) {
        try {
          var body = JSON.parse(response.rawBody);
          return new Error(body.errorMessage);
        } catch (e) {
          return new Error('Unknown error code 0001.');
        }
      }

      return response;
    }

    /* --- User data --- */
    /**
     * Get list of user accounts for selected broker
     *
     * @return {angular.IPromise<Array>} - list of accounts
     */
    function getAccounts() {
      var deferred = $q.defer();
      gCtx.account.list(function (response) {
        if (_hasError(response)) {
          deferred.reject(_getError(response));
        } else {
          // console.log(response);

          gAccounts = response.body.accounts.map(_convertAccount);

          // reset selected account
          gSelectedAccountId = btTemplateApiService.resetSelectedAccount(gAccounts, gSelectedAccountId);

          deferred.resolve(gAccounts);
        }
      });
      return deferred.promise;
    }

    /**
     *
     * @param {*} account
     * @return {ecapp.ITradingAccount}
     * @private
     */
    function _convertAccount(account) {
      return {
        acc: account.id,
        key: account.id,
        name: account.id,
        rawData: account,

        // type: '',
        // typeDescription: '',
        // alias: account.id,
        // altId: account.id,
        // displayName: account.id,
        // isStockLocateEligible: false,
      };
    }

    /**
     * Get balance for accounts
     *
     * @param {Array} accountIds - list of account ids
     * @return {angular.IPromise<Array>}
     */
    function getBalances(accountIds) {
      var deferred = $q.defer();
      var data = [];
      var lastError = null;
      var count = 0;

      accountIds.forEach(function (accountId) {
        gCtx.account.summary(accountId, function (response) {
          count += 1;
          if (_hasError(response)) {
            lastError = _getError(response);
          } else {
            // console.log(response);
            data.push(response.body.account);
          }

          if (count === accountIds.length) {
            if (lastError) {
              deferred.reject(lastError);
            } else {
              var newData = data.map(function (item) {
                return {
                  alias: item.alias,
                  displayName: item.alias,
                  acc: item.id,
                  key: item.id,
                  name: item.alias,
                  type: item.currency,
                  typeDescription: '',
                  NAV: parseFloat(item.NAV),
                  UPL: parseFloat(item.unrealizedPL),
                  Balance: parseFloat(item.balance),
                  RPL: parseFloat(item.pl),
                  MarginUsed: parseFloat(item.marginUsed),
                  MarginAvailable: parseFloat(item.marginAvailable),
                  rawData: item,
                };
              });
              newData.sort(function (a, b) {
                if (a.name < b.name) return -1;
                if (a.name > b.name) return 1;
                return 0;
              });
              deferred.resolve(newData);
            }
          }
        });
      });
      return deferred.promise;
    }

    /**
     * Get all positions for accounts
     *
     * @param {Array} accountIds - list of account ids
     * @return {angular.IPromise<Array>}
     */
    function getPositions(accountIds) {
      var deferred = $q.defer();
      var data = [];
      var lastError = null;
      var count = 0;

      accountIds.forEach(function (accountId) {
        gCtx.position.list(accountId, function (response) {
          count += 1;
          if (_hasError(response)) {
            lastError = _getError(response);
          } else {
            // console.log(response);
            var newData = [];
            response.body.positions.forEach(function (item) {
              var pos = 'none';
              if (item.long.units === '0' && item.short.units === '0') {
                console.log('Bad position. Long and short units equal to zero.');
              } else {
                pos = item.long.units !== '0' ? 'long' : 'short';
              }
              if (pos !== 'none') {
                var avg = parseFloat(item[pos].averagePrice);
                var qty = parseInt(item[pos].units);

                var instrument = btInstrumentsService.getInstrumentByComplexSymbol(item.instrument + ':OANDA');

                /** @type {ecapp.ITradingPosition} */
                var positionObject = {
                  alias: accountId,
                  description: btInstrumentsService.convertBTName(item.instrument, 'oanda'),
                  key: 'op-' + item.instrument,
                  acc: accountId,
                  symbol: item.instrument,
                  displayName: instrument.displayName,
                  position: item.long.units !== '0' ? 'Long' : 'Short',
                  quantity: qty,
                  OPL: parseFloat(item[pos].unrealizedPL),
                  acct: (parseFloat(item[pos].unrealizedPL) / (avg * Math.abs(qty))) * 100,
                  total: 0,
                  margin: 0,
                  avg: avg,
                  mrkValue: avg * qty,
                  lastPrice: getLastPrice(item.instrument),
                  rawData: item,
                };
                newData.push(positionObject);
              }
            });
            data = data.concat(newData);
          }

          if (count === accountIds.length) {
            if (lastError) {
              deferred.reject(lastError);
            } else {
              deferred.resolve(data);
            }
          }
        });
      });
      return deferred.promise;
    }

    /**
     * This function saves last price of the instrument to special cache.
     *
     * It helps to set position last price.
     *
     * @param {String} symbol - instrument symbol
     * @param {String} price - last price as a text
     */
    function saveLastPrice(symbol, price) {
      gLastPrices[symbol] = price;
    }

    /**
     * This function returns last price of the instrument from special cache or zero.
     *
     * It helps to set position last price.
     *
     * @param {String} symbol - instrument symbol
     * @return {number}
     */
    function getLastPrice(symbol) {
      if (gLastPrices[symbol]) {
        return parseFloat(gLastPrices[symbol]);
      } else {
        return 0;
      }
    }

    /**
     * Get all orders for accounts
     *
     * @param {String[]} accountIds - list of account ids
     * @return {angular.IPromise<Array>}
     */
    function getOrders(accountIds) {
      var deferred = $q.defer();

      var bigData = [];
      var bigErrors = [];
      var promises = [];

      // TODO: Add error handling
      // for each account get three types of orders
      accountIds.map(function (accountId) {
        var states = [{ count: 30 }, { state: 'CANCELLED', count: 20 }, { state: 'FILLED', count: 20 }];
        // for each order type create promise
        states.forEach(function (state) {
          var innerDeferred = $q.defer();
          promises.push(innerDeferred.promise);

          gCtx.order.list(accountId, state, function (response) {
            if (_hasError(response)) {
              bigErrors.push(_getError(response));
            } else {
              var data = response.body.orders.map(function (item) {
                var mItem = JSON.parse(JSON.stringify(item));
                mItem.accountId = accountId;
                return mItem;
              });
              bigData = bigData.concat(data);
            }
            innerDeferred.resolve({});
          });
        });
      });

      // TODO: Use promise data instead of bigData
      $q.all(promises).then(function () {
        if (bigErrors.length > 0) {
          deferred.reject(bigErrors[0]);
        } else {
          var newData = bigData.map(_convertOrder);
          deferred.resolve(newData);
        }
      });

      return deferred.promise;
    }

    /**
     *
     * @param {*} item
     * @return {{alias: (*|number|Number), displayName: *, key, acc: (*|number|Number), symbol, quantity: number, side: string, limit: *, stop: *, status: *, fillPrice: string, filled: string, placeTime: DateTime, executeTime: DateTime}}
     * @private
     */
    function _convertOrder(item) {
      var orderStates = {
        PENDING: 'Received',
        FILLED: 'Filled',
        TRIGGERED: 'PartialFill',
        CANCELLED: 'Rejected',
      };

      var instrument = btInstrumentsService.getInstrumentByComplexSymbol(item.instrument + ':OANDA');

      return {
        alias: item.accountId,
        displayName: instrument.displayName,
        key: item.id,
        acc: item.accountId,
        symbol: item.instrument,
        quantity: Math.abs(item.units),
        side: item.units > 0 ? 'Long' : 'Short',
        action: item.units > 0 ? 'Buy' : 'Sell',
        limit: item.type === 'LIMIT' ? parseFloat(item.price) : '-',
        stop: item.type === 'STOP' ? parseFloat(item.price) : '-',
        price: parseFloat(item.price),
        status: orderStates[item.state],
        fillPrice: '-',
        filled: '-',
        placeTime: item.createTime,
        executeTime: item.filledTime,
      };
    }

    /* --- Market Data --- */
    /**
     * Get information about selected symbol
     *
     * @param {String} symbol - symbol name
     * @return {angular.IPromise<ecapp.ITradingInstrument>}
     */
    function getSymbolInfo(symbol) {
      void symbol;
      var deferred = $q.defer();
      deferred.reject(new Error('Coming soon!'));
      return deferred.promise;
    }

    /**
     * Search symbol using query string
     *
     * @param {String} query - symbol name
     * @return {angular.IPromise<Array>} - list of symbol names
     */
    function searchSymbol(query) {
      void query;
      var deferred = $q.defer();
      deferred.reject(new Error('Coming soon!'));
      return deferred.promise;
    }

    /**
     * Search symbol using query string
     *
     * @param {String} text - search text
     * @param {Number} limit - limit number of results
     * @param {Object} params - additional parameters
     * @return {angular.IPromise<Array>} - list of symbol names
     */
    function suggestSymbols(text, limit, params) {
      var deferred = $q.defer();

      if (gInstruments === null) {
        // load data
        if (gAccounts.length === 0) {
          deferred.reject(new Error('Select account at first'));
        } else {
          gCtx.account.instruments(getSelectedAccountId(), params, function (response) {
            if (_hasError(response)) {
              deferred.reject(_getError(response));
            } else {
              if (response.body.instruments !== undefined) {
                gInstruments = response.body.instruments;
                deferred.resolve(search(gInstruments));
              } else {
                deferred.reject(new Error('Bad suggestSymbols response!'));
              }
            }
          });
        }
      } else {
        deferred.resolve(search(gInstruments));
      }

      return deferred.promise;

      /**
       * Search instrument by name
       * @param {Array} instrumentList - list of instrument
       * @return {btSymbolObject[]}
       */
      function search(instrumentList) {
        var data = instrumentList.filter(function (item) {
          return (
            item.name.toLowerCase().indexOf(text.toLowerCase()) !== -1 ||
            item.displayName.toLowerCase().indexOf(text.toLowerCase()) !== -1
          );
        });

        if (data.length > limit) {
          data = data.slice(0, limit);
        }

        return data.map(function (item) {
          return {
            ticker: item.name,
            name: item.name,
            desc: item.type + ' ' + item.displayName,
            rawData: item,
          };
        });
      }
    }

    /**
     * Get quotes for list of symbols
     *
     * @param {String[]} symbols -
     * @return {angular.IPromise<ecapp.ITradingQuoteResponse>}
     */
    function getQuotes(symbols) {
      var deferred = $q.defer();
      var params = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: gAuthorization,
          'Accept-Datetime-Format': 'UNIX',
        },
        params: {
          instruments: symbols.join(','),
          // since: '',
          includeUnitsAvailable: false,
          includeHomeConversions: false,
        },
      };
      $http
        .get(gOandaUrl + '/accounts/' + getSelectedAccountId() + '/pricing', params)
        .then(function (response) {
          var data = response.data;
          deferred.resolve(data);
        })
        .catch(function (error) {
          deferred.reject(_getError(error));
        });
      return deferred.promise;
    }

    /**
     * Stream snapshot for selected symbol
     *
     * @param {String} symbol - symbol name
     * @param {Function} onProgress - function to call on progress
     * @return {angular.IPromise<*>} - return object to terminate streaming
     */
    function streamQuote(symbol, onProgress) {
      void symbol;
      void onProgress;
      var deferred = $q.defer();
      deferred.reject(new Error('Coming soon!'));
      return deferred.promise;
    }

    /**
     * Get snapshots for list of symbols
     *
     * @param {String[]} symbols
     * @param {Object} range
     * @param {Number} interval -
     * @param {String} unit -
     * @return {angular.IPromise<Array>}
     */
    function getSnapshots(symbols, range, interval, unit) {
      void symbols;
      void range;
      void interval;
      void unit;
      var deferred = $q.defer();
      deferred.reject(new Error('Coming soon!'));
      return deferred.promise;
    }

    /**
     * Stream snapshot for selected symbol
     *
     * @param {string} ticker - ticker
     * @param {string} granularity - granularity
     * @param {number} back - candles back
     * @param {(bar: ecapp.ITradingCandle) => void} onUpdate - function to call on progress
     * @return {angular.IPromise<{stop: () => void}>} - return object to terminate streaming
     */
    function streamSnapshot(ticker, granularity, back, onUpdate) {
      console.log('streamSnapshot', ticker, granularity, back, onUpdate);
      var deferred = $q.defer();
      deferred.reject(new Error('Coming soon!'));
      return deferred.promise;
    }

    /* --- Order execution --- */
    /**
     * Confirm order
     *
     * @param {ecapp.ITradingOrderRequest} order - order request data
     * @return {angular.IPromise<ecapp.ITradingOrderConfirmation>}
     */
    function confirmOrder(order) {
      void order;
      var deferred = $q.defer();
      deferred.reject(new Error('Coming soon!'));
      return deferred.promise;
    }

    /**
     * Submit order
     *
     * @param {ecapp.ITradingOrderRequest} order - order request data
     * @return {angular.IPromise<ecapp.ITradingOrderResponse>}
     */
    function submitOrder(order) {
      var deferred = $q.defer();

      if (getSelectedAccountId() === null) {
        deferred.reject(new Error('Select account at first'));
      } else {
        var newOrder = _prepareOrder(order);

        gCtx.order.create(getSelectedAccountId(), { order: newOrder }, function (response) {
          if (_hasError(response)) {
            deferred.reject(_getError(response));
          } else {
            // console.log(response);

            //orderCreateTransaction
            //orderFillTransaction
            //orderCancelTransaction
            if (response.body.orderCancelTransaction) {
              deferred.reject(new Error(response.body.orderCancelTransaction.reason));
            } else {
              // noinspection EqualityComparisonWithCoercionJS
              deferred.resolve({ filled: response.body.orderFillTransaction != null, rawData: response.body });
            }
          }
        });
      }

      return deferred.promise;
    }

    /**
     *
     * @param {ecapp.ITradingOrderRequest} order
     * @return {Object}
     * @private
     */
    function _prepareOrder(order) {
      var actionSign = {
        SELL: -1,
        BUY: 1,
        SELL_SHORT: -1,
        BUY_COVER: 1,
      };

      var newOrder = {
        units: actionSign[order.action] * order.quantity,
        instrument: order.symbol,
        timeInForce: order.timeInForce,
        gtdTime: Math.round(order.gtdTime / 1000).toString(),
        type: order.type.toUpperCase(),
        positionFill: 'DEFAULT',
      };

      if (order.limitPrice) newOrder.price = order.limitPrice.toString();

      if (order.takeProfit) newOrder.takeProfitOnFill = order.takeProfit.toString();

      if (order.stopLoss) newOrder.stopLossOnFill = order.stopLoss.toString();

      return newOrder;
    }

    /**
     * Update order
     *
     * @param {String} orderId - order id
     * @param {Object} orderChanges - changes in order
     * @return {angular.IPromise<ecapp.ITradingOrderResponse>}
     */
    function updateOrder(orderId, orderChanges) {
      void orderId;
      void orderChanges;
      var deferred = $q.defer();
      deferred.reject(new Error('Coming soon!'));
      return deferred.promise;
    }

    /**
     * Cancel order
     *
     * @param {String} orderId - order id
     * @return {angular.IPromise<ecapp.ITradingOrderResponse>}
     */
    function cancelOrder(orderId) {
      var deferred = $q.defer();

      if (getSelectedAccountId() === null) {
        deferred.reject(new Error('Select account at first'));
      } else {
        gCtx.order.cancel(getSelectedAccountId(), orderId, function (response) {
          if (_hasError(response)) {
            deferred.reject(_getError(response));
          } else {
            // console.log(response);

            if (response.body.orderCancelTransaction) {
              deferred.resolve({});
            } else {
              deferred.resolve(new Error(response.body.errorMessage));
            }
          }
        });
      }

      return deferred.promise;
    }

    /**
     * Select account
     *
     * @param {String} id - account id
     * @return {?String} id of selected account or null
     */
    function selectAccount(id) {
      var res = gAccounts.filter(function (item) {
        return item.key === id;
      });

      gSelectedAccountId = res ? res[0].key : null;

      return gSelectedAccountId;
    }

    /**
     *
     * @return {any}
     */
    function getSelectedAccountId() {
      return gSelectedAccountId;
    }

    /**
     *
     * @param {*} id
     * @return {any}
     */
    function isAccountSelected(id) {
      return gSelectedAccountId ? gSelectedAccountId === id : false;
    }

    /**
     *
     * @return {any}
     */
    function getAccessData() {
      var deferred = $q.defer();

      deferred.resolve(gUserData);

      return deferred.promise;
    }

    /**
     *
     * @param {*} symbol
     * @param {*} granularity
     * @param {*} period
     * @return {*}
     */
    function getCandles(symbol, granularity, period) {
      console.log(symbol, granularity, period);
      return $q.reject(new Error('Not Supported'));
    }

    return {
      getCandles: getCandles,
      // getAllInstruments: getAllInstruments,
      getLiveCandleData: getLiveCandleData,
      getLiveCandlesData: getLiveCandlesData,
      getLastCandlesData: getLastCandlesData,
      getEntryPrice: getEntryPrice,

      // trading api
      initialize: initialize,

      connect: connect,
      disconnect: disconnect,

      login: login,
      fastLogin: fastLogin,
      logout: logout,
      signUp: signUp,
      checkUser: checkUser,
      getUsername: getUsername,
      isLoggedIn: isLoggedIn,

      getTradingMode: getTradingMode,
      setTradingMode: setTradingMode,

      getAccounts: getAccounts,
      getBalances: getBalances,
      getPositions: getPositions,
      getOrders: getOrders,

      getSymbolInfo: getSymbolInfo,
      searchSymbol: searchSymbol,
      suggestSymbols: suggestSymbols,
      getQuotes: getQuotes,
      streamQuote: streamQuote,
      getSnapshots: getSnapshots,
      streamSnapshot: streamSnapshot,

      confirmOrder: confirmOrder,
      submitOrder: submitOrder,
      updateOrder: updateOrder,
      cancelOrder: cancelOrder,

      selectAccount: selectAccount,
      isAccountSelected: isAccountSelected,
      getSelectedAccountId: getSelectedAccountId,

      getAccessData: getAccessData,
    };
  }
})();
