/**
 * Created by Sergey Panpurin on 5/25/22.
 */

(function btAssessmentsWidgetClosure() {
  'use strict';

  var gDebug = false;
  var gPrefix = 'btAssessmentsWidget:';

  angular
    .module('ecapp')
    /**
     * Description
     *
     * @ngdoc component
     * @name btAssessmentsWidget
     * @memberOf ecapp
     * @todo
     */
    .component('btAssessmentsWidget', {
      templateUrl: 'directives/barometer/bt-assessments-widget.html',
      controller: btAssessmentsWidgetController,
      controllerAs: 'vm',
    });

  btAssessmentsWidgetController.$inject = ['$timeout', 'btLinkDataService'];

  /**
   * @param {angular.ITimeoutService} $timeout
   * @param {ecapp.ILinkDataService} btLinkDataService
   */
  function btAssessmentsWidgetController($timeout, btLinkDataService) {
    if (gDebug) console.log(gPrefix, 'Controller');

    /*jshint validthis: true*/
    var vm = this;

    vm.isLoading = true;
    vm.error = '';
    vm.groups = [];
    vm.created = 'N/A';
    vm.start = 'N/A';
    vm.end = 'N/A';
    vm.today = 'N/A';

    /**
     *
     */
    vm.$onInit = function () {
      if (gDebug) console.log(gPrefix, 'Initializing...');

      getDailyTable();
    };

    /**
     *
     */
    function getDailyTable() {
      vm.isLoading = true;
      vm.error = '';

      btLinkDataService
        .getDailyTable()
        .then(function (table) {
          vm.created = table.created;
          vm.start = table.start;
          vm.end = table.end;
          vm.today = table.today;
          vm.groups = table.data;
          vm.isLoading = false;
        })
        .catch(function (err) {
          console.error(err);
          vm.error = err.message || 'Unable to load transaction due to unknown problem';
          vm.isLoading = false;
        });
    }

    /**
     *
     */
    vm.$onDestroy = function () {
      if (gDebug) console.log(gPrefix, 'Destroying...');
    };
  }
})();
