/**
 * Created by Sergey Panpurin on 5/3/2017.
 */

// @ts-check
(function btTradingServiceClosure() {
  'use strict';

  var gDebug = false;
  var gPrefix = 'btTradingService:';
  var gHasAutoRefresh = true;

  angular.module('ecapp').factory('btTradingService', btTradingService);

  btTradingService.$inject = [
    'BT',
    '$q',
    '$rootScope',
    '$ionicPopup',
    '$state',
    'btAudioService',
    'btInstrumentsService',
    'btToastrService',
    '$timeout',
    'btShareScopeService',
    '$interval',
    'btSettings',
    '$analytics',
    '$templateCache',
    'btSubscriptionService',
    'btBrokersService',
    'btEventEmitterService',
    'btSettingsService',
    'btRestrictionService',
    'CacheFactory',
    'btPriceService',
    'btErrorService',
  ];

  /**
   *  This ???
   *
   *  New ideas:
   *  1. During startup after user login we load broker data.
   *  2. At start we have default broker.
   *  3. If you cancel token refresh you will be switch to default broker.
   *  4. After initialization only connect and disconnect functions can change broker.
   *  5. After connect we get accounts and positions information immediately.
   *  6. We can update position information in background every 5 seconds.
   *  7. We can auto update
   *
   * Default broker can be connected, but can't be selected.
   *
   * Try to remove $ionicPopup, $stat. They are only connected to select broker menu.
   *
   * ### Price vs Quote
   *
   * There are two concepts connected to instrument price in application. First one is a concept of current price of
   * instrument (bid, ask and last). It's called price quote. Another one is a price references of instrument (yesterday
   * close, today open, today low and high). It's called price reference.
   *
   * Price quotes should be updated all the time. Part of price references is constant (yesterday close, today open),
   * but part of them is variable (today low and high) and should be updated. Price quote can't be used to update price
   * references due to missing so data.
   *
   * ### Events
   *
   * This service broadcast two events on root scope: `broker:connected` and `broker:disconnected`.
   *
   *  broker:connected - sent after success broker connection but before preload of trading data
   *  broker:disconnected - sent after success broker disconnection
   *
   * ### Workflow
   *
   * Service Initialization
   * Broker Selection
   * Broker Connection
   * Broker Disconnection
   * Default Broker Connection
   *
   * First step is a service initialization (`initialize`). This function try to get broker settings from user account.
   * If user already logged in to some broker account, he will have access information in his account.
   *
   * Broker disconnection will return default broker and connect it.
   *
   * @ngdoc service
   * @name btTradingService
   * @memberOf ecapp
   * @param {ecapp.IBTConstants} BT
   * @param {angular.IQService} $q
   * @param {ecapp.ICustomRootScope} $rootScope
   * @param {ionic.IPopupService} $ionicPopup
   * @param {angular.ui.IStateService} $state
   * @param {ecapp.IAudioService} btAudioService
   * @param {ecapp.IInstrumentsService} btInstrumentsService
   * @param {ecapp.IToastrService} btToastrService
   * @param {angular.ITimeoutService} $timeout
   * @param {ecapp.IShareScopeService} btShareScopeService
   * @param {angular.IIntervalService} $interval
   * @param {ecapp.ISettings} btSettings
   * @param {ext.IAnalyticsService} $analytics
   * @param {angular.ITemplateCacheService} $templateCache
   * @param {ecapp.ISubscriptionService} btSubscriptionService
   * @param {ecapp.IBrokersService} btBrokersService
   * @param {ecapp.IEventEmitterService} btEventEmitterService
   * @param {ecapp.ISettingsService} btSettingsService
   * @param {ecapp.IRestrictionService} btRestrictionService
   * @param {ext.ICacheFactoryService} $CacheFactory
   * @param {ecapp.IPriceService} btPriceService
   * @param {ecapp.IErrorService} btErrorService
   * @return {ecapp.ITradingService}
   */
  function btTradingService(
    BT,
    $q,
    $rootScope,
    $ionicPopup,
    $state,
    btAudioService,
    btInstrumentsService,
    btToastrService,
    $timeout,
    btShareScopeService,
    $interval,
    btSettings,
    $analytics,
    $templateCache,
    btSubscriptionService,
    btBrokersService,
    btEventEmitterService,
    btSettingsService,
    btRestrictionService,
    $CacheFactory,
    btPriceService,
    btErrorService
  ) {
    console.log('Running btTradingService');

    /**
     * Information about supported brokers and reference broker trading service
     * @type {Record<string, ecapp.IBrokerInfo>}
     */
    var gSupportedBrokers = btBrokersService.getBrokers();

    // var gIsInitialized = false;
    // var gInitializationPromises = [];

    var gInitializationPromise = null;
    var gConnectionPromise = null;

    var gDefaultTradeSize = null;

    /**
     * User trading accounts.
     * @type {ecapp.ITradingAccount[]}
     */
    var gLastAccounts = [];

    /**
     * Balance of selected trading account. Actually it will be just one value.
     * Array is used to keep same update logic.
     * @type {ecapp.ITradingBalance[]}
     */
    var gLastBalances = [];

    /**
     * Positions connected to selected user account.
     * @type {ecapp.ITradingPosition[]}
     */
    var gLastPositions = [];

    /**
     * Orders connected to selected user account.
     * @type {ecapp.ITradingOrder[]}
     */
    var gLastOrders = [];

    var gRateData = {};

    /** @type {ecapp.IBrokerInfo} */
    var gBroker = _defaultBroker();

    /**
     * This object contains information about brokerage. It can be used for dynamic linking.
     *
     * @type {ecapp.IBrokerObject}
     */
    var gBrokerObject = {
      isConnected: false,
      isDefault: true,
      isTradeable: false,
      hasPastData: true,
    };

    var gIsPractice = false;
    var gReLoginInProcess = false;

    var gOptions = {
      displayMode: {
        regular: 'regular',
        small: 'small',
      },
    };

    var gCache = $CacheFactory('trading', {
      storageMode: 'memory',
      maxAge: 1000,
      deleteOnExpire: 'passive',
      storagePrefix: 'bt.caches.',
    });

    var gAccountRefresher = null;
    var gPricesRefresher = null;
    var gQuotesRefresher = null;

    /** @type {ecapp.IPriceCache} */
    var gPriceCache = {};
    window.btStore.priceCache = gPriceCache;

    // Add access for testing
    // window.btPriceCache = gPriceCache;

    /**
     * @typedef {Object} btTradingSettings
     * @property {Object} refresh - intervals for refreshing trading data
     * @property {number} refresh.account - interval for refreshing account details: balance, positions, orders
     * @property {number} refresh.reference - interval for refreshing price references of cached instruments
     * @property {number} refresh.quote - interval for refreshing price quotes of cached instruments
     * @property {Object} tradestation - ???
     * @property {boolean} tradestation.enabled - ???
     */

    /**
     *
     * @type {btTradingSettings}
     */
    var gTradingSettings = {
      refresh: {
        account: 4000,
        quote: 2000,
        reference: 20000,
      },
      tradestation: {
        enabled: false,
      },
    };

    var gRequestsType = {
      getAccounts: 'general',
      getSymbolInfo: 'general',
      searchSymbol: 'general',
      suggestSymbols: 'general',

      getOptionExpirations: 'general',
      getOptionStrikes: 'general',

      getBalances: 'trading',
      getPositions: 'trading',
      getOrders: 'trading',
      confirmOrder: 'trading',
      submitOrder: 'trading',
      updateOrder: 'trading',
      cancelOrder: 'trading',

      getQuotes: 'prices',
      streamQuote: 'prices',
      getSnapshots: 'prices',
      streamSnapshot: 'prices',

      getCandles: 'prices',
      getLastCandlesData: 'prices',
      getLiveCandleData: 'prices',
      getLiveCandlesData: 'prices',
    };

    var gWatchlist = [];
    window.btStore.watchedInstruments = gWatchlist;

    btEventEmitterService.addListener('login:success', onLoginSuccess);
    btEventEmitterService.addListener('logout:success', onLogoutSuccess);

    return {
      initialize: initialize,

      connect: connect,
      disconnect: disconnect,
      isConnected: isConnected,
      isDefaultBroker: isDefaultBroker,

      login: login,
      fastLogin: fastLogin,
      signUp: signUp,
      checkUser: checkUser,
      getUsername: getUsername,

      getUserInfo: getUserInfo,
      getBrokerObject: getBrokerObject,
      getBrokerInfo: getBrokerInfo,
      getBrokerName: getBrokerName,
      getSupportedBrokers: getSupportedBrokers,
      isBrokerSupported: isBrokerSupported,

      showSelectBrokerMenu: showSelectBrokerMenu,

      getLimit: getLimit,

      // Trading interface
      getTradingMode: getTradingMode,
      setTradingMode: setTradingMode,

      getAccounts: getAccounts,
      getLastAccounts: getLastAccounts, // Not in use
      getBalances: getBalances,
      getLastBalances: getLastBalances, // Not in use
      getPositions: getPositions,
      getLastPositions: getLastPositions,
      getOrders: getOrders,
      getLastOrders: getLastOrders,

      getSymbolInfo: getSymbolInfo,
      searchSymbol: searchSymbol, // Not in use
      suggestSymbols: suggestSymbols,
      getOptionExpirations: getOptionExpirations,
      getOptionStrikes: getOptionStrikes,

      getQuotes: getQuotes, // Just internal use for update quotes
      streamQuote: streamQuote, // Not in use
      getSnapshots: getSnapshots, // Not in use
      streamSnapshot: streamSnapshot, // Not in use

      processOrder: processOrder,
      confirmOrder: confirmOrder,
      submitOrder: submitOrder,
      updateOrder: updateOrder,
      cancelOrder: cancelOrder,
      closePosition: closePosition,
      closeAllPositions: closeAllPositions,

      getCandles: getCandles,
      getLastCandlesData: getLastCandlesData,
      getLiveCandleData: getLiveCandleData,
      getLiveCandlesData: getLiveCandlesData,

      // getBrokerSymbol: getBrokerSymbol,
      getInstrumentByBrokerSymbol: getInstrumentByBrokerSymbol,
      loadInstrumentByTicker: loadInstrumentByTicker,
      getWatchedInstruments: getWatchedInstruments,

      getCharts: getCharts,
      setCharts: setCharts,

      // hasBrokerSymbol: hasBrokerSymbol,

      setDefaultTradeSize: setDefaultTradeSize,
      getDefaultTradeSize: getDefaultTradeSize,

      hasPositions: hasPositions,
      hasOrders: hasOrders,

      selectAccount: selectAccount,
      isAccountSelected: isAccountSelected,
      getSelectedAccountId: getSelectedAccountId,
      getSelectedAccount: getSelectedAccount,

      parseAccountList: parseAccountList,

      isPractice: isPractice,
      isRealTrading: isRealTrading,
      hasPracticeAccount: hasPracticeAccount,
      hasBrokerLogin: hasBrokerLogin,
      getDefaultDisplayOptions: getDefaultDisplayOptions,

      // getPriceObject: getPriceObject,
      enablePriceUpdates: enablePriceUpdates,
      disablePriceUpdates: disablePriceUpdates,
      changeMonthOption: changeMonthOption,
    };

    /**
     * This function starts automatic refreshing of trading information.
     */
    function startAutoRefresh() {
      var interval = gTradingSettings.refresh;

      if (window.btStore) {
        window.btStore.MarketDataUpdateIntervals = {
          account: interval.account,
          quote: interval.quote,
          reference: interval.reference,
        };
      }

      if (gHasAutoRefresh) {
        if (gAccountRefresher === null && interval.account > 0) {
          gAccountRefresher = $interval(function () {
            updateAccount();
          }, interval.account);
        }

        if (gQuotesRefresher === null && interval.quote > 0) {
          gQuotesRefresher = $interval(function () {
            updateQuotes();
          }, interval.quote);
        }

        if (gPricesRefresher === null && interval.reference > 0) {
          gPricesRefresher = $interval(function () {
            updatePrices();
          }, interval.reference);
        }
      }

      updateAccount();
      updateQuotes();
      updatePrices();
    }

    /**
     * This function updates details of selected account: balance, positions and orders.
     */
    function updateAccount() {
      if (gDebug) console.log(gPrefix, 'interval was called (updateAccount).');

      if (gBroker.name === 'default' || gBroker.name === 'ctrader') {
        if (gDebug) console.log(gPrefix, 'auto update was skipped for ' + gBroker.name + ' broker.');
      } else if (!btSettingsService.hasFeature('trading')) {
        if (gDebug) console.log(gPrefix, 'auto update of balance, positions and orders is not supported.');
      } else {
        if (gDebug) console.log(gPrefix, 'get positions and orders automatically.');
        var account = getSelectedAccountId();
        if (account !== null) {
          getBalances([account])
            .then(function () {
              if (gDebug) console.log(gPrefix, 'balance of selected account was updated.');
            })
            .catch(function (reason) {
              console.error(gPrefix, 'positions auto update was failed.', reason);
            });

          getPositions([account])
            .then(function () {
              if (gDebug) console.log(gPrefix, 'positions for selected account was updated.');
            })
            .catch(function (reason) {
              console.error(gPrefix, 'positions auto update was failed.', reason);
            });

          getOrders([account])
            .then(function () {
              if (gDebug) console.log(gPrefix, 'orders for selected account was updated.');
            })
            .catch(function (reason) {
              console.error(gPrefix, 'orders auto update was failed.', reason);
            });
        } else {
          if (gDebug) console.log(gPrefix, 'account is not selected');
        }
      }
    }

    /**
     * This function updates instruments prices.
     * See the difference between price and quote above.
     */
    function updatePrices() {
      if (gDebug) console.log(gPrefix, 'updatePrices');

      if (gDebug) console.log(gPrefix, 'interval was called (updatePrices).', new Date());

      if (!isConnected()) return;

      if (!btSettingsService.hasFeature('prices')) return;

      if (gBroker.name === 'ctrader') {
        if (gDebug) console.log(gPrefix, 'auto update of prices was skipped for ctrader broker.');
        return;
      }

      // Update only the instruments currently in use.
      var symbols = Object.keys(gPriceCache).filter(function (symbol) {
        return gPriceCache[symbol].links > 0;
      });

      if (gDebug) console.log(gPrefix, 'active references:', symbols.length, 'of', Object.keys(gPriceCache).length);

      updateInstrumentPrices(symbols)
        .then(function () {
          if (gDebug) console.log(gPrefix, 'prices were updated automatically', new Date());
        })
        .catch(function (reason) {
          console.error(gPrefix, reason);
          if (gDebug) console.log(gPrefix, 'prices were not updated due to error');
        });
    }

    /**
     * This function updates price of a instrument.
     *
     * @param {string} symbol - instrument symbol
     * @return {angular.IPromise<unknown>}
     */
    function updateInstrumentPrice(symbol) {
      if (!btSettingsService.hasFeature('prices')) {
        if (gDebug) console.log(gPrefix, 'Can not update ' + symbol + ' price due this feature is disabled.');
        return $q.resolve();
      }

      if (!gPriceCache[symbol]) {
        if (gDebug) console.log(gPrefix, 'Can not update ' + symbol + ' price due no cache.');
      }

      if (!isConnected()) {
        if (gDebug) console.log(gPrefix, 'Can not update ' + symbol + ' price due service is not connected.');
        return $q.resolve();
      }

      return getLiveCandleData(symbol)
        .then(_updatePriceObject)
        .catch(function (reason) {
          console.error(gPrefix, reason);
          return $q.reject(reason);
        });
    }

    /**
     * Updates price object.
     *
     * @param {ecapp.ITradingLiveCandle} value - ?
     */
    function _updatePriceObject(value) {
      btPriceService.updateComplexPriceObject(
        gPriceCache[value.symbol],
        null,
        value.yesterday.closeText,
        value.today.openText,
        value.today.lowText,
        value.today.highText,
        gPriceCache[value.symbol].count === 0 ? value.now.bidText : null,
        gPriceCache[value.symbol].count === 0 ? value.now.askText : null,
        gPriceCache[value.symbol].count === 0 ? value.now.lastText : null,
        null,
        null
      );
      gPriceCache[value.symbol].count++;
      if (gDebug)
        console.log(gPrefix, 'Price of ' + value.symbol + ' (' + gPriceCache[value.symbol].links + ') was updated.');
    }

    /**
     * This function updates price of specified instruments.
     *
     * @param {string[]} symbols - list of symbols
     * @return {angular.IPromise<unknown>}
     */
    function updateInstrumentPrices(symbols) {
      if (gDebug) console.log('>>> Update Instrument Prices');

      if (!btSettingsService.hasFeature('prices')) {
        if (gDebug) console.log(gPrefix, 'Can not update prices due this feature is disabled.');
        return $q.resolve();
      }

      if (!isConnected()) {
        if (gDebug) console.log(gPrefix, 'Can not update prices due service is not connected.');
        return $q.resolve();
      }

      if (!symbols.length) {
        return $q.resolve();
      }

      symbols.forEach(function (symbol) {
        if (!gPriceCache[symbol]) {
          if (gDebug) console.log(gPrefix, 'Can not update ' + symbol + ' price due no cache.');
        }
      });

      if (gDebug) console.log(gPrefix, 'Update Instrument Prices', symbols);

      return getLiveCandlesData(symbols)
        .then(function (values) {
          values.forEach(_updatePriceObject);
        })
        .catch(function (reason) {
          console.error(gPrefix, reason);
          return $q.reject(reason);
        });
    }

    /**
     * This function updates instruments quotes.
     * See the difference between price and quote above.
     */
    function updateQuotes() {
      if (gDebug) console.log('>>> Update Quotes');

      if (gDebug) console.log(gPrefix, 'interval was called (updateQuotes).', new Date());

      if (!isConnected()) return;

      if (!btSettingsService.hasFeature('prices')) return;

      if (gBroker.name === 'ctrader') {
        if (gDebug) console.log(gPrefix, 'auto update of prices was skipped for ctrader broker.');
        return;
      }

      var symbols = Object.keys(gPriceCache).filter(function (symbol) {
        return gPriceCache[symbol].links > 0 && gPriceCache[symbol].count !== 0;
      });

      if (gDebug) console.log(gPrefix, 'active quotes:', symbols.length, 'of', Object.keys(gPriceCache).length);

      if (symbols.length) {
        // console.log('>>> Update Quotes');

        getQuotes(symbols)
          .then(processQuotes)
          .then(function () {
            if (gDebug) console.log(gPrefix, 'quotes were updated automatically', new Date());
          })
          .catch(function (reason) {
            console.error(gPrefix, reason);
            if (gDebug) console.log(gPrefix, 'quotes were not updated due to error');
          });
      }

      /**
       * This function processes price quotes.
       *
       * @param {{ prices: ecapp.ITradingQuote[]; time: number } | ecapp.ITradingQuoteResponse} response
       */
      function processQuotes(response) {
        response.prices.forEach(function (price) {
          if (!price || !gPriceCache[price.instrument]) return;

          gPriceCache[price.instrument].count++;

          if (!price.tradeable && gPriceCache[price.instrument].now.last.value !== 0) {
            // Skip updates for non-tradeable instruments due to somethings OANDA change price for closed markets.
            return;
          }

          btPriceService.updateComplexPriceObjectQuotes(
            gPriceCache[price.instrument],
            null,
            price.last,
            price.bids[0] ? price.bids[0].price : null,
            price.asks[0] ? price.asks[0].price : null,
            price.bids ? parseQuoteBucket(price.bids) : undefined,
            price.asks ? parseQuoteBucket(price.asks) : undefined
          );
        });
      }
    }

    /**
     *
     * @param {ecapp.ITradingQuoteBucket[]} items
     * @return {ecapp.IPriceQuote[]}
     */
    function parseQuoteBucket(items) {
      return items.map(function (item) {
        return {
          value: parseFloat(item.price),
          text: item.price,
          liquidity: item.liquidity.toString(),
        };
      });
    }

    /**
     * This function stop auto refreshing of trading information.
     */
    function stopAutoRefresh() {
      if (gAccountRefresher !== null) {
        $interval.cancel(gAccountRefresher);
        gAccountRefresher = null;
      }

      if (gPricesRefresher !== null) {
        $interval.cancel(gPricesRefresher);
        gPricesRefresher = null;
      }

      if (gQuotesRefresher !== null) {
        $interval.cancel(gQuotesRefresher);
        gQuotesRefresher = null;
      }
    }

    /**
     * Initializes trading service. Try to load broker data and initialize broker. If broker was connected, data
     * about this was saved in local storage. Initialization code will be run only ONCE.
     *
     * @alias ecapp.btTradingService#initialize
     * @return {angular.IPromise<null>}
     */
    function initialize() {
      if (gInitializationPromise) {
        return gInitializationPromise;
      }

      // if service is not initialized and it is first call - start initialization process
      if (gDebug) console.log(gPrefix, 'broker data loading...');

      gInitializationPromise = _loadBrokerData()
        .then(function () {
          if (gDebug) console.log(gPrefix, 'broker data has been loaded');
          return initializeBrokerService();
        })
        .then(getApplicationSettings)
        .catch(function (reason) {
          console.error(gPrefix, 'initialization.', reason);
          return $q.reject(reason);
        })
        .finally(function () {
          return btInstrumentsService.init().then(function () {
            btInstrumentsService.addBrokerSymbols(gBroker.name);
          });
        });

      return gInitializationPromise;
    }

    /**
     * Initializes broker service.
     *
     * @return {angular.IPromise<*>}
     */
    function initializeBrokerService() {
      if (!_isBrokerSelected()) {
        if (gDebug) console.log(gPrefix, 'broker is not selected - fail');
        return $q.reject(new Error('Broker is not selected'));
      }

      if (gDebug) console.log(gPrefix, 'initializing broker service...');

      return gBroker.service
        .initialize(gBroker.data, _saveBrokerData)
        .then(function (data) {
          void data;
          if (gDebug) console.log(gPrefix, 'broker initialized');
          return gBroker.service.getAccessData().then(function (data) {
            gBroker.data = data;
            return _saveBrokerData(gBroker.data);
          });
        })
        .catch(function (error) {
          console.error(gPrefix, error);
          if (gDebug) console.log(gPrefix, 'broker initialization failed');
          if (gDebug) console.log(gPrefix, 'switch to default');
          return _deleteBrokerData();
        });
    }

    /**
     * This function gets application settings for trading.
     *
     * @return {angular.IPromise<*>}
     */
    function getApplicationSettings() {
      if (btSettingsService.isLinkDataService()) {
        gTradingSettings = {
          refresh: {
            account: 1 * 60 * 1000,
            quote: 1 * 60 * 1000,
            reference: 1 * 60 * 1000,
          },
          tradestation: { enabled: false },
        };

        return $q.resolve();
      }

      return btShareScopeService
        .getAppSettings('trading')
        .then(function (result) {
          try {
            gTradingSettings = angular.extend(gTradingSettings, result);
            gSupportedBrokers['tradestation'].visible = gTradingSettings.tradestation.enabled;
            if (gDebug) console.log(gPrefix, 'settings were loaded');
          } catch (error) {
            return $q.reject(error);
          }
        })
        .catch(function (reason) {
          console.error(gPrefix, reason);
        });
    }

    // Begin Broker functions
    /**
     * Return default broker object
     *
     * @return {ecapp.IBrokerInfo}
     * @private
     */
    function _defaultBroker() {
      return gSupportedBrokers['default'];
    }

    /**
     * This function sets defaults settings.
     *
     * @private
     */
    function _setDefaults() {
      // gIsInitialized = false;
      // gInitializationPromises = [];
      gInitializationPromise = null;
      gConnectionPromise = null;
      gDefaultTradeSize = null;
      clearLastData(gLastAccounts);
      clearLastData(gLastBalances);
      clearLastData(gLastPositions);
      clearLastData(gLastOrders);

      Object.keys(gPriceCache).forEach(function (symbol) {
        delete gPriceCache[symbol];
      });

      gRateData = {};
      gBroker = _defaultBroker();
      gIsPractice = false;
      gReLoginInProcess = false;
      btSubscriptionService.setRealTradingBenefits(false);
      btSubscriptionService.updateStatus();
    }

    /**
     * This function checks whether broker is supported.
     *
     * @alias ecapp.btTradingService#isBrokerSupported
     * @param {string} brokerId - broker id
     * @return {boolean}
     */
    function isBrokerSupported(brokerId) {
      return gSupportedBrokers[brokerId] !== undefined;
    }

    /**
     * This function checks whether broker is connected.
     *
     * @alias ecapp.btTradingService#isConnected
     * @return {boolean}
     */
    function isConnected() {
      if (window.isTradeStation || navigator.userAgent.match(/(MSIE)+/)) {
        return false;
      } else {
        return gBroker.status === 'connected';
      }
    }

    /**
     * This function checks whether current broker is default.
     *
     * @alias ecapp.btTradingService#isDefaultBroker
     * @return {boolean} - default broker
     */
    function isDefaultBroker() {
      return gBroker.name === 'default';
    }

    /**
     * This function checks whether current broker is tradeable.
     *
     * @return {boolean} - whether broker is tradeable
     */
    function _isTradeableBroker() {
      return isConnected() && !isDefaultBroker();
    }

    /**
     * This functions checks whether some broker is selected.
     * @return {boolean} - broker is selected
     * @private
     */
    function _isBrokerSelected() {
      return gBroker.service !== undefined;
    }

    /**
     * This function returns object with information about supported brokers (key = broker's id)
     * @alias ecapp.btTradingService#getSupportedBrokers
     * @return {Record<string, ecapp.IBrokerInfo>}
     */
    function getSupportedBrokers() {
      // if (!btShareScopeService.isInitialized()) {
      //   console.error('User is not initialized');
      //   throw new Error('User is not initialized');
      // }
      //
      // var location = btShareScopeService.getAccountInfoField('location');
      // if (location.country === 'ZA') {
      //   return  gSupportedBrokers.filter(function (broker) {
      //     return broker.id === 'ib';
      //   })
      // }

      return gSupportedBrokers;
    }

    /**
     * This function returns information about current broker.
     *
     * @alias ecapp.btTradingService#getBrokerObject
     * @return {ecapp.IBrokerObject} - broker object
     */
    function getBrokerObject() {
      return gBrokerObject;
    }

    /**
     * This function updates broker object.
     *
     * @private
     */
    function _updateBrokerObject() {
      gBrokerObject.isConnected = isConnected();
      gBrokerObject.isDefault = isDefaultBroker();
      gBrokerObject.isTradeable = _isTradeableBroker();
      gBrokerObject.hasPastData = getBrokerName() !== 'ctrader';
    }

    /**
     * Returns information about connected broker or empty object
     *
     * @alias ecapp.btTradingService#getBrokerInfo
     * @return {ecapp.IBrokerInfo} - information about broker
     */
    function getBrokerInfo() {
      return gBroker;
    }

    /**
     * Returns broker name.
     *
     * @alias ecapp.btTradingService#getBrokerName
     * @return {string} - broker name
     */
    function getBrokerName() {
      return gBroker.name;
    }

    /**
     * Returns information about user or empty object.
     *
     * @alias ecapp.btTradingService#getUserInfo
     * @return {Object} - information about user
     */
    function getUserInfo() {
      return { name: gBroker.user } || {};
    }

    // End - Broker functions

    // Begin - General Trading Interface
    /**
     * Connects to selected broker. Auth
     *
     * @alias ecapp.btTradingService#connect
     * @return {angular.IPromise<Object>}
     */
    function connect() {
      if (gConnectionPromise) {
        return gConnectionPromise;
      } else {
        if (gDebug) console.log(gPrefix, 'try to connect broker');

        if (!_isBrokerSelected()) {
          if (gDebug) console.log(gPrefix, 'broker is not selected');
          return $q.reject(new Error('Broker is not selected'));
        }

        // gIsPractice = false;
        gBroker.status = 'connecting';
        if (gDebug) console.log(gPrefix, 'selected broker - ' + gBroker.name);

        // connect broker api
        gConnectionPromise = gBroker.service.connect().then(onBrokerConnectionSuccess).catch(onBrokerConnectionFail);

        return gConnectionPromise;
      }

      /**
       *
       * @param {*} data
       * @return {angular.IPromise<*>}
       */
      function onBrokerConnectionSuccess(data) {
        if (gDebug) console.log(gPrefix, 'broker connected');

        gBroker.user = data.userId;
        gBroker.status = 'connected';
        gBroker.tradingMode = gBroker.service.getTradingMode();

        $rootScope.isTradeStation = gBroker.name === 'tradestation';
        if ($rootScope.isTradeStation) $rootScope.hasAdBanner = false;

        return _preloadWatchlist()
          .then(function () {
            btInstrumentsService.addBrokerSymbols(gBroker.name);

            btSubscriptionService.setRealTradingBenefits(isRealTrading());
            btSubscriptionService.updateStatus();
            $rootScope.supportOrders = gBroker.support.orders;
            $rootScope.supportPositions = gBroker.support.positions;
            $rootScope.supportBidAndAsk = gBroker.support.bidAndAsk;
            $rootScope.supportTrading = gBroker.support.trading;

            _updateBrokerObject();

            if (gDebug) console.log(gPrefix, 'broadcast broker:connected');
            $rootScope.$broadcast('broker:connected', gBroker.name);
            return getAccounts();
          })
          .then(parseAccountList)
          .then(preloadDetails)
          .then(function () {
            startAutoRefresh();
            return gBroker;
          });
      }

      /**
       *
       * @return {*}
       */
      function preloadDetails() {
        var promises = [];

        if (btSettingsService.hasFeature('trading') && !isDefaultBroker()) {
          var account = getSelectedAccountId();
          if (account !== null) {
            promises.push(getPositions([account]));
            promises.push(getBalances([account]));
            promises.push(getOrders([account]));
          }
        }

        if (promises.length > 0) {
          return $q
            .all(promises)
            .then(function (results) {
              if (gDebug) console.log(gPrefix, 'preload of details was finished', results.length);
            })
            .catch(function (reason) {
              console.error(reason);
            });
        }
      }

      /**
       *
       * @param {*} reason
       * @return {*}
       */
      function onBrokerConnectionFail(reason) {
        console.error(gPrefix, 'failed to connect broker.', reason);
        gBroker.status = 'connection failed';

        $rootScope.isTradeStation = gBroker.name === 'tradestation';
        if ($rootScope.isTradeStation) $rootScope.hasAdBanner = false;

        $timeout(function () {
          gConnectionPromise = null;
        }, 500);

        return $q.reject(reason);
      }
    }

    /**
     * This function disconnects current broker and connect default broker.
     *
     * Remove access data from database. At next time user will need to enter login and password again.
     *
     * @alias ecapp.btTradingService#disconnect
     * @param {boolean} [force] - force disconnect
     * @return {angular.IPromise<*>}
     */
    function disconnect(force) {
      if (gDebug) console.log(gPrefix, 'disconnect');

      if (isConnected() || force) {
        return gBroker.service
          .disconnect()
          .then(_deleteBrokerData)
          .then(function () {
            _updateBrokerObject();

            $rootScope.isTradeStation = gBroker.name === 'tradestation';
            if ($rootScope.isTradeStation) $rootScope.hasAdBanner = false;

            if (gDebug) console.log(gPrefix, 'broadcast broker:disconnected');
            $rootScope.$broadcast('broker:disconnected');
            stopAutoRefresh();
          })
          .then(function () {
            // Connect to default broker.
            return connect();
          })
          .catch(function (error) {
            console.error(gPrefix, 'disconnect.', error);
          });
      } else {
        if (gDebug) console.log(gPrefix, 'broker is not connected');
        return $q.resolve();
      }
    }

    /**
     * Is user connected to practice trading (OANDA account created via BetterTrader app)
     * @return {boolean}
     */
    function isPractice() {
      return gIsPractice;
    }

    /**
     * Does user had practice account (OANDA account created via BetterTrader app)
     * @return {boolean}
     */
    function hasPracticeAccount() {
      return btShareScopeService.hasPracticeAccount();
    }

    /**
     * Is user connected to real trading
     * @return {boolean}
     */
    function isRealTrading() {
      return false;
      // return !isDefaultBroker() && getTradingMode() === 'real' && getBrokerName() !== 'ctrader';
    }

    /**
     * Get trading mode
     *
     * @alias ecapp.btTradingService#getTradingMode
     * @return {?string} - trading mode
     */
    function getTradingMode() {
      if (_isBrokerSelected()) {
        return gBroker.service.getTradingMode();
      } else {
        return null;
      }
    }

    /**
     * Set trading mode
     *
     * @alias ecapp.btTradingService#setTradingMode
     * @param {string} mode - mode
     * @return {boolean|null}
     */
    function setTradingMode(mode) {
      if (_isBrokerSelected()) {
        return gBroker.service.setTradingMode(mode);
      } else {
        return null;
      }
    }

    /* --- User data --- */
    /**
     * Starts login to broker account.
     *
     * @alias ecapp.btTradingService#login
     * @param {string} brokerId - broker id
     * @param {string} mode - trading mode: real or demo
     * @return {angular.IPromise<Array>}
     */
    function login(brokerId, mode) {
      if (gDebug) console.log(gPrefix, 'try to login to ' + brokerId + ' broker');

      if (_selectBroker(brokerId)) {
        return gBroker.service.login(mode, true, _saveBrokerData).then(_successLogin).catch(_failLogin);
      } else {
        return $q.reject(new Error('Broker is not selected'));
      }
    }

    /**
     * Runs fast login to broker account if we already have access data.
     *
     * @alias ecapp.btTradingService#fastLogin
     * @param {string} brokerId - broker id
     * @param {string} mode - trading mode: real or demo
     * @param {ecapp.IBrokerAuthData} accessData - access data
     * @return {angular.IPromise<Array>}
     */
    function fastLogin(brokerId, mode, accessData) {
      if (gDebug) console.log(gPrefix, 'try to login to ' + brokerId + ' broker');

      if (_selectBroker(brokerId)) {
        return gBroker.service.fastLogin(mode, accessData, _saveBrokerData).then(_successLogin).catch(_failLogin);
      } else {
        return $q.reject(new Error('Broker is not selected'));
      }
    }

    /**
     * Handle login successes
     * @param {Object} data -
     * @return {angular.IPromise<*>}
     * @private
     */
    function _successLogin(data) {
      gBroker.data = data;

      // disconnect default broker
      gConnectionPromise = null;
      $rootScope.$broadcast('broker:disconnected');

      Object.keys(gPriceCache).forEach(function (symbol) {
        delete gPriceCache[symbol];
      });

      return _saveBrokerData(gBroker.data).finally(function () {
        if (btRestrictionService.hasFeature('personalization')) {
          btShareScopeService.setMailChimpField('BROKERNAME', gBroker.name);
          btShareScopeService.setMailChimpField('BROKERTIME', new Date().toISOString());
        }
      });
    }

    /**
     * Handles login fails
     *
     * @param {Object} error
     * @return {angular.IPromise<never>}
     * @private
     */
    function _failLogin(error) {
      console.error(gPrefix, "Can't login!", error);
      _selectBroker('default');
      return $q.reject(error);
    }

    /**
     * Preloads information about instruments in the watchlist.
     *
     * @return {angular.IPromise<void>}
     * @private
     */
    function _preloadWatchlist() {
      if (gDebug) console.log(gPrefix, 'Preload watchlist');

      var broker = getBrokerName();
      var watchlist = btShareScopeService.getWatchedInstruments(broker);

      // Set default watchlist for the broker.
      if (watchlist === undefined) {
        if (gDebug) console.log(gPrefix, 'Use default watchlist');
        watchlist = getBrokerInfo().defaultWatchlist;
        btShareScopeService.setWatchedInstruments(watchlist, broker);
        btShareScopeService.updateProfileSettingsFromFieldsList(['watchList']);
      }

      return preloadInstruments(watchlist).then(function (instruments) {
        gWatchlist.splice(0, gWatchlist.length);
        instruments.forEach(function (instrument) {
          gWatchlist.push(instrument);
        });
      });
    }

    /**
     * Preloads instruments if the have ticker.
     *
     * @param {string[]} symbols - watched symbols
     * @return {angular.IPromise<ecapp.ITradingInstrument[]>}
     */
    function preloadInstruments(symbols) {
      // var tickers = symbols.filter(btInstrumentsService.isTicker);
      var tickers = symbols;

      if (gDebug) console.log(gPrefix, 'Preloading', tickers.join(','));

      // Load custom instruments
      var promises = tickers.map(preloadInstrument);

      return $q.all(promises).then(function (instruments) {
        return instruments.filter(function (instrument) {
          return !!instrument;
        });
      });
    }

    /**
     * Preloads instruments if the have ticker.
     *
     * @param {string} ticker - ticker
     * @return {angular.IPromise<ecapp.ITradingInstrument>}
     */
    function preloadInstrument(ticker) {
      return getSymbolInfo(ticker)
        .then(function (instrument) {
          btInstrumentsService.addInstrument(instrument);
          return instrument;
        })
        .catch(function (err) {
          console.error(err);
          return null;
        });
    }

    /**
     * Logout from broker account
     * Run this function on user log out to remove sensitive information from device and stop all routines.
     *
     * @return {angular.IPromise<void>}
     */
    function logout() {
      if (isConnected()) {
        return gBroker.service.logout().then(stopAutoRefresh).finally(_setDefaults);
      } else {
        return $q.reject(new Error('Broker not connected - logout'));
      }
    }

    /**
     * Create new account
     *
     * @alias ecapp.btTradingService#signUp
     * @param {*} userData
     * @return {angular.IPromise<*>}
     */
    function signUp(userData) {
      var deferred = $q.defer();

      if (_isBrokerSelected()) {
        try {
          gBroker.service
            .signUp(userData)
            .then(_saveDemoAccount)
            .then(function (data) {
              deferred.resolve(data);
            })
            .catch(function (error) {
              deferred.reject(error);
            });
        } catch (e) {
          deferred.reject(e);
        }
      } else {
        deferred.reject(new Error('Broker not connected - signUp'));
      }
      return deferred.promise;
    }

    /**
     * Check user data before sign up
     *
     * @alias ecapp.btTradingService#checkUser
     * @param {*} userData
     * @return {angular.IPromise<*>}
     */
    function checkUser(userData) {
      var deferred = $q.defer();

      if (_isBrokerSelected()) {
        try {
          gBroker.service
            .checkUser(userData)
            .then(function (data) {
              deferred.resolve(data);
            })
            .catch(function (error) {
              deferred.reject(error);
            });
        } catch (e) {
          deferred.reject(e);
        }
      } else {
        deferred.reject(new Error('Broker not selected - checkUser'));
      }
      return deferred.promise;
    }

    /**
     * Check user data before sign up
     *
     * @alias ecapp.btTradingService#getUsername
     * @param {string} email -
     * @return {angular.IPromise<*>}
     */
    function getUsername(email) {
      var deferred = $q.defer();

      if (_isBrokerSelected()) {
        try {
          gBroker.service
            .getUsername(email)
            .then(function (data) {
              deferred.resolve(data);
            })
            .catch(function (error) {
              deferred.reject(error);
            });
        } catch (e) {
          deferred.reject(e);
        }
      } else {
        deferred.reject(new Error('Broker not selected - getUsername'));
      }
      return deferred.promise;
    }

    /**
     * Function wrapper.
     *
     * @param {string} name - function name
     * @param {{ connect: boolean, default: boolean, demo: boolean }} check - checks
     * @param {*} callback - callback
     * @return {angular.IPromise<*>}
     */
    function wrapper(name, check, callback) {
      _measureRate(name, gRateData);
      _measureRate('total', gRateData);
      var deferred = $q.defer();

      if (!isRequestSupported(name)) {
        deferred.reject(new Error('This request ' + name + ' is not supported.'));
      } else if (check.default && isDefaultBroker()) {
        deferred.reject(new Error('Select broker'));
      } else if (check.connect && !isConnected()) {
        deferred.reject(new Error('Broker not connected - ' + name));
      } else if (check.demo && getTradingMode() !== 'demo' && getBrokerName() !== 'tradestation') {
        deferred.reject(new Error('Live trading is not available'));
      } else {
        try {
          callback()
            .then(function (data) {
              deferred.resolve(data);
            })
            .catch(function (error) {
              if (isReLoginNeeded(error)) {
                _reLogin(deferred);
              } else {
                deferred.reject(error);
              }
            });
        } catch (e) {
          deferred.reject(e);
        }
      }
      return deferred.promise;
    }

    /**
     * This function indicates whether request is supported.
     *
     * @param {string} name - request name
     * @return {boolean}
     */
    function isRequestSupported(name) {
      if (gRequestsType[name] === undefined) {
        console.error('Unknown request');
        return false;
      }

      if (gRequestsType[name] === 'general') {
        return true;
      }

      if (btSettingsService.hasFeature(gRequestsType[name])) {
        return true;
      } else {
        console.error(new Error("This domain didn't support " + gRequestsType[name] + '.'));
        return false;
      }
    }

    /**
     * Wrapper for functions that require service to be connected.
     *
     * @param {string} name - function name
     * @param {any} callback - callback
     * @return {angular.IPromise<*>}
     */
    function checkConnect(name, callback) {
      return wrapper(name, { connect: true, default: false, demo: false }, callback);
    }

    /**
     * Wrapper for functions that require non default broker.
     *
     * @param {string} name - function name
     * @param {any} callback - callback
     * @return {angular.IPromise<*>}
     */
    function checkDefault(name, callback) {
      return wrapper(name, { connect: true, default: true, demo: false }, callback);
    }

    /**
     * Wrapper for functions that require to check for demo account.
     *
     * @param {string} name - function name
     * @param {any} callback - callback
     * @return {angular.IPromise<*>}
     */
    function checkDemo(name, callback) {
      return wrapper(name, { connect: true, default: true, demo: true }, callback);
    }

    /**
     * Checks whether re-login is needed.
     *
     * @param {Error} error - error
     * @return {boolean}
     */
    function isReLoginNeeded(error) {
      if (getBrokerName() === 'oanda') {
        return error.message === 'Insufficient authorization to perform request.';
      } else {
        return error.message === 'Access token expired - try to login again';
      }
    }

    /**
     *
     * @param {angular.IDeferred} deferred
     * @private
     */
    function _reLogin(deferred) {
      if (gReLoginInProcess) {
        deferred.reject(new Error('Re-login in process'));
        return;
      }

      gReLoginInProcess = true;
      var scope = $rootScope.$new(true);

      var confirmPopup = $ionicPopup.confirm({
        title: 'Please log in again',
        okText: 'Log In',
        scope: scope,
        template:
          '<div class="">' +
          'To keep your work secure, your ' +
          getBrokerInfo().displayName +
          ' Brokerage session has timed out. ' +
          'To log back in, please hit <b>Log In</b> below.<br>' +
          '<br>' +
          'We apologize for interrupting your flow. Thanks for helping us keep BetterTrader secure!' +
          '</div>',
      });

      confirmPopup.then(function (res) {
        if (res) {
          return onConfirm();
        } else {
          return onCancel();
        }
      });

      /**
       *
       * @return {angular.IPromise<*>}
       */
      function onConfirm() {
        return login(gBroker.name, gBroker.tradingMode)
          .then(connect)
          .then(function () {
            gReLoginInProcess = false;
            $state.reload();
            deferred.reject(new Error('Reload app!'));
          })
          .catch(function () {
            return disconnect(true).finally(function () {
              $state.reload();
              deferred.reject(new Error('ReLogin fail!'));
            });
          });
      }

      /**
       *
       * @return {angular.IPromise<*>}
       */
      function onCancel() {
        return disconnect().finally(function () {
          $state.reload();
          deferred.reject(new Error('Reload app!'));
        });
      }
    }

    /**
     * This function gets list of user accounts for selected broker.
     *
     * @alias ecapp.btTradingService#getAccounts
     * @return {angular.IPromise<ecapp.ITradingAccount[]>} - list of accounts
     */
    function getAccounts() {
      return checkConnect('getAccounts', function () {
        if (gCache.get('accounts')) {
          return $q.resolve(gCache.get('accounts'));
        } else {
          return gBroker.service.getAccounts().then(function (accounts) {
            resetLastData(gLastAccounts, accounts, false);
            gCache.put('accounts', accounts);
            return accounts;
          });
        }
      });
    }

    /**
     * This function returns last saved accounts.
     *
     * @alias ecapp.btTradingService#getLastAccounts
     * @return {ecapp.ITradingAccount[]}
     */
    function getLastAccounts() {
      return gLastAccounts;
    }

    /**
     * This function get balances for specified accounts
     *
     * @alias ecapp.btTradingService#getBalances
     * @param {string[]} accountIds - list of account ids
     * @return {angular.IPromise<ecapp.ITradingBalance[]>}
     */
    function getBalances(accountIds) {
      return checkDefault('getBalances', function () {
        if (gCache.get('balances' + accountIds.toString())) {
          return $q.resolve(gCache.get('balances' + accountIds.toString()));
        } else {
          return gBroker.service.getBalances(accountIds).then(function (balances) {
            try {
              var user = btShareScopeService.accountInfo;
              gIsPractice =
                balances[0].rawData.createdByUserID.toString() === user.demoAccount.data.user.user_id.toString();
            } catch (error) {
              gIsPractice = false;
            }
            resetLastData(gLastBalances, balances);
            gCache.put('balances' + accountIds.toString(), balances);
            return balances;
          });
        }
      });
    }

    /**
     * This function returns last saved balances for the selected account.
     *
     * @alias ecapp.btTradingService#getLastBalances
     * @return {ecapp.ITradingBalance[]}
     */
    function getLastBalances() {
      return gLastBalances;
    }

    /**
     * This function gets positions for specified accounts.
     *
     * It is used in:
     * 1. bt-broker-data-controller to get positions for all accounts.
     * 2. bt-main-controller to get number of open  positions.
     * 3. bt-watchlist-service to get positions for all accounts.
     *
     * @alias ecapp.btTradingService#getPositions
     * @param {string[]} accountIds - list of account ids
     * @return {angular.IPromise<ecapp.ITradingPosition[]>}
     */
    function getPositions(accountIds) {
      if (gDebug) console.log(gPrefix, 'getPositions', new Date(), accountIds);
      return checkDefault('getPositions', function () {
        if (gCache.get('positions' + accountIds.toString())) {
          return $q.resolve(gCache.get('positions' + accountIds.toString()));
        } else {
          return gBroker.service.getPositions(accountIds).then(function (positions) {
            // test data
            positions.forEach(function (position) {
              position.count = gRateData['getPositions'].total.count;
            });
            resetLastData(gLastPositions, positions);
            gCache.put('positions' + accountIds.toString(), positions);
            return positions;
          });
        }
      });
    }

    /**
     * This function returns last saved positions for the selected account.
     *
     * @alias ecapp.btTradingService#getLastPositions
     * @return {ecapp.ITradingPosition[]}
     */
    function getLastPositions() {
      return gLastPositions;
    }

    /**
     * This function gets all orders for specified accounts.
     *
     * It is used in:
     * 1. bt-main-controller to get number of open  positions.
     *
     * @alias ecapp.btTradingService#getOrders
     * @param {string[]} accountIds - list of account ids
     * @return {angular.IPromise<ecapp.ITradingOrder[]>}
     */
    function getOrders(accountIds) {
      return checkDefault('getOrders', function () {
        if (gCache.get('orders' + accountIds.toString())) {
          return $q.resolve(gCache.get('orders' + accountIds.toString()));
        } else {
          return gBroker.service.getOrders(accountIds).then(function (orders) {
            // test data
            orders.forEach(function (order) {
              order.count = gRateData['getOrders'].total.count;
            });
            resetLastData(gLastOrders, orders);
            gCache.put('orders' + accountIds.toString(), orders);
            return orders;
          });
        }
      });
    }

    /**
     * This function returns last saved orders for the selected account.
     *
     * @alias ecapp.btTradingService#getLastOrders
     * @return {ecapp.ITradingOrder[]}
     */
    function getLastOrders() {
      return gLastOrders;
    }

    /* --- Market Data --- */
    /**
     * Get information about selected symbol
     *
     * @alias ecapp.btTradingService#getSymbolInfo
     * @param {string} symbol - symbol name
     * @return {angular.IPromise<ecapp.ITradingInstrument>}
     */
    function getSymbolInfo(symbol) {
      return checkConnect('getSymbolInfo', function () {
        return gBroker.service.getSymbolInfo(symbol);
      });
    }

    /**
     * Search symbol using query string
     *
     * @alias ecapp.btTradingService#searchSymbol
     * @param {string} text - symbol name
     * @param {any} [params] - ?
     * @return {angular.IPromise<ecapp.ITradingSymbol[]>} - list of symbol names
     */
    function searchSymbol(text, params) {
      return checkConnect('searchSymbol', function () {
        return gBroker.service.searchSymbol(text, params);
      });
    }

    /**
     * Search symbol using query string
     *
     * @alias ecapp.btTradingService#suggestSymbols
     * @param {string} text - search text
     * @param {number} limit - limit number of results
     * @param {Object} params - additional parameters
     * @return {angular.IPromise<ecapp.ITradingSymbol[]>} - list of symbol names
     */
    function suggestSymbols(text, limit, params) {
      return checkConnect('suggestSymbols', function () {
        return gBroker.service.suggestSymbols(text, limit, params);
      });
    }

    /**
     *
     * @param {*} underlying
     * @return {any}
     */
    function getOptionExpirations(underlying) {
      return checkConnect('getOptionExpirations', function () {
        if (gBroker.service.getOptionExpirations) {
          return gBroker.service.getOptionExpirations(underlying);
        } else {
          return $q.reject(new Error('Test'));
        }
      });
    }

    /**
     *
     * @param {*} underlying
     * @param {*} expiration
     * @return {any}
     */
    function getOptionStrikes(underlying, expiration) {
      return checkConnect('getOptionStrikes', function () {
        if (gBroker.service.getOptionExpirations) {
          return gBroker.service.getOptionStrikes(underlying, expiration);
        } else {
          return $q.reject(new Error('Test'));
        }
      });
    }

    /**
     * Get quotes for list of symbols
     *
     * @alias ecapp.btTradingService#getQuotes
     * @param {string[]} symbols
     * @return {angular.IPromise<{ prices: ecapp.ITradingQuote[]; time: number }>}
     */
    function getQuotes(symbols) {
      return checkConnect('getQuotes', function () {
        return gBroker.service.getQuotes(symbols);
      });
    }

    /**
     * Stream snapshot for selected symbol
     *
     * @alias ecapp.btTradingService#streamQuote
     * @param {string} symbol - symbol name
     * @param {ecapp.ICallback} onProgress - function to call on progress
     * @return {angular.IPromise<*>} - return object to terminate streaming
     */
    function streamQuote(symbol, onProgress) {
      return checkConnect('streamQuote', function () {
        if (symbol === '') {
          return $q.reject(new Error('Symbol is not supported by broker!'));
        } else {
          return gBroker.service.streamQuote(symbol, onProgress);
        }
      });
    }

    /**
     * Get snapshots for list of symbols
     *
     * @alias ecapp.btTradingService#getSnapshots
     * @param {string[]} symbols - list of BetterTrader symbols (will be convert to broker names or replaced by '')
     * @param {Object} range -
     * @param {number} interval -
     * @param {string} unit -
     * @return {angular.IPromise<Array>}
     */
    function getSnapshots(symbols, range, interval, unit) {
      return checkConnect('getSnapshots', function () {
        return gBroker.service.getSnapshots(symbols, range, interval, unit);
      });
    }

    /**
     * Stream snapshot for selected symbol
     *
     * @alias ecapp.btTradingService#streamSnapshot
     * @param {string} ticker - BetterTrader symbol (will be convert to broker names or reject error)
     * @param {string} granularity -
     * @param {number} back -
     * @param {ecapp.ICallback} onUpdate - function to call on progress
     * @return {angular.IPromise<*>} - return object to terminate streaming
     */
    function streamSnapshot(ticker, granularity, back, onUpdate) {
      return checkConnect('streamSnapshot', function () {
        if (ticker === '') {
          return $q.reject(new Error('Symbol is not supported by broker!'));
        } else {
          return gBroker.service.streamSnapshot(ticker, granularity, back, onUpdate);
        }
      });
    }

    /* --- Order execution --- */
    /**
     * Confirm order
     *
     * @alias ecapp.btTradingService#confirmOrder
     * @param {ecapp.ITradingOrderRequest} order - order request data
     * @return {angular.IPromise<ecapp.ITradingOrderConfirmation>}
     */
    function confirmOrder(order) {
      return checkDemo('confirmOrder', function () {
        if (order.symbol === '') {
          return $q.reject(new Error('Symbol is not supported by broker!'));
        }

        btAudioService.playBuySell();

        return gBroker.service.confirmOrder(order);
      });
    }

    /**
     * Submit order
     *
     * @alias ecapp.btTradingService#submitOrder
     * @param {ecapp.ITradingOrderRequest} order - order request data
     * @return {angular.IPromise<ecapp.ITradingOrderResponse>}
     */
    function submitOrder(order) {
      return checkDemo('submitOrder', function () {
        if (order.symbol === '') {
          return $q.reject(new Error('Symbol is not supported by broker!'));
        }

        btAudioService.playBuySell();

        return gBroker.service
          .submitOrder(order)
          .then(function (data) {
            btAudioService.playConfirmation();
            logTradeOrder(order, data);
            return data;
          })
          .catch(function (error) {
            btAudioService.playError();
            return $q.reject(error);
          });
      });
    }

    /**
     * Update order
     * Note: be careful with symbol name
     *
     * @alias ecapp.btTradingService#updateOrder
     * @param {string} orderId - order id
     * @param {Object} orderChanges - changes in order
     * @return {angular.IPromise<ecapp.ITradingOrderResponse>}
     */
    function updateOrder(orderId, orderChanges) {
      return checkDemo('updateOrder', function () {
        btAudioService.playBuySell();
        return gBroker.service.updateOrder(orderId, orderChanges);
      });
    }

    /**
     * Cancel order
     * Note: be careful with symbol name
     *
     * @alias ecapp.btTradingService#cancelOrder
     * @param {string} orderId - order id
     * @return {angular.IPromise<ecapp.ITradingOrderResponse>}
     */
    function cancelOrder(orderId) {
      return checkDemo('cancelOrder', function () {
        btAudioService.playBuySell();
        var deferred = $q.defer();
        gBroker.service
          .cancelOrder(orderId)
          .then(function (orderData) {
            btToastrService.success('Order was canceled', 'Cancel order', {
              type: 'trade',
            });
            $timeout(checkPositionsAndOrders, 500, false, orderData);
          })
          .catch(function (error) {
            btToastrService.error('Error: ' + (error.Message || error.message), 'Cancel order', { type: 'trade' });
            console.error(gPrefix, 'API call failed.', error);
            deferred.reject(error);
          });

        /**
         *
         * @param {ecapp.ITradingOrderResponse} orderData
         */
        function checkPositionsAndOrders(orderData) {
          $q.all([getPositions([getSelectedAccountId()]), getOrders([getSelectedAccountId()])])
            .then(function () {
              deferred.resolve(orderData);
            })
            .catch(function (error) {
              deferred.reject(error);
            });
        }

        return deferred.promise;
      });
    }

    /**
     * This function closes position.
     * It opens popup to confirm the action. If user cancel the action {empty: true} will be returned,
     * otherwise order response object will be returned.
     *
     * @alias ecapp.btTradingService#closePosition
     * @param {ecapp.ITradingPosition} position
     * @return {angular.IPromise<{empty: boolean}|ecapp.ITradingOrderResponse>}
     */
    function closePosition(position) {
      /** @type {ecapp.ICustomScope} */
      var scope = $rootScope.$new(true);
      scope.position = position;

      var confirmPopup = $ionicPopup.confirm({
        title: 'Close Position (' + position.symbol + ')',
        okText: 'Submit',
        scope: scope,
        template: $templateCache.get('directives/instruments/bt-position-close-popup.html'),
      });

      return confirmPopup.then(function (res) {
        if (res) {
          // console.log(res);
          return onConfirm();
        } else {
          return $q.resolve({ empty: true });
        }
      });

      /**
       *
       * @return {*}
       */
      function onConfirm() {
        if (window.btSettings.BT_DOMAIN === 'bettertrader') {
          $analytics.eventTrack('close-position', {
            category: 'brokerage',
            label: position.symbol,
          });
        }
        return processOrder(_generateClosePositionOrder(position));
      }
    }

    /**
     * This function close all positions.
     *
     * @alias ecapp.btTradingService#closeAllPositions
     * @param {ecapp.ITradingPosition[]} positions -
     * @return {angular.IPromise<*>}
     */
    function closeAllPositions(positions) {
      var confirmPopup = $ionicPopup.confirm({
        title: 'Close All Position',
        okText: 'Submit',
        template: $templateCache.get('directives/instruments/bt-position-close-all-popup.html'),
      });

      return confirmPopup.then(function (res) {
        if (res) {
          // console.log(res);
          return onConfirm();
        } else {
          return $q.resolve({ empty: true });
        }
      });

      /**
       *
       * @return {*}
       */
      function onConfirm() {
        if (window.btSettings.BT_DOMAIN === 'bettertrader') {
          $analytics.eventTrack('close-position', {
            category: 'brokerage',
            label: 'all',
          });
        }

        var promises = [];

        positions.forEach(function (position) {
          try {
            promises.push(processOrder(_generateClosePositionOrder(position)));
          } catch (e) {
            console.error('Error cancelling position ', e);
          }
        });

        return $q.all(promises);
      }
    }

    /**
     *
     * @param {*} position
     * @return {{type: string, symbol, action: string, quantity: number, rawPosition: *}}
     * @private
     */
    function _generateClosePositionOrder(position) {
      return {
        type: 'Market',
        symbol: position.symbol,
        action: position.position === 'Long' ? 'SELL' : 'BUY',
        quantity: Math.abs(position.quantity),
        rawPosition: position,
      };
    }

    // End - General Trading Interface

    // Begin - Extended Trading Interface
    /**
     * This function gets last 30 candles with specified granularity.
     *
     * It is used in:
     *  1. bt-instrument-chart to.
     *
     * @alias ecapp.btTradingService#getCandles
     * @param {string} symbol - broker symbol name
     * @param {string} granularity - candle period
     * @param {any} period - ?
     * @return {angular.IPromise<{candles: ecapp.ITradingCandle[]}>} candles data in oanda format
     */
    function getCandles(symbol, granularity, period) {
      return checkConnect('getCandles', function () {
        if (symbol === '') {
          return $q.reject(new Error('Symbol is not supported by broker!'));
        } else {
          return gBroker.service.getCandles(symbol, granularity, period);
        }
      });
    }

    /**
     * This function gets last 30 candles with specified granularity.
     *
     * It is used in:
     *  1. bt-instrument-chart to.
     *
     * @alias ecapp.btTradingService#getLastCandlesData
     * @param {string} symbol - broker symbol name
     * @param {string} granularity - candle period
     * @param {number} [count] - number of candles
     * @return {angular.IPromise<{candles: ecapp.ITradingLastCandle[]}>} candles data in oanda format
     */
    function getLastCandlesData(symbol, granularity, count) {
      return checkConnect('getLastCandlesData', function () {
        if (symbol === '') {
          return $q.reject(new Error('Symbol is not supported by broker!'));
        } else {
          return gBroker.service.getLastCandlesData(symbol, granularity, count || 30);
        }
      });
    }

    /**
     * This function gets next prices: yesterday close, today open, today low, today high, current bid, current ask and last.
     *
     * It is used in:
     * 1. bt-broker-data-controller to.
     * 2. bt-instrument-page-controller to.
     * 3. bt-instrument-container to.
     * 3. bt-tutorial-controller to.
     * 3. bt-watchlist-service to.
     *
     * @alias ecapp.btTradingService#getLiveCandleData
     * @param {string} symbol - broker symbol name
     * @return {angular.IPromise<ecapp.ITradingLiveCandle>} candles data in oanda format
     */
    function getLiveCandleData(symbol) {
      return checkConnect('getLiveCandleData', function () {
        if (symbol === '') {
          return $q.reject(new Error('Symbol is not supported by broker!'));
        } else {
          return gBroker.service.getLiveCandleData(symbol);
        }
      });
    }

    /**
     * This function gets next prices: yesterday close, today open, today low, today high, current bid, current ask and last.
     *
     * It is used in:
     * 1. bt-broker-data-controller to.
     * 2. bt-instrument-page-controller to.
     * 3. bt-instrument-container to.
     * 3. bt-tutorial-controller to.
     * 3. bt-watchlist-service to.
     *
     * @alias ecapp.btTradingService#getLiveCandlesData
     * @param {string[]} symbols - list of broker symbols
     * @return {angular.IPromise<ecapp.ITradingLiveCandle[]>} candles data in oanda format
     */
    function getLiveCandlesData(symbols) {
      return checkConnect('getLiveCandlesData', function () {
        if (!(symbols && symbols.length)) {
          return $q.reject(new Error('List of symbols is not defined!'));
        } else {
          if (gBroker.service.getLiveCandlesData) {
            return gBroker.service.getLiveCandlesData(symbols);
          } else {
            return $q.all(symbols.map(gBroker.service.getLiveCandleData));
          }
        }
      });
    }

    // /**
    //  * This function returns complex price object.
    //  *
    //  * @alias ecapp.btTradingService#getPriceObject
    //  * @param {ecapp.ITradingInstrument} instrument
    //  * @return {btComplexPriceObject}
    //  */
    // function getPriceObject(instrument) {
    //   if (gPriceCache[symbol] === undefined) {
    //     // var instrument = btInstrumentsService.getInstrumentByField('brokerSymbol', symbol);
    //     gPriceCache[symbol] = btPriceService.createComplexPriceObject(symbol, instrument.precision, btPriceService.parseThresholds(instrument));
    //     updateInstrumentPrice(symbol);
    //   }
    //
    //   return gPriceCache[symbol];
    // }

    /**
     * This function enables automatic price refreshing of the instrument.
     *
     * @alias ecapp.btTradingService#enablePriceUpdates
     * @param {ecapp.ITradingInstrument} instrument
     */
    function enablePriceUpdates(instrument) {
      var symbol = instrument.OandaSymbol;
      // console.log('Enable price updates for ' + symbol, gPriceCache[symbol], instrument.price);

      if (gDebug) console.log(gPrefix, 'Enable Price Updates for', instrument);

      if (instrument.provider === 'lds') {
        symbol = instrument.brokerSymbol + ':' + instrument.month;
      }

      if (instrument.provider === 'bettertrader' || instrument.provider === 'tradestation') {
        symbol = instrument.brokerSymbol;
      }

      if (symbol) {
        if (gPriceCache[symbol] === undefined) {
          gPriceCache[symbol] = btPriceService.createComplexPriceObject(
            symbol,
            instrument.precision,
            btPriceService.parseThresholds(instrument)
          );
          // if (symbol === 'AUD_USD') {
          // console.log('TEST: set price for ' + symbol);
          // }
          instrument.price = gPriceCache[symbol];
          updateInstrumentPrice(symbol).catch(console.error);
        }

        gPriceCache[symbol].links++;
      } else {
        console.log('No symbol');
      }

      // console.log('Instrument price ' + symbol, instrument.price);
    }

    /**
     * This function disables automatic price refreshing of the instrument.
     *
     * @alias ecapp.btTradingService#disablePriceUpdates
     * @param {ecapp.ITradingInstrument} instrument - instrument instance
     */
    function disablePriceUpdates(instrument) {
      var symbol = instrument.OandaSymbol;
      // console.log('Disable price updates for ' + symbol);

      if (instrument.provider === 'lds') {
        symbol = instrument.brokerSymbol + ':' + instrument.month;
      }

      if (symbol && gPriceCache[symbol]) {
        gPriceCache[symbol].links--;

        if (gPriceCache[symbol].links < 0) {
          gPriceCache[symbol].links = 0;
          console.error(
            btErrorService.ClientError('NEGATIVE_PRICE_LINK', 'Number of price links for ' + symbol + ' is negative')
          );
        }
      }
    }

    /**
     *
     * @alias ecapp.btTradingService#changeMonthOption
     * @param {ecapp.ITradingInstrument} instrument - instrument instance
     */
    function changeMonthOption(instrument) {
      if (instrument.provider === 'lds') {
        var links = 0;
        if (instrument.price) {
          links = instrument.price.links;
          instrument.price.links = 0;
        }

        var symbol = instrument.brokerSymbol + ':' + instrument.month;

        if (gPriceCache[symbol] === undefined) {
          gPriceCache[symbol] = btPriceService.createComplexPriceObject(
            symbol,
            instrument.precision,
            btPriceService.parseThresholds(instrument)
          );
        }

        instrument.price = gPriceCache[symbol];
        instrument.links = links;
        updateInstrumentPrice(symbol).catch(console.error);
      }
    }

    // /**
    //  * Convert BetterTrader symbol name to broker symbol name (or empty string)
    //  * @param {string} btSymbolName - BetterTrader name of symbol
    //  * @return {string} broker name of symbol
    //  */
    // function getBrokerSymbol(btSymbolName) {
    //   return btInstrumentsService.convertBrokerName(btSymbolName, gBroker.name);
    // }

    /**
     * Convert BetterTrader symbol name to broker symbol name (or empty string)
     *
     * @alias ecapp.btTradingService#getInstrumentByBrokerSymbol
     * @param {string} brokerSymbol - BetterTrader name of symbol
     * @return {ecapp.ITradingInstrument} broker name of symbol
     */
    function getInstrumentByBrokerSymbol(brokerSymbol) {
      var instrument;
      // if (isDefaultBroker()) {
      instrument = btInstrumentsService.getInstrumentSmart(brokerSymbol);
      // } else {
      // instrument = btInstrumentsService.getInstrumentByField('brokerSymbol', brokerSymbol);
      // }

      if (instrument) {
        return instrument;
      } else {
        // console.log('>>> Instrument not found', brokerSymbol);

        if (gBroker.service.createInstrument) {
          return gBroker.service.createInstrument(brokerSymbol);
        } else {
          var name = gBroker.name;
          var provider = name === 'default' ? 'oanda' : name;
          return btInstrumentsService.createBrokerInstrument(name, brokerSymbol, provider, 'Unknown', true);
        }
      }
    }

    /**
     *
     * @param {string} ticker
     * @return {angular.IPromise<ecapp.ITradingInstrument>} broker name of symbol
     */
    function loadInstrumentByTicker(ticker) {
      return gBroker.service.getSymbolInfo(ticker);
      // return gBroker.service.getSymbolInfo(ticker).then(function (info) {
      //   return btInstrumentsService.getInstrumentSmart(info.name);
      // });
    }

    /**
     * Returns current watched instruments.
     *
     * @return {ecapp.ITradingInstrument[]}
     */
    function getWatchedInstruments() {
      return gWatchlist;
    }

    /**
     *
     * @return {*}
     */
    function getCharts() {
      var symbols = btShareScopeService.getCharts(gBroker.name);
      if (!symbols) symbols = gBroker.defaultCharts;
      return symbols;
    }

    /**
     *
     * @param {*} symbols
     * @return {*}
     */
    function setCharts(symbols) {
      btShareScopeService.setCharts(gBroker.name, symbols);
      return btShareScopeService.updateProfileSettingsFromFieldsList(['charts']);
    }

    // /**
    //  * Return true if symbol can be converted to broker
    //  * @param {string} btSymbolName - BetterTrader name of symbol
    //  * @return {boolean}
    //  */
    // function hasBrokerSymbol(btSymbolName) {
    //   return getBrokerSymbol(btSymbolName) !== '';
    // }

    /**
     * Set default trade size and save this data
     * @param {number} value - default trade size
     * @return {angular.IPromise<*>}
     */
    function setDefaultTradeSize(value) {
      gDefaultTradeSize = value;
      return _saveBrokerData(gBroker.data);
    }

    /**
     * Get default trade size
     * @return {number}
     */
    function getDefaultTradeSize() {
      return gDefaultTradeSize || 1;
    }

    /**
     * Get rate limit for selected action
     *
     * @alias ecapp.btTradingService#getLimit
     * @param {string} name - name of action
     * @return {number} requests per minute
     */
    function getLimit(name) {
      void name;
      if (getBrokerName() === 'tradier') {
        return 100;
      }
      return 40;
    }

    /**
     *
     * @param {string} symbol
     * @return {*}
     */
    function hasPositions(symbol) {
      if (gLastPositions !== null) {
        var positions = gLastPositions.filter(function (position) {
          return position.symbol === symbol;
        });
        if (positions.length !== 0) {
          return positions;
        } else {
          return [];
        }
      } else {
        return [];
      }
    }

    /**
     *
     * @param {string} symbol
     * @return {*}
     */
    function hasOrders(symbol) {
      if (gLastOrders !== null) {
        return gLastOrders.filter(function (order) {
          return order.symbol === symbol && (order.status === 'Received' || order.status === 'Queued');
        });
      } else {
        return null;
      }
    }

    /**
     * If account is selected success (not equal to null), it will be saved.
     *
     * @param {string} id - account id
     * @return {?string} id of selected account or null
     */
    function selectAccount(id) {
      if (isConnected()) {
        var selectedAccountId = gBroker.service.selectAccount(id);
        if (selectedAccountId !== null) {
          gBroker.data.defaultAccount = selectedAccountId;
          _saveBrokerData(gBroker.data);
        }
        return selectedAccountId;
      } else {
        return null;
      }
    }

    /**
     *
     * @param {*} id
     * @return {*}
     */
    function isAccountSelected(id) {
      if (isConnected()) {
        return gBroker.service.isAccountSelected(id);
      } else {
        return false;
      }
    }

    /**
     *
     * @return {*}
     */
    function getSelectedAccountId() {
      if (isConnected()) {
        return gBroker.service.getSelectedAccountId();
      } else {
        return null;
      }
    }

    /**
     *
     * @return {*}
     */
    function getSelectedAccount() {
      if (isConnected()) {
        const accounts = getLastAccounts();
        const selectedAccountId = getSelectedAccountId();
        const selectedAccount = accounts.filter(function (account) {
          return account.key === selectedAccountId;
        })[0];
        return selectedAccount;
      } else {
        return null;
      }
    }

    /**
     * Processes order.
     *
     * @param {*} orderBody - order body
     * @return {angular.IPromise<*>}
     */
    function processOrder(orderBody) {
      var deferred = $q.defer();
      var title = 'New order';
      var opts = { type: 'trade' };

      if (orderBody.symbol !== undefined) {
        var msg = orderBody.symbol + ' ' + orderBody.action.toLowerCase();
        btToastrService.info(msg + ' order sent', title, opts);

        orderBody = _correctOrder(orderBody);
        submitOrder(orderBody)
          .then(function (orderData) {
            btToastrService.success(msg + ' order processed', title, opts);
            if (gDebug) console.log(gPrefix, 'API called successfully. Returned data:', orderData);
            $timeout(checkPositionsAndOrders, 500, false, orderData);
          })
          .catch(function (error) {
            btToastrService.error(error.Message || error.message, title, opts);
            console.error(gPrefix, 'API call failed.', error);
            deferred.reject(error);
          });
      } else {
        btToastrService.error('The instrument is not connected to selected broker', title, opts);
        deferred.reject(new Error('The instrument is not connected to selected broker'));
      }

      /**
       *
       * @param {ecapp.ITradingOrderResponse} orderData
       */
      function checkPositionsAndOrders(orderData) {
        $q.all([getPositions([getSelectedAccountId()]), getOrders([getSelectedAccountId()])])
          .then(function (results) {
            var orders = results[1];
            orders.forEach(function (order) {
              if (order.key === orderData.key) {
                // ???
              }
            });
            deferred.resolve(orderData);
          })
          .catch(function (error) {
            deferred.reject(error);
          });
      }

      return deferred.promise;
    }

    /**
     *
     * @param {ecapp.ITradingOrderRequest} orderBody
     * @return {ecapp.ITradingOrderRequest}
     * @private
     */
    function _correctOrder(orderBody) {
      var position;
      if (orderBody.rawPosition) {
        position = orderBody.rawPosition;
      } else {
        position = hasPositions(orderBody.symbol)[0];
      }

      if (orderBody.instrument.type !== 'CRYPTO') {
        if (orderBody.action === BT.TRADE_ACTION.SELL) {
          if (!(position && position.quantity > 0)) {
            orderBody.action = BT.TRADE_ACTION.SELL_SHORT;
          }
        }

        if (orderBody.action === BT.TRADE_ACTION.BUY) {
          if (position && position.quantity < -1) {
            orderBody.action = BT.TRADE_ACTION.BUY_COVER;
          }
        }
      }

      return orderBody;
    }

    // End - Extended Trading Interface

    // Begin - Helpers
    /**
     * Show select broker pop-up
     *
     * @alias ecapp.btTradingService#showSelectBrokerMenu
     * @param {ecapp.ICustomScope} scope - angular scope
     */
    function showSelectBrokerMenu(scope) {
      var myPopup = $ionicPopup.show({
        template:
          '<p>In order to trade via <b>BetterTrader</b> you need to connect your brokerage account.</p>' +
          '<p>Click <b>"Manage"</b> and check if we already working with your broker.</p>',
        title: 'Trading Ability',
        scope: scope,
        cssClass: 'popup-bar-dark',
        buttons: [
          { text: 'Cancel' },
          {
            text: 'Manage',
            type: 'button-positive',
            onTap: function (e) {
              void e;
              return true;
            },
          },
        ],
      });

      myPopup.then(function (res) {
        if (gDebug) console.log(gPrefix, 'Tapped!', res);
        if (res) {
          if (gDebug) console.log(gPrefix, 'Trading: try to open ecapp.app.broker');
          $state.go('ecapp.app.broker');
        }
      });
    }

    /**
     *
     * @param {*} accounts
     * @return {*}
     */
    function parseAccountList(accounts) {
      return accounts.map(function (a) {
        return a.key;
      });
    }

    // End - Helpers

    // Begin - Private
    /**
     * Load broker data (now from local storage) and try to select broker.
     * Always resolve some data: real or null object.
     * External variables: broker
     *
     * @return {angular.IPromise<ecapp.IBrokerInfo>}
     * @private
     */
    function _loadBrokerData() {
      if (gDebug) console.log(gPrefix, 'loading broker data...');

      if (gDebug) console.log(gPrefix, 'waiting for ShareScopeService...');
      return btShareScopeService.wait().then(function () {
        if (gDebug) console.log(gPrefix, 'service is ready');
        var brokerSettings;

        if (btSettingsService.isLinkDataService()) {
          brokerSettings = { name: 'default' };
        } else {
          brokerSettings = btShareScopeService.getAccountInfoField('brokerData');
        }
        if (gDebug) console.log(gPrefix, 'broker data:', brokerSettings);

        if (brokerSettings && brokerSettings.name && _selectBroker(brokerSettings.name)) {
          if (isDefaultBroker()) {
            if (gDebug) console.log(gPrefix, 'use default token');
            gBroker.data = { token: getDefaultToken(), mode: 'demo' };
          } else {
            gBroker.data = brokerSettings.data;
          }

          if (getBrokerName() !== 'tradestation') {
            if (brokerSettings.selectedTradeSize) {
              gDefaultTradeSize = brokerSettings.selectedTradeSize;
            } else {
              gDefaultTradeSize = gBroker.defaultTradeSize;
            }
          } else {
            gDefaultTradeSize = undefined;
          }
        } else {
          if (gDebug) console.log(gPrefix, 'broker data not found');
        }

        if (gDebug) console.log(gPrefix, 'broker object:', gBroker);

        return gBroker;
      });
    }

    /**
     *
     * @return {string}
     */
    function getDefaultToken() {
      return btSettings.BT_OANDA_TOKEN;
    }

    /**
     * Save broker data (now to local storage)
     *
     * @param {ecapp.IBrokerData} data - broker data
     * @return {angular.IPromise<ecapp.IUserBrokerData>}
     * @private
     */
    function _saveBrokerData(data) {
      var deferred = $q.defer();

      if (gDebug) console.log(gPrefix, 'save broker data', data);
      btShareScopeService.setAccountInfoField('brokerData', {
        name: gBroker.name,
        selectedTradeSize: gDefaultTradeSize,
        isPractice: gIsPractice,
        data: data,
      });

      btShareScopeService
        .updateProfileSettingsFromFieldsList(['brokerData'])
        .then(function (data) {
          // console.log(data);
          deferred.resolve(data);
        })
        .catch(function (error) {
          console.error(gPrefix, 'saving broker data', error);
          deferred.reject(error);
        });

      return deferred.promise;
    }

    /**
     * Save demo account data
     * @param {Object} demoData - demo account data
     * @return {angular.IPromise<*>}
     * @private
     */
    function _saveDemoAccount(demoData) {
      var deferred = $q.defer();

      if (gDebug) console.log(gPrefix, 'save broker data', demoData);
      btShareScopeService.setAccountInfoField('demoAccount', {
        name: gBroker.name,
        selectedTradeSize: gDefaultTradeSize,
        data: demoData,
      });

      btShareScopeService
        .updateProfileSettingsFromFieldsList(['demoAccount'])
        .then(function (data) {
          if (gDebug) console.log(gPrefix, '_saveDemoAccount data:', data);
          deferred.resolve(demoData);
        })
        .catch(function (error) {
          console.error(gPrefix, '_saveDemoAccount error:', error);
          deferred.reject(error);
        });

      return deferred.promise;
    }

    /**
     * Delete broker data (now from local storage)
     *
     * @return {angular.IPromise<*>}
     * @private
     */
    function _deleteBrokerData() {
      clearLastData(gLastPositions);
      clearLastData(gLastOrders);

      gDefaultTradeSize = null;
      gIsPractice = false;

      Object.keys(gPriceCache).forEach(function (symbol) {
        delete gPriceCache[symbol];
      });

      gBroker = _defaultBroker();

      gInitializationPromise = null;
      gConnectionPromise = null;

      return _saveBrokerData(null).then(function () {
        // gIsInitialized = false;
        return initialize();
      });
    }

    /**
     * Select broker if it's supported. Just fill global broker variable.
     * External variables: broker
     *
     * @param {string} brokerId - broker id
     * @return {boolean} is broker selected
     * @private
     */
    function _selectBroker(brokerId) {
      if (gDebug) console.log(gPrefix, 'selecting ' + brokerId + ' broker...');

      if (isBrokerSupported(brokerId)) {
        if (gDebug) console.log(gPrefix, 'broker has been selected');
        gBroker = gSupportedBrokers[brokerId];
        gBroker.status = 'selected';
        gBroker.user = null;

        return true;
      } else {
        if (gDebug) console.log(gPrefix, 'broker is not supported');
        return false;
      }
    }

    /**
     * This function calculates number of calls per minute in some interval and in history.
     *
     * @param {string} name - name of process
     * @param {object} scope - object to save measurements
     * @private
     */
    function _measureRate(name, scope) {
      var DELTA = 10 * 1000; // delta in milliseconds
      var MINUTE = 60 * 1000; // one minute in milliseconds

      // init object
      if (scope[name] === undefined) {
        scope[name] = {
          interval: { start: new Date(), count: 0 },
          total: { start: new Date(), count: 0 },
        };
      }

      // refresh interval counter
      if (scope[name].interval.start === null) {
        scope[name].interval = { start: new Date(), count: 0 };
      }

      // end interval
      var now = new Date().getTime();
      if (now - scope[name].interval.start > DELTA) {
        var nMinutes = (now - scope[name].total.start) / MINUTE;
        scope[name].interval.start = null;
        if (gDebug)
          console.log(
            gPrefix,
            name,
            'interval rate:',
            (scope[name].interval.count * (MINUTE / DELTA)).toFixed(2),
            'requests per minute'
          );
        if (gDebug)
          console.log(
            gPrefix,
            'historical rate:',
            (scope[name].total.count / nMinutes).toFixed(2),
            'requests per minute'
          );
      }

      // increase counter
      scope[name].interval.count++;
      scope[name].total.count++;
    }

    /**
     *
     * @param {*} request
     * @param {*} response
     */
    function logTradeOrder(request, response) {
      var user = {
        email: $rootScope.currentUser.email,
        account: getSelectedAccountId(),
        broker: getBrokerName(),
        mode: getTradingMode(),
      };

      btShareScopeService.logTradeOrder({
        user: user,
        request: request,
        response: response,
        created: new Date(),
      });
    }

    // End - Private

    /**
     *
     * @param {*} data
     */
    function onLoginSuccess(data) {
      void data;
      try {
        if (gDebug) console.log(gPrefix, 'on login:success');
      } catch (e) {
        console.error(e);
      }
    }

    /**
     *
     * @param {*} data
     */
    function onLogoutSuccess(data) {
      void data;
      try {
        logout();
      } catch (e) {
        console.error(e);
      }
    }

    /**
     * This function checks login ability for specified broker.
     *
     * @param {string} name - broker name
     * @return {boolean}
     */
    function hasBrokerLogin(name) {
      return name === 'oanda' || name === 'tradestation' || (name === 'ctrader' && false) || name === 'tradier';
    }

    /**
     * This function returns trade idea appearance.
     *
     * @return {{displayMode: string}}
     */
    function getDefaultDisplayOptions() {
      return { displayMode: gOptions.displayMode.regular };
    }

    /**
     * This function clear last data (balance, positions or orders).
     *
     * @param {Array} list - list of some data
     */
    function clearLastData(list) {
      var n = list.length;
      for (var i = 0; i < n; i++) {
        list.pop();
      }
    }

    /**
     * This function reset array.
     *
     * @param {Array} dest - destination array
     * @param {Array} src - source array
     * @param {boolean} [selected=true]
     */
    function resetLastData(dest, src, selected) {
      clearLastData(dest);
      selected = selected === undefined ? true : selected;

      if (selected) {
        var selectedAccount = getSelectedAccountId();
        if (selectedAccount) {
          for (var i = 0; i < src.length; i++) {
            if (src[i].acc === selectedAccount) dest.push(src[i]);
          }
        }
      } else {
        for (var j = 0; j < src.length; j++) {
          dest.push(src[j]);
        }
      }
    }
  }
})();
