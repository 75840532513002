/**
 * Created by Sergey Panpurin on 9/14/2017.
 */

/**
 * @see ecapp.IComplexPrice
 * @typedef {object} btComplexPriceObject
 * @property {Date} updated - time of last update
 * @property {number} links - number of links (connected to auto refresh)
 * @property {number} count - number of updates
 * @property {string} symbol - instrument symbol
 * @property {number} [precision] - instrument precision (number of digits after dot)
 * @property {{positive: number[], negative: number[]}} [thresholds] - change thresholds
 * @property {object} yesterday - information about yesterday prices
 * @property {btPriceObject} yesterday.close - yesterday close price
 * @property {object} today  - information about today prices
 * @property {btPriceObject} today.open - today close price
 * @property {btPriceObject} today.low - today close price
 * @property {btPriceObject} today.high - today close price
 * @property {object} now - information about current price
 * @property {btPriceObject} now.bid - current bid price
 * @property {btPriceQuoteObject[]} now.bids - list of bid prices
 * @property {btPriceObject} now.ask - current ask price
 * @property {btPriceQuoteObject[]} now.asks - list of ask prices
 * @property {btPriceObject} now.last - current last price
 */

/**
 * @see ecapp.IPrice
 * @typedef {Object} btPriceObject
 * @property {number} value - price as a number
 * @property {number} derivative - difference between current and previous value
 * @property {string} movement - price derivative status: positive, negative, neutral
 * @property {string} text - price as a text
 * @property {string} constant - constant part of the price
 * @property {string} variable - variable part of the price
 * @property {number} delta - difference between current price and yesterday close
 * @property {string} change - change from yesterday close (delta as a text)
 * @property {number} percent - change in percentage from yesterday close
 * @property {string} status - price change status: positive, negative or neutral
 * @property {number} significance - significance of price change: 0, 1, 2, 4
 */

/**
 * @see ecapp.IPriceQuote
 * @typedef {object} btPriceQuoteObject
 * @property {number} value - price value
 * @property {string} text - price value as a text
 * @property {string} liquidity - liquidity
 */

// @ts-check
(function btPriceServiceClosure() {
  'use strict';

  var gDebug = false;
  var gPrefix = 'btPriceService:';

  /**
   * @ngdoc service
   * @name btPriceService
   * @memberOf ecapp
   * @description
   *  Help to work with price
   */

  /** Separator */
  angular.module('ecapp').factory('btPriceService', btPriceService);

  btPriceService.$inject = [];

  /**
   *
   * @return {ecapp.IPriceService}
   */
  function btPriceService() {
    console.log('Running btPriceService');
    if (gDebug) console.log(gPrefix + ' test');

    var gDefaultPrecision = 1;

    /**
     * Get price precision
     * @param {String | Number} price
     * @return {Number} precision
     */
    function getPricePrecision(price) {
      // special units like ' (bond price)
      var match0 = ('' + price).match(/(['/]+)/);
      if (match0) {
        return 3;
      }

      // regular units
      var match = ('' + price).match(/(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/);

      if (!match) {
        return 0;
      }

      return Math.max(
        0,
        // Number of digits right of decimal point.
        (match[1] ? match[1].length : 0) -
          // Adjust for scientific notation.
          (match[2] ? +match[2] : 0)
      );
    }

    /**
     * This function determine precision of number in string
     *
     * @param {Number|String} value - string with number inside
     * @return {Number} - precision
     */
    function getPrecision(value) {
      value = value.toString();
      var i = value.indexOf('.');

      if (i === -1) {
        return 0;
      }

      return value.length - value.indexOf('.') - 1;
    }

    /**
     * This function creates new composite price object.
     *
     * @param {string} symbol - instrument symbol
     * @param {number} [precision] - instrument precision
     * @param {ecapp.IPriceThresholds} [thresholds]
     * @param {Date} [created] - time of creation
     * @return {ecapp.IComplexPrice}
     */
    function createComplexPriceObject(symbol, precision, thresholds, created) {
      return {
        updated: created || new Date(),
        links: 0,
        count: 0,
        symbol: symbol,
        precision: precision,
        thresholds: thresholds || undefined,
        yesterday: {
          close: createPriceObject(),
        },
        today: {
          low: createPriceObject(),
          high: createPriceObject(),
          open: createPriceObject(),
        },
        now: {
          bid: createPriceObject(),
          bids: [],
          ask: createPriceObject(),
          asks: [],
          last: createPriceObject(),
        },
      };
    }

    /**
     * This function creates new partial price object.
     *
     * @return {ecapp.IPrice}
     */
    function createPriceObject() {
      return {
        value: 0,
        text: 'Loading...',
        delta: 0,
        change: 'N/A',
        percent: 0,
        status: 'neutral',
        constant: 'Loading...',
        variable: '',
        derivative: 0,
        movement: 'neutral',
        significance: 0,
      };
    }

    /**
     * This function updates complex price object.
     *
     * @param {ecapp.IComplexPrice} price - composite price object
     * @param {Date} updated - time of last update
     * @param {string} close - yesterday close price
     * @param {string} open - today open price
     * @param {string} low - the lowest price of today
     * @param {string} high - the highest price of today
     * @param {string} bid - current bid price
     * @param {string} ask - current ask price
     * @param {string} last - current last price
     * @param {ecapp.IPriceQuote[]} bids - list of bid prices
     * @param {ecapp.IPriceQuote[]} asks - list of ask prices
     */
    function updateComplexPriceObject(price, updated, close, open, low, high, bid, ask, last, bids, asks) {
      price.updated = updated || new Date();
      var ref = null;
      if (close) ref = parseFloat(close);
      else if (price.yesterday.close.value) ref = price.yesterday.close.value;

      if (ref) {
        if (price.precision === undefined)
          price.precision = guessPricePrecision([close, open, low, high, bid, ask, last]);

        if (close) updatePriceObject(price.yesterday.close, close, ref, price.precision);

        if (open) updatePriceObject(price.today.open, open, ref, price.precision);
        if (low) updatePriceObject(price.today.low, low, ref, price.precision);
        if (high) updatePriceObject(price.today.high, high, ref, price.precision);

        if (bid) updatePriceObject(price.now.bid, bid, ref, price.precision);
        if (ask) updatePriceObject(price.now.ask, ask, ref, price.precision);
        if (last) updatePriceObject(price.now.last, last, ref, price.precision, price.thresholds);
      }

      if (bids) price.now.bids = bids;
      if (asks) price.now.bids = asks;
    }

    /**
     * This function tries to guess price precision.
     *
     * @param {String[]} prices - list of different prices
     * @return {Number} maximal price precision
     */
    function guessPricePrecision(prices) {
      var precisions = [];
      prices.forEach(function (value) {
        if (value) precisions.push(getPricePrecision(value));
      });

      if (precisions.length > 0) {
        return Math.max.apply(null, precisions);
      } else {
        return gDefaultPrecision;
      }
    }

    /**
     * This function updates partial price object.
     *
     * @param {ecapp.IPrice} price - partial price object
     * @param {String} text - price as a text
     * @param {Number} ref - reference price
     * @param {?Number} [precision=1] - price precision
     * @param {ecapp.IPriceThresholds} [thresholds] - thresholds
     */
    function updatePriceObject(price, text, ref, precision, thresholds) {
      precision = precision !== undefined ? precision : gDefaultPrecision;

      var value = 0;
      try {
        value = parseFloat(text);
      } catch (e) {
        console.error(new Error('Invalid price: ' + text));
      }

      if (price.value === 0) {
        price.constant = text;
        price.variable = '';
        price.derivative = 0;
        price.movement = 'neutral';
      } else {
        price.constant = getConstantPart(text, price.text);
        price.variable = getVariablePart(text, price.text);
        price.derivative = value - price.value;
        price.movement = price.derivative > 0 ? 'positive' : price.derivative < 0 ? 'negative' : 'neutral';
      }

      price.value = value;
      price.text = text;
      price.delta = price.value - ref;
      price.change = price.delta.toFixed(precision);
      price.percent = getPercent(price.value, ref);

      price.significance = calculateSignificance(price.percent, thresholds);
      price.status = price.delta > 0 ? 'positive' : price.delta < 0 ? 'negative' : 'neutral';

      // If change is too big hide it
      if (Math.abs(price.percent) > 50) {
        price.change = 'N/A';
        price.percent = NaN;
        price.status = 'neutral';
      }
    }

    /**
     *
     * @param {number} percent
     * @param {ecapp.IPriceThresholds} thresholds
     * @return {number}
     */
    function calculateSignificance(percent, thresholds) {
      var significance = 0;

      if (thresholds) {
        if (percent > 0) {
          thresholds.positive.forEach(function (threshold, i) {
            if (percent > threshold) significance = i + 1;
          });
        } else if (percent < 0) {
          thresholds.negative.forEach(function (threshold, i) {
            if (percent < threshold) significance = i + 1;
          });
        }
      }
      return significance;
    }

    /**
     * This function updates complex price object using quotes.
     *
     * @param {ecapp.IComplexPrice} price - composite price object
     * @param {Date} updated - time of last update
     * @param {String} last - last price
     * @param {String} bid - current bid price
     * @param {String} ask - current ask price
     * @param {ecapp.IPriceQuote[]} [bids] - list of bid prices
     * @param {ecapp.IPriceQuote[]} [asks] - list of ask prices
     */
    function updateComplexPriceObjectQuotes(price, updated, last, bid, ask, bids, asks) {
      price.updated = updated || new Date();
      /** @type {Number} */
      var ref = price.yesterday.close.value;
      // /** @type {?String} */
      // var last = null;

      if (!last) {
        // !!! > Function toFixed could produce rounding problems
        if (bid !== null && ask !== null) last = ((parseFloat(bid) + parseFloat(ask)) / 2).toFixed(price.precision);
        if (bid !== null && ask === null) last = bid;
        if (bid === null && ask !== null) last = ask;
      }

      if (last) {
        // !!! > It may be better to use bid value here
        if (parseFloat(last) < price.today.low.value) updatePriceObject(price.today.low, last, ref, price.precision);
        // !!! > It may be better to use ask value here
        if (parseFloat(last) > price.today.high.value) updatePriceObject(price.today.high, last, ref, price.precision);

        updatePriceObject(price.now.last, last, ref, price.precision, price.thresholds);
      }

      // bid saves previous value if there are not bids
      if (bid) updatePriceObject(price.now.bid, bid, ref, price.precision);

      // asks saves previous value if there are not asks
      if (ask) updatePriceObject(price.now.ask, ask, ref, price.precision);

      if (bids) price.now.bids = bids;
      if (asks) price.now.bids = asks;
    }

    /**
     * This function calculates constant part of value.
     *
     * @param {String} newValue - new value
     * @param {String} oldValue - old value
     * @return {String} constant part of value
     */
    function getConstantPart(newValue, oldValue) {
      var n = Math.min(newValue.length, oldValue.length);
      var result = [];
      for (var i = 0; i < n; i++) {
        if (oldValue[i] === newValue[i]) {
          result.push(oldValue[i]);
        } else {
          return result.join('');
        }
      }
      return result.join('');
    }

    /**
     * This function calculates variable part of value.
     *
     * @param {String} newValue - new value
     * @param {String} oldValue - old value
     * @return {String} variable part of value
     */
    function getVariablePart(newValue, oldValue) {
      var n = newValue.length;
      var result = [];
      for (var i = 0; i < n; i++) {
        if (i < oldValue.length) {
          if (newValue[i] !== oldValue[i] || result.length > 0) {
            result.push(newValue[i]);
          }
        } else {
          result.push(newValue[i]);
        }
      }

      return result.join('');
    }

    /**
     * This function calculates percentage for specified value.
     *
     * @param {Number} value - specified value
     * @param {Number} reference - reference value
     * @return {Number} percentage
     */
    function getPercent(value, reference) {
      return ((value - reference) / reference) * 100;
    }

    /**
     * This function prepares significant movement thresholds for positive and negative daily change.
     *
     * @param {ecapp.ITradingInstrument} instrument - instrument object
     * @return {{negative: number[], positive: number[]}} - thresholds sorted from low to high
     */
    function parseThresholds(instrument) {
      if (
        instrument.characteristics &&
        instrument.characteristics['significant-thresholds'] &&
        instrument.characteristics['significant-thresholds'].thresholds
      ) {
        var result = { positive: [], negative: [] };
        ['P5', 'P2', 'P1', 'P05'].forEach(function (key) {
          if (instrument.characteristics['significant-thresholds'].thresholds[key]) {
            result.positive.push(instrument.characteristics['significant-thresholds'].thresholds[key][0]);
            result.negative.push(instrument.characteristics['significant-thresholds'].thresholds[key][1]);
          }
        });
        return result;
      } else {
        return undefined;
      }
    }

    return {
      parseThresholds: parseThresholds,
      calculateSignificance: calculateSignificance,
      getPricePrecision: getPricePrecision,
      getPrecision: getPrecision,
      createComplexPriceObject: createComplexPriceObject,
      updateComplexPriceObject: updateComplexPriceObject,
      updateComplexPriceObjectQuotes: updateComplexPriceObjectQuotes,
    };
  }
})();
